import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Container,
  IconButton,
  Icon,
  Spinner,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiAnnotation, HiLogout } from 'react-icons/hi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BiBarcode } from 'react-icons/bi';
import {
  IsShippedType,
  useProductionsQuery,
  UserType,
  useStoreMessagesCountQuery
} from '../generated/graphql';
import { PrinterModal } from './ui/PrinterModal';
import { TransferModal } from './ui/TransferModal';
export const Layout: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: storeMessagesCountData } = useStoreMessagesCountQuery({
    pollInterval: 5000
  });

  const { data, loading } = useProductionsQuery({
    pollInterval: 1000 * 60 * 5,
    variables: {
      isShipped: IsShippedType.Progress,
      first: 1,
      skip: 0
    }
  });

  function logOut() {
    localStorage.removeItem('token');
    window.location.reload();
  }

  const pages = [
    {
      name: 'Yeni Üretim',
      path: '/new-production',
      notPermissions: [UserType.Worker],
      subPages: []
    },
    {
      name: 'Depo İşlemleri',
      path: '/storage',
      notPermissions: [UserType.Production],
      subPages: [
        {
          name: 'Depo',
          path: '/storage',
          notPermissions: [UserType.Production]
        },
        {
          name: `Bekleyen Ürünler ${
            loading ? (
              <Spinner />
            ) : data && data?.productions.productionCount > 0 ? (
              `(${data?.productions.productionCount})`
            ) : (
              ''
            )
          }`,
          path: '/pending-products',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Depoya Gönder',
          path: '/send-to-storage',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Kritik Stok',
          path: '/store-stock',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Üretim Hareketleri',
          path: '/production-reports',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Şuan Üretimde Olanlar',
          path: '/production',
          notPermissions: [UserType.Production]
        }
      ]
    },
    {
      name: 'Sevk İşlemleri',
      path: '/create-delivery',
      notPermissions: [UserType.Production],
      subPages: [
        {
          name: 'Sevk Yarat',
          path: '/create-delivery',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Sevkler',
          path: '/delivery-list',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Beymen Etiketi Yazdır',
          path: '/beymen-print',
          notPermissions: [UserType.Production]
        }
      ]
    },
    {
      name: 'Sipariş İşlemleri',
      path: '/order-list',
      notPermissions: [UserType.Production],
      subPages: [
        {
          name: 'İnternet Siparişleri',
          path: '/order-list',
          notPermissions: [UserType.Production]
        },
        {
          name: 'İadeler',
          path: '/order-returns',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Trendyol Siparişleri',
          path: '/market-orders',
          notPermissions: [UserType.Production]
        }
        // {
        //   name: 'Shopify Siparişleri',
        //   path: '/shopify-orders',
        //   notPermissions: [UserType.Production]
        // }
      ]
    },
    {
      name: 'Diğer',
      path: '/stok',
      notPermissions: [UserType.Production],
      subPages: [
        {
          name: 'Mağaza Stokları',
          path: '/stok',
          notPermissions: [UserType.Production]
        },

        {
          name: 'Ürünler',
          path: '/product-groups',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Ürün Listesi',
          path: '/product-group-list',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Kargo Listesi',
          path: '/cargo-list',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Kargo Oluştur',
          path: '/create-cargo',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Mağaza Ürün Talepleri',
          path: '/product-request/list',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Sayım yap',
          path: '/inventory',
          notPermissions: [UserType.Production]
        },
        {
          name: 'Sayımlar',
          path: '/counts',
          notPermissions: [UserType.Production]
        }
      ]
    }
  ];

  return (
    <Box>
      <Box bg="#862337">
        <Container maxW="container.xl">
          <Box
            display={{
              base: 'block',
              md: 'none'
            }}
            as="section"
            py={5}
            px={6}
            shadow={'md'}
          >
            <Box as="nav">
              <Box position="relative" display="flex" alignItems={'center'}>
                <Box display="flex" alignItems={'center'} mr="auto">
                  <Box bgColor={'white'} p={1}>
                    <Link to="/dashboard">
                      <img
                        src="https://images.jujumood.com/logo.png?format=webp&height=30"
                        alt="Juju Logo"
                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  display={{
                    base: 'flex',
                    md: 'none'
                  }}
                  justifyContent="flex-end"
                >
                  <Button
                    className="flex items-center"
                    variant={'outline'}
                    onClick={() => setIsOpen(true)}
                  >
                    <Icon
                      as={GiHamburgerMenu}
                      color="white"
                      fontSize={'20px'}
                    />
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              display={{
                base: isOpen ? 'block' : 'none',
                md: 'none'
              }}
              position="fixed"
              top={0}
              left={0}
              bottom={0}
              width="80%"
              zIndex={50}
            >
              <Box
                position={'fixed'}
                width="20%"
                right={0}
                top={0}
                bottom={0}
                bgColor="blackAlpha.500"
                zIndex={49}
                onClick={() => setIsOpen(false)}
              ></Box>
              <Box
                as="nav"
                display="flex"
                flexDirection={'column'}
                py={6}
                px={6}
                width="full"
                height={'full'}
                bgColor="white"
                borderRight={'1px solid '}
                borderColor="gray.300"
              >
                <div className="flex items-center mb-8">
                  <a
                    className="mr-auto text-3xl font-bold leading-none"
                    href="/"
                  >
                    <img
                      src="https://images.jujumood.com/logo.png?format=webp&height=50"
                      alt="Juju Logo"
                    />
                  </a>
                  <button className="navbar-close">
                    <svg
                      className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div>
                  <Flex flexDirection={'column'}>
                    <Link to="/dashboard">
                      <Box fontWeight={'semibold'} fontSize="xl" mb={4}>
                        Arama
                      </Box>
                    </Link>
                    {pages.map((page) => {
                      return page?.notPermissions?.map((permissions) => {
                        if (permissions === localStorage.getItem('role')) {
                          return null;
                        }
                        return page.subPages.length > 0 ? (
                          <Menu key={page.name}>
                            <MenuButton
                              fontWeight={'semibold'}
                              fontSize="xl"
                              mb={4}
                              textAlign={'left'}
                            >
                              {page.name}
                            </MenuButton>
                            <MenuList bg={'#862337'}>
                              {page.subPages?.map((subPage) => {
                                return (
                                  <MenuItem
                                    fontWeight={'semibold'}
                                    fontSize="xl"
                                    mb={4}
                                    _hover={{
                                      bg: 'gray.200',
                                      color: '#862337'
                                    }}
                                    as={Link}
                                    to={subPage.path}
                                  >
                                    {subPage.name}
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        ) : (
                          <Link key={page.name} to={page.path}>
                            <Box
                              textAlign={'left'}
                              fontWeight={'semibold'}
                              fontSize="xl"
                              mb={4}
                            >
                              {page.name}
                            </Box>
                          </Link>
                        );
                      });
                    })}
                    <Link to="/print-barcode">
                      <Box fontWeight={'semibold'} fontSize="xl" mb={4}>
                        Barkod Yazdır
                      </Box>
                    </Link>

                    <Link to="/store-messages">
                      <Box fontWeight={'semibold'} fontSize="xl" mb={4}>
                        Mesajlar
                      </Box>
                    </Link>
                    <Box
                      onClick={logOut}
                      cursor="pointer"
                      fontWeight={'semibold'}
                      fontSize="xl"
                      mb={4}
                    >
                      Çıkış Yap
                    </Box>
                  </Flex>
                  {localStorage.getItem('role') !== UserType.Production && (
                    <PrinterModal />
                  )}
                  {localStorage.getItem('role') !== UserType.Production && (
                    <TransferModal />
                  )}
                </div>
              </Box>
            </Box>
          </Box>

          <Flex
            display={{
              base: 'none',
              md: 'flex'
            }}
            color="white"
            justifyContent="space-between"
            alignItems="center"
            p={4}
          >
            <Box bgColor={'white'} p={2}>
              <Link to="/dashboard">
                <img
                  src="https://images.jujumood.com/logo.png?format=webp&width=35&height=50"
                  alt="Juju Logo"
                />
              </Link>
            </Box>
            <Flex
              justifyContent={'center'}
              alignItems="center"
              experimental_spaceX={4}
            >
              <Link to="/dashboard">
                <Box fontWeight={'semibold'}>Arama</Box>
              </Link>
              {pages.map((page) => {
                return page?.notPermissions?.map((permissions) => {
                  if (permissions === localStorage.getItem('role')) {
                    return null;
                  }
                  return page.subPages.length > 0 ? (
                    <Menu key={page.name}>
                      <MenuButton fontWeight={'semibold'}>
                        {page.name}
                      </MenuButton>
                      <MenuList bg={'#862337'}>
                        {page.subPages?.map((subPage) => {
                          return (
                            <MenuItem
                              fontWeight={'semibold'}
                              _hover={{
                                bg: 'gray.200',
                                color: '#862337'
                              }}
                              as={Link}
                              to={subPage.path}
                            >
                              {subPage.name}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  ) : (
                    <Link key={page.name} to={page.path}>
                      <Box fontWeight={'semibold'}>{page.name}</Box>
                    </Link>
                  );
                });
              })}
            </Flex>
            <Flex
              color="black"
              alignItems="space-between"
              p={2}
              experimental_spaceX={4}
            >
              {/* <Button mx={2}>
                <Link to="/add-stock">
                  <Icon
                    fontSize="14px"
                    aria-label="add barcode"
                    as={FaPlus}
                    mt={1}
                  />
                </Link>
              </Button> */}

              <Button mx={2}>
                <Link to="/print-barcode">
                  <Icon
                    fontSize="20px"
                    aria-label="printbarcode"
                    as={BiBarcode}
                    mt={1}
                  />
                </Link>
              </Button>
              {localStorage.getItem('role') !== UserType.Production && (
                <Button display={'flex'} alignItems={'center'} mx={2}>
                  <Link to="/store-messages">
                    <Icon
                      fontSize="17px"
                      aria-label="messages"
                      as={HiAnnotation}
                      mt={1}
                    />
                  </Link>{' '}
                  {storeMessagesCountData &&
                    storeMessagesCountData.countStoreMessage}
                </Button>
              )}
              {localStorage.getItem('role') !== UserType.Production && (
                <PrinterModal />
              )}
              {localStorage.getItem('role') !== UserType.Production && (
                <TransferModal />
              )}
              <IconButton
                aria-label="LogOut"
                icon={<HiLogout />}
                onClick={logOut}
                mx={2}
                fontSize="17px"
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <hr />
      <Box>{children}</Box>
    </Box>
  );
};
