import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button
} from '@chakra-ui/react';

export const DeliveryModal = ({
  onSubmit,
  store,
  price,
  quantity,
  currency
}: {
  onSubmit: () => void;
  store: string;
  price: number;
  quantity: number;
  currency: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button bg={'green.500'} color="white" onClick={onOpen}>
        Sevk Yap{' '}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <strong>{store}</strong> adlı satış noktasına{' '}
            <strong>
              {price} {currency}
            </strong>{' '}
            tutarında <strong>{quantity}</strong> adet ürün sevk etmek
            istediğinize emin misiniz?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={(e: React.MouseEvent) => {
                onSubmit();
                onClose();
              }}
              bg={'green.500'}
              color="white"
            >
              Sevki Onayla
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
