import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner,
  List,
  ListItem,
  Text
} from '@chakra-ui/react';
import { Layout } from '../Layout';
import { useProducts } from '../../hooks/useGetStocks';
import { LocalType, useStoresWithTitleQuery } from '../../generated/graphql';
import RenderMaterials from '../../utils/renderMaterials';
import Pagination from '../ui/Pagination';

const StoreStock = () => {
  const [storeId, setStoreId] = useState('');
  const [page, setPage] = useState(1);
  const [stocks, setStocks] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const {
    products,
    loading: prodLoading,
    totalPage
  } = useProducts({
    storeId
  });
  const { data: stores } = useStoresWithTitleQuery({
    variables: {
      isLocal: LocalType.Store
    }
  });
  const pageUp = () => {
    window.scrollTo(0, 0);
  };
  async function getStocks(
    store: string,
    category: string,
    productGroup: string
  ) {
    setLoading(true);
    const data = await axios.get(
      `${
        process.env.REACT_APP_STOCKURL
      }/stock/getProductVarition?productGroupId=${productGroup}${
        store ? '&storeId=' + store : ''
      }&categoryId=${category}`
    );
    if (data.data.data) {
      setStocks([...stocks, ...data.data.data.stockData]);
      setLoading(false);
    }
    console.log('🌵💜🐢', ...data?.data.data.stockData, stocks);

    setLoading(false);
  }

  return (
    <Layout>
      <Container maxW={'container.xl'}>
        <Box my={5} w={96}>
          <FormLabel fontWeight={'bold'}>Select Store</FormLabel>
          <Select
            placeholder="Select store"
            onChange={(e) => setStoreId(e.target.value)}
          >
            {stores?.stores
              .filter((s) => s.id !== 'ckt8qddhqlsa50859mu9991fa')
              .map((store) => (
                <option key={store.id} value={store.id}>
                  {store.title}
                </option>
              ))}
          </Select>
        </Box>
        <Table>
          <Thead>
            <Tr>
              <Th>Product Image</Th>
              <Th>Product SKU</Th>
              <Th>Product Name</Th>
              <Th>Product Quantity</Th>
              <Th>Store</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          {prodLoading ? (
            <Spinner />
          ) : (
            <Tbody>
              {products &&
                products
                  .filter((p) => p.amount <= 7)
                  .sort((a, b) => b.amount - a.amount)
                  .map((product) => {
                    return (
                      <>
                        <Tr
                          bgColor={
                            product.amount === 3
                              ? 'purple.400'
                              : product.amount === 2
                              ? 'orange.500'
                              : product.amount === 1
                              ? 'red.600'
                              : product.amount <= 0
                              ? 'black'
                              : ''
                          }
                          color={product.amount <= 1 ? 'white' : ''}
                        >
                          <Td>
                            <img
                              src={`${process.env.REACT_APP_CDNURL}/${product.image}?format=webp&height=150&quality=80`}
                              alt={product.title}
                            />
                          </Td>
                          <Td>
                            {product.category}-{product.sku}
                          </Td>
                          <Td>{product.title}</Td>
                          <Td>{product.amount}</Td>
                          <Td>
                            {storeId ? (
                              stores?.stores.find(
                                (s) => s.id === product.storeId
                              )?.title
                            ) : (
                              <List>
                                {product.storeStock.map((stock: any) => {
                                  return (
                                    <ListItem>
                                      <Text fontWeight={'bold'} as="span">
                                        {
                                          stores?.stores.find(
                                            (s) => s.id === stock.store
                                          )?.title
                                        }{' '}
                                        :
                                      </Text>

                                      <Text
                                        fontWeight={'bold'}
                                        as="span"
                                        color={
                                          Math.sign(stock._sum.amount) === -1
                                            ? 'red.200'
                                            : stock._sum.amount >= 5
                                            ? 'green.200'
                                            : stock._sum.amount <= 4
                                            ? 'blue.200'
                                            : stock._sum.amount <= 2
                                            ? 'orange.200'
                                            : stock._sum.amount <= 0
                                            ? 'red.200'
                                            : 'red.200'
                                        }
                                      >
                                        {stock._sum.amount}
                                      </Text>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            )}
                          </Td>
                          <Td>
                            <Button
                              colorScheme="blue"
                              onClick={async () => {
                                const control = stocks.filter(
                                  (s: any) =>
                                    s.sku.split('-')[1] === product.sku
                                );
                                if (control.length === 0) {
                                  await getStocks(
                                    storeId,
                                    product.category,
                                    product.sku
                                  );
                                }
                              }}
                            >
                              Varyasyonları Getir
                            </Button>
                          </Td>
                        </Tr>

                        <Tr w={'full'}>
                          <Td colSpan={5}>
                            <Accordion allowMultiple w={'full'}>
                              <AccordionItem>
                                <AccordionButton>
                                  <Box flex="1" textAlign="left">
                                    Varyasyonlar
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                  <Table>
                                    <Thead>
                                      <Tr>
                                        <Th>SKU</Th>
                                        <Th>Color</Th>
                                        <Th>Size</Th>
                                        <Th>Quantity</Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {!loading &&
                                        stocks
                                          .filter(
                                            (s: any) =>
                                              s.sku.split('-')[1] ===
                                              product.sku
                                          )
                                          .filter((p: any) => p._sum.amount < 5)
                                          .sort(
                                            (a: any, b: any) =>
                                              b._sum.amount - a._sum.amount
                                          )
                                          .map((s: any) => {
                                            return (
                                              <Tr>
                                                <Td>{s.sku}</Td>
                                                <Td>
                                                  <RenderMaterials
                                                    locale="tr"
                                                    sku={s.sku}
                                                  />
                                                </Td>
                                                <Td>{s.sku.split('-')[2]}</Td>
                                                <Td>{s._sum.amount}</Td>
                                              </Tr>
                                            );
                                          })}
                                    </Tbody>
                                  </Table>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Td>
                        </Tr>
                      </>
                    );
                  })}
            </Tbody>
          )}
        </Table>
      </Container>
      <Button
        m={10}
        py={8}
        rounded={'full'}
        onClick={pageUp}
        pos={'fixed'}
        bottom={0}
      >
        Top
      </Button>
    </Layout>
  );
};

export default StoreStock;
