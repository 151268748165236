import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import { OrderTypeType, useInvoicesQuery } from '../../generated/graphql';
import formatMoney from '../../utils/formatMoney';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import axios from 'axios';
import { useUserLazyQuery, UserType } from '../../generated/graphql';

interface Request {
  id: string;
  status: string;
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
  storeId: string;
  sellerId: string;
  productRequest: ProductRequest[];
}

type ProductRequest = {
  id: string;
  sku: string;
  quantity: number;
  notes: string;
  createdAt: string;
  updatedAt: string;
  productId: string;
};

const ProductRequests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [user] = useUserLazyQuery();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUsers = async () => {
    const data = await user({
      variables: {
        first: 15,
        skip: 0,
        userType: UserType.Worker
      }
    });
    return data.data?.users;
  };

  useEffect(() => {
    const userData = localStorage.getItem('users');
    if (userData === null || userData === undefined) {
      getUsers()
        .then((res) => {
          console.log(res);
          localStorage.setItem('users', JSON.stringify(res?.users));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err.message);
          setLoading(false);
        });
    }
  }, []);

  const fetchData = async () => {
    try {
      const result = await axios({
        url: `${process.env.REACT_APP_PRODUCTURL}/request/all`,
        method: 'GET'
      });
      console.log('ss', result.data);
      setData(result.data);
      setLoading(false);
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function renderStore(id: string) {
    switch (id) {
      case 'ckoq4cr691mfa0759r1n8nddz':
        return 'Nişantaşı';
      case 'ckoq4krsy1mvv0759k9mrf1dr':
        return 'Bebek';
      case 'ckwz1vfu8fkca08599m67a6mk':
        return 'Galataport';
      default:
        return id;
    }
  }
  function renderUser(id: string) {
    const users = localStorage.getItem('users');
    if (users !== null) {
      const user = JSON.parse(users).find((user: any) => user.id === id);

      return user?.name + ' ' + user?.surname;
    }
  }

  const renderOrders = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={'auto'} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu. {error}</Td>
        </Tr>
      );
    }
    if (data) {
      return data.map((req: Request, i) => {
        return (
          <Tr key={i}>
            <Td>{i + 1} </Td>
            <Td>{dayjs(req.createdAt).format('DD MM YYYY')}</Td>
            <Td>{renderStore(req.storeId)}</Td>
            <Td>{renderUser(req.sellerId) || ''}</Td>
            <Td>{req.productRequest.length}</Td>
            <Td>{req.isRead ? 'Okundu' : 'Okunmadı'}</Td>
            <Td>{req.status}</Td>
            <Td>
              <Link to={`/product-request/${req.id}`}>
                <Tag colorScheme="blue">Detay</Tag>
              </Link>
            </Td>
          </Tr>
        );
      });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Siparişler" />
        <Box>
          <Table variant={'striped'} className="w-full table-auto">
            <Thead
              display={{
                base: 'none',
                md: 'table-header-group'
              }}
            >
              <Tr>
                <Th>#</Th>
                <Th>Tarih</Th>
                <Th>Mağaza</Th>
                <Th>Satıcı</Th>
                <Th>Ürün Sayısı</Th>
                <Th>Okundu Bilgisi</Th>
                <Th>Durumu</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderOrders()}</Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default ProductRequests;
