import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Box,
  Button,
  Spinner,
  Tr,
  Container
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LocalType, useStoresWithTitleQuery } from '../../generated/graphql';
import axios from 'axios';
import Heading from '../ui/Heading';
import dayjs from 'dayjs';
const StoreCounts = () => {
  const { data, error } = useStoresWithTitleQuery({
    variables: {
      isLocal: LocalType.Store
    }
  });
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState<any>([]);
  async function getCounts() {
    const res = await axios.get(
      `${process.env.REACT_APP_STOCKURL}/stock/getStoreStocks`
    );
    if (res.data) {
      setCounts(res.data);
    }
  }
  useEffect(() => {
    if (data) {
      setLoading(true);
      getCounts();
      setLoading(false);
    }
  }, [data]);

  return (
    <Layout>
      <Container maxW={'container.xl'}>
        <Heading title="Mağaza Stok Sayımları" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Mağaza</Th>
              <Th>Sayım Tarihi</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Spinner />
            ) : (
              counts.map((item: any) => {
                const store = data?.stores?.find(
                  (store) => store.id === item.store
                );
                return (
                  <Tr key={item.store}>
                    <Td>{store?.title}</Td>

                    <Td>{dayjs(item.createdAt).format('DD/MM/YYYY')}</Td>
                    <Td>
                      <Link to={`/stock-count/${item.id}`}>
                        <Button colorScheme="blue">Detay</Button>
                      </Link>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </Container>
    </Layout>
  );
};

export default StoreCounts;
