/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Box,
  Container,
  Text,
  Button,
  useToast,
  SimpleGrid,
  Spinner,
  GridItem,
  Image,
  Flex
} from '@chakra-ui/react';
import Heading from '../ui/Heading';
import {
  useFinishedProductMutation,
  useMyStartedProductsQuery,
  useStartedProductsQuery
} from '../../generated/graphql';
import dayjs from 'dayjs';
import RenderMaterials from '../../utils/renderMaterials';

export const StartedProduction = () => {
  const toast = useToast();
  const {
    data: myStartedProducts,
    refetch: myRefetch,
    loading: myLoading
  } = useStartedProductsQuery({
    fetchPolicy: 'network-only'
  });

  return (
    <Layout>
      <Container my={4} maxW={'container.xl'}>
        <Heading title="Şuan Üretimde Olan Ürünler" />
        <Box my={4}>
          <Button
            onClick={() => {
              myRefetch();
            }}
            isLoading={myLoading}
            disabled={myLoading}
          >
            Yenile
          </Button>
        </Box>
        <Box>
          {myLoading ? (
            <Spinner />
          ) : (
            <SimpleGrid
              gap={4}
              columns={{
                base: 1,
                md: 2,
                lg: 12
              }}
            >
              {myStartedProducts?.startedProductions.length === 0 ? (
                <GridItem
                  my={2}
                  border="1px solid"
                  borderColor={'gray.100'}
                  p={4}
                  textAlign="center"
                  w={'full'}
                  colSpan={12}
                >
                  Henüz ürününüz yok.
                </GridItem>
              ) : (
                myStartedProducts?.startedProductions.map((p) => {
                  return (
                    <GridItem
                      bg={'gray.50'}
                      border="1px"
                      borderColor={'gray.100'}
                      borderStyle={'solid'}
                      rounded={'md'}
                      key={p.id}
                      colSpan={4}
                    >
                      <Box p={4}>
                        <Flex>
                          <Box>
                            <Text fontWeight={'semibold'} fontSize={'xl'}>
                              {p.product.productGroup.description[0].title}
                            </Text>
                            <Text fontSize={'sm'}>
                              {p.product.sku?.split('-')[0]}-
                              {p.product.sku?.split('-')[1]}-
                              {p.product.sku?.split('-')[2]}
                            </Text>
                          </Box>
                          <Box ml={'auto'}>
                            <Text fontSize={'sm'} fontWeight={'bold'}>
                              Üretim Yapan
                            </Text>
                            <Text fontSize={'sm'}>
                              {p.user.name} {p.user.surname}
                            </Text>
                          </Box>
                        </Flex>
                        <Box my={2} px={1}>
                          <Image
                            w={'full'}
                            src={`https://images.jujumood.com/${p.product.productGroup.photos[0]}?quality=80&width=300&height=300&fit=crop`}
                          />
                          <Box my={2}>
                            <Text fontSize={'sm'} fontWeight={'bold'}>
                              Ürün Materyalleri
                            </Text>
                            <Box w={'auto'}>
                              <RenderMaterials
                                sku={p.product.sku || ''}
                                locale="tr"
                              />
                            </Box>
                          </Box>
                          {p.isRepair && (
                            <Box my={2}>
                              <Text fontSize={'sm'} fontWeight={'bold'}>
                                Tadilat Sebebi
                              </Text>
                              <Text fontSize={'sm'}>{p.repairStatus}</Text>
                            </Box>
                          )}
                          <Box my={2}>
                            <Text fontSize={'sm'} fontWeight={'bold'}>
                              Başlama Zamanı
                            </Text>
                            <Text fontSize={'sm'}>
                              {dayjs(p.startedAt?.split('Z')[0]).format(
                                'DD.MM.YYYY HH:mm:ss'
                              )}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </GridItem>
                  );
                })
              )}
            </SimpleGrid>
          )}
        </Box>
      </Container>
    </Layout>
  );
};
