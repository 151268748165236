/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import Select from 'react-select';
import { useCategoriesQuery } from '../../generated/graphql';

interface CategorySelectProps {
  category: string;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
}

export const CategorySelect = ({
  category,
  setCategory
}: CategorySelectProps) => {
  const { data, loading, error } = useCategoriesQuery();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  return (
    <Select
      value={data?.categories.map((c) => {
        if (c.id === category) {
          return {
            value: c.id,
            label: c.description[0].title
          };
        }
      })}
      options={
        data &&
        data?.categories.map((c: any) => {
          return {
            value: c.id,
            label: c.description[0].title
          };
        })
      }
      onChange={(e: any) => setCategory(e.value)}
    />
  );
};

interface SubCategorySelectProps {
  category: string;
  setSubCategory: React.Dispatch<React.SetStateAction<string>>;
}

export const SubCategorySelect = ({
  category,
  setSubCategory
}: SubCategorySelectProps) => {
  const { data, loading, error } = useCategoriesQuery({
    variables: {
      category
    }
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  return (
    <Select
      options={
        data &&
        data?.categories.map((c: any) => {
          return {
            value: c.id,
            label: c.description[0].title
          };
        })
      }
      onChange={(e: any) => setSubCategory(e.value)}
    />
  );
};
