import React from 'react';
import { useOrderReturnsQuery } from '../../generated/graphql';
import { Layout } from './../Layout';
import { Link } from 'react-router-dom';
import { BsBullseye } from 'react-icons/bs';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag,
  Icon
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import { FaCheckCircle } from 'react-icons/fa';

dayjs.locale('tr');

export const OrderReturns = () => {
  const { data, loading, error } = useOrderReturnsQuery({
    fetchPolicy: 'network-only'
  });

  const renderOrders = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={'auto'} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu. {error.message}</Td>
        </Tr>
      );
    }
    if (data) {
      return data.orderReturns.map((order, i) => {
        return (
          <Tr key={order.id}>
            <Td>
              <Box
                textAlign={'center'}
                color="gray.600"
                fontWeight={'semibold'}
                p={2}
              >
                {i + 1}
              </Box>
            </Td>
            <Td>
              {!order.isRead ? (
                <Icon as={BsBullseye} color="red.600" fontSize={'18px'} />
              ) : (
                <Icon as={FaCheckCircle} color="green.500" fontSize={'18px'} />
              )}
            </Td>
            <Td>
              {dayjs(order.createdAt).format('DD MMMM YYYY')} <br />
              {dayjs(order.createdAt).format('HH:mm')}
            </Td>
            <Td>
              {order.user.name} {order.user.surname}
            </Td>
            <Td>{order.productOrder.product.sku}</Td>
            <Td>{order.reason}</Td>
            <Td>{order.status}</Td>
            <Td width="140px">
              <Flex flexDir={'column'} experimental_spaceY={1} width="140px">
                <Box>
                  <Link to={`/order-return/${order.id}`}>
                    <Tag
                      textAlign={'center'}
                      justifyContent="center"
                      variant={'solid'}
                      py={2}
                      colorScheme={'blue'}
                      width="100%"
                    >
                      Detay
                    </Tag>
                  </Link>
                </Box>
              </Flex>
            </Td>
          </Tr>
        );
      });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="İadeler" />
        <Box>
          <Table variant={'striped'} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th textAlign={'center'}>#</Th>
                <Th>
                  <BsBullseye />
                </Th>
                <Th>Tarih</Th>
                <Th>İsim Soyisim</Th>
                <Th>Ürün</Th>
                <Th>İade Nedeni</Th>
                <Th>Durumu</Th>

                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderOrders()}</Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};
