import React, { useState, useEffect, useRef } from 'react';

import { Layout } from '../Layout';
import {
  Container,
  Input,
  Box,
  Flex,
  Button,
  Text,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SimpleGrid
} from '@chakra-ui/react';
import Heading from '../ui/Heading';
import {
  PriceType,
  useStoresWithTitleQuery,
  useTransferToCartMutation,
  useProductionsQuery,
  IsShippedType,
  useUpdateTransferProductMutation,
  useStartShipmentMutation
} from '../../generated/graphql';
import Select from 'react-select';
import RenderMaterials from '../../utils/renderMaterials';
import { DeleteModal } from '../ui/DeleteModal';
import { DeliveryModal } from '../ui/DeliveryModal';
import useSound from 'use-sound';
const alert = require('./sound/red-alert-fx.wav');
const success = require('./sound/red-alert-fx.wav');
const beep = require('./sound/sonar-beep_C_minor.wav');

export const CreateDelivery = () => {
  const [priceType, setPriceType] = useState(PriceType.Retail);
  const [storeId, setStoreId] = useState('');
  const [storeTitle, setStoreTitle] = useState('');
  const [sound, setSound] = useState('');
  const audioPlayer = useRef<any>(alert);
  const [play] = useSound(sound);
  const computerName = localStorage.getItem('transferType') || 'server';
  function playAudio() {
    play();
  }
  const toast = useToast();

  useEffect(() => {
    refetch();
  });

  const {
    data: storeData,
    loading: storeLoading,
    error: storeError
  } = useStoresWithTitleQuery();
  const {
    data: productionData,
    loading: productionLoading,
    error: productionError,
    refetch
  } = useProductionsQuery({
    variables: {
      isShipped: IsShippedType.Cart,
      first: 1000000,
      skip: 0,
      computerName: computerName,
      orderBy: 'updatedAt_DESC'
    }
  });
  const [transferToCart] = useTransferToCartMutation();
  const [updateTransferProduct] = useUpdateTransferProductMutation();
  const [startShipment] = useStartShipmentMutation();

  if (storeLoading || productionLoading) return <div>Loading...</div>;
  if (storeError || productionError) return <div>Error!</div>;
  const readBarcode = async (barcode: string) => {
    console.log('🌵💜🐢', barcode);
    try {
      const res = await transferToCart({
        variables: {
          barcode,
          computerName: computerName
        }
      });
      if (res.data?.transferToCart.id) {
        setSound(beep);

        setTimeout(() => {
          playAudio();
        }, 2000);
        refetch();
        toast({
          title: 'Success',
          description: 'Ürün başarıyla eklendi',
          status: 'success',
          duration: 1000,
          isClosable: true
        });

        // playAlert('submarine');
      }
    } catch (error: any) {
      console.log('🌵💜🐢 error', error);
      playAudio();
      toast({
        title: 'Error',
        description: `${error.message}`,
        status: 'warning',
        duration: 4000
      });
      // playAlert('funk');
    }
  };

  const onDelete = async (id: string) => {
    try {
      await updateTransferProduct({
        variables: {
          isShipped: IsShippedType.Storage,
          updateTransferProductId: id
        }
      });
      refetch();
      toast({
        title: 'Success',
        description: 'Ürün başarıyla silindi ve stoğa eklendi',
        status: 'success',
        duration: 2000,
        isClosable: true
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: `${error.message}`,
        status: 'warning',
        duration: 3000
      });
    }
  };

  const onStartShipment = async () => {
    if (storeId === '') {
      return toast({
        title: 'Error',
        description: `Hata! Mağaza seçilmedi`,
        status: 'warning',
        duration: 3000
      });
    }
    try {
      const res = await startShipment({
        variables: {
          store: storeId,
          priceType,
          computerName: computerName
        }
      });
      if (res.data?.startShipment.result) {
        // playAudio('cash-register-fx_A_minor_phakjg.wav');
        //https://res.cloudinary.com/jujumood/video/upload/v1654257112/cash-register-fx_A_minor_phakjg.wav
        refetch();
        toast({
          title: 'Success',
          description: 'Ürün Başarıyla Sevk Edildi',
          status: 'success',
          duration: 2000
        });
      }
    } catch (error: any) {
      toast({
        title: 'Error',
        description: `${error.message}`,
        status: 'warning',
        duration: 3000
      });
    }
  };

  let totalPrice = 0;
  let currency = 'TRY';

  return (
    <Layout>
      <audio ref={audioPlayer} src={beep} />
      <Container maxW={'container.xl'}>
        <Heading title={`Ürünleri Sevk Et - ${computerName}`} />
        <Box
          border="1px solid"
          borderColor={'gray.100'}
          my="4"
          rounded={4}
          p={{
            base: 4,
            md: 10
          }}
        >
          <Box>
            <Box>
              <Box
                mb={2}
                fontWeight={'bold'}
                fontSize="xl"
                textAlign={{
                  base: 'center',
                  md: 'left'
                }}
              >
                Fiyat Seçimi
              </Box>
              <SimpleGrid
                mb={6}
                columns={{
                  base: 2,
                  md: 4
                }}
                gap={4}
              >
                <Button
                  fontSize={{
                    base: '14px',
                    md: '16px'
                  }}
                  _hover={{ bg: 'blue.400' }}
                  bg={`${
                    priceType === PriceType.Retail ? 'blue.500' : 'gray.500'
                  }`}
                  color={'white'}
                  onClick={(e: React.MouseEvent) =>
                    setPriceType(PriceType.Retail)
                  }
                >
                  Mağaza Satış
                </Button>
                <Button
                  fontSize={{
                    base: '14px',
                    md: '16px'
                  }}
                  _hover={{ bg: 'blue.400' }}
                  bg={`${
                    priceType === PriceType.Foreign ? 'blue.500' : 'gray.500'
                  }`}
                  color={'white'}
                  onClick={(e: React.MouseEvent) =>
                    setPriceType(PriceType.Foreign)
                  }
                >
                  Yurtdışı Satış
                </Button>
                <Button
                  fontSize={{
                    base: '14px',
                    md: '16px'
                  }}
                  _hover={{ bg: 'blue.400' }}
                  bg={`${
                    priceType === PriceType.Wholesale ? 'blue.500' : 'gray.500'
                  }`}
                  color={'white'}
                  onClick={(e: React.MouseEvent) =>
                    setPriceType(PriceType.Wholesale)
                  }
                >
                  Euro Toptan Satış
                </Button>
                <Button
                  fontSize={{
                    base: '14px',
                    md: '16px'
                  }}
                  _hover={{ bg: 'blue.400' }}
                  bg={`${
                    priceType === PriceType.Wholesaleus
                      ? 'blue.500'
                      : 'gray.500'
                  }`}
                  color={'white'}
                  onClick={(e: React.MouseEvent) =>
                    setPriceType(PriceType.Wholesaleus)
                  }
                >
                  Dolar Toptan Satış
                </Button>
              </SimpleGrid>
            </Box>
            <Box>
              <Box
                mb={2}
                fontWeight={'bold'}
                fontSize="xl"
                textAlign={{
                  base: 'center',
                  md: 'left'
                }}
              >
                Sevk Yeri
              </Box>
              <Box
                w={{
                  base: '100%',
                  md: 96
                }}
                my={2}
              >
                <Select
                  options={storeData?.stores.map((s) => {
                    return {
                      value: s.id,
                      label: s.title
                    };
                  })}
                  onChange={(e: any) => {
                    setStoreId(e.value);
                    setStoreTitle(e.label);
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box
                mt={6}
                mb={2}
                fontWeight={'bold'}
                fontSize="xl"
                textAlign={{
                  base: 'center',
                  md: 'left'
                }}
              >
                Barkod Girişi
              </Box>
              <Box>
                <Input
                  w={{
                    base: '100%',
                    md: 96
                  }}
                  my={2}
                  placeholder="Barkodu okutunuz"
                  onChange={(e: any) => {
                    if (e.target.value.length === 12) {
                      readBarcode(e.target.value);
                      e.target.value = '';
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Table my="4">
              <Thead
                display={{
                  base: 'none',
                  md: 'table-header-group'
                }}
              >
                <Tr>
                  <Th>İşlem</Th>
                  <Th>Resim</Th>
                  <Th>İsim</Th>
                  <Th>SKU</Th>
                  <Th>Barkod</Th>
                  <Th>Seçenekler</Th>
                  <Th>Fiyat</Th>
                </Tr>
              </Thead>
              <Tbody>
                {productionData?.productions.productions.map(
                  (production, index) => {
                    const price = production.product.productGroup.price.find(
                      (p) => p.priceType === priceType
                    );
                    const size = production.product.sku?.split('-')[2];
                    const sizePrices =
                      production.product.productGroup.sizePrices.find(
                        (p) => p.size.sku === size
                      );
                    const sizePrice = sizePrices?.prices.find(
                      (p) => p.priceType === priceType
                    );
                    let productPrice = price?.price ? price.price : -99999999;
                    if (sizePrice) {
                      productPrice += sizePrice.price;
                    }
                    totalPrice += productPrice;
                    currency = price?.currency.code || 'TRY';

                    return (
                      <Tr key={production.id}>
                        <Td
                          display={{
                            base: 'table-cell',
                            md: 'none'
                          }}
                          colSpan={7}
                        >
                          <Box
                            position="relative"
                            width="100%"
                            bgColor={'gray.100'}
                            p={4}
                          >
                            <Box position={'absolute'} right={0} top={0}>
                              <Box p={2}>
                                <DeleteModal
                                  button
                                  onDelete={onDelete}
                                  id={production.id}
                                />
                              </Box>
                            </Box>
                            <Box
                              border="1px solid"
                              borderColor="gray.100"
                              rounded="md"
                              overflow={'hidden'}
                            >
                              <img
                                src={`${process.env.REACT_APP_CDNURL}/${production.product.productGroup.photos[0]}?width=700&height=700&quality=50&trim=20&fit=contain&bg=ffffff`}
                                alt={
                                  production.product.productGroup
                                    ?.description[0].title
                                }
                                width="700"
                                height="700"
                              />
                            </Box>

                            <Box textAlign={'center'}>
                              <Box fontWeight={'semibold'} my={1}>
                                {
                                  production.product.productGroup
                                    ?.description[0].title
                                }
                              </Box>
                              <Box color="gray.500">
                                {`${
                                  production.product.productGroup.category.sku
                                }-${production.product.productGroup.sku}-${
                                  production.product.sku?.split('-')[2]
                                }`}
                              </Box>
                              <Box>{production.product.barcode}</Box>

                              <Box my={2}>
                                <RenderMaterials
                                  locale="tr"
                                  sku={production.product.sku || ''}
                                />
                              </Box>
                            </Box>
                            <Box
                              textAlign={'center'}
                              my={2}
                              fontSize="lg"
                              fontWeight={'semibold'}
                            >
                              {productPrice} {price?.currency.code}
                            </Box>
                          </Box>
                        </Td>
                        <Td
                          display={{
                            base: 'none',
                            md: 'table-cell'
                          }}
                        >
                          <DeleteModal
                            button
                            onDelete={onDelete}
                            id={production.id}
                          />
                        </Td>
                        <Td
                          display={{
                            base: 'none',
                            md: 'table-cell'
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_CDNURL}/${production.product.productGroup.photos[0]}?width=100&height=100&quality=50&trim=20&fit=contain&bg=ffffff`}
                            alt={
                              production.product.productGroup?.description[0]
                                .title
                            }
                            width="100"
                            height="100"
                          />
                        </Td>
                        <Td
                          display={{
                            base: 'none',
                            md: 'table-cell'
                          }}
                        >
                          {
                            production.product.productGroup?.description[0]
                              .title
                          }
                        </Td>
                        <Td
                          display={{
                            base: 'none',
                            md: 'table-cell'
                          }}
                        >
                          {`${production.product.productGroup.category.sku}-${
                            production.product.productGroup.sku
                          }-${production.product.sku?.split('-')[2]}`}
                        </Td>
                        <Td
                          display={{
                            base: 'none',
                            md: 'table-cell'
                          }}
                        >
                          {production.product.barcode}
                        </Td>
                        <Td
                          display={{
                            base: 'none',
                            md: 'table-cell'
                          }}
                        >
                          <RenderMaterials
                            locale="tr"
                            sku={production.product.sku || ''}
                          />
                        </Td>
                        <Td
                          display={{
                            base: 'none',
                            md: 'table-cell'
                          }}
                        >
                          {productPrice} {price?.currency.code}
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
              <Tbody>
                <Tr>
                  <Td colSpan={6} textAlign="right">
                    Toplam Adet:
                  </Td>
                  <Td fontWeight={'bold'}>
                    {productionData?.productions.productionCount}
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={6} textAlign="right">
                    Toplam Fiyat:
                  </Td>
                  <Td fontWeight={'bold'}>
                    {totalPrice} {currency}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Box
              textAlign={{
                base: 'right',
                md: 'left'
              }}
            >
              {/* <Button colorScheme={'green'} onClick={onStartShipment}>
                Sevk Yap
              </Button> */}
              <DeliveryModal
                onSubmit={onStartShipment}
                price={totalPrice}
                currency={currency}
                quantity={productionData?.productions.productionCount || 0}
                store={storeTitle}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};
