import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button,
  Text,
  Icon
} from '@chakra-ui/react';
import { BiTask } from 'react-icons/bi';
import {
  BiBadgeCheck,
  BiBadge,
  BiSend,
  BiCurrentLocation,
  BiSearchAlt2
} from 'react-icons/bi';
export const StorageModal = ({
  id,
  onSubmit,
  text,
  icon
}: {
  id: string;
  onSubmit: (id: string) => void;
  text?: string;
  icon?: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      {icon ? (
        <Button bg={'red.500'} color="white" onClick={onOpen}>
          <Icon fontSize={'xl'} as={BiCurrentLocation} />
        </Button>
      ) : (
        <Button
          w={'full'}
          bg={'green.500'}
          color="white"
          rightIcon={<BiTask />}
          onClick={onOpen}
        >
          Depoya Gönder
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Ürünün barkodunu basıp etiketlediğinizi kontrol edin.
            <br />
            {text
              ? `${text}  göndermek istediğinize emin misiniz?`
              : ' Depoya göndermek istediğinize emin misiniz?'}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={(e: React.MouseEvent) => onSubmit(id)}
              bg={'green.500'}
              color="white"
            >
              {text ? text : 'Depoya'} Gönder
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
