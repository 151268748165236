import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { materialsFromSku } from './materialsFromSku';

interface Props {
  sku: string;
  locale: string;
}

const RenderMaterials: React.FC<Props> = ({ locale, sku }) => {
  const a = materialsFromSku(sku, locale);
  const b = Object.entries(a);

  return (
    <Box>
      {b.map((item: any, i) => {
        return (
          <Box key={i}>
            <Flex
              justifyContent={{
                base: 'start',
                md: 'flex-start'
              }}
              alignItems={'center'}
              mb={2}
              fontSize="15px"
            >
              <Box
                rounded={'full'}
                w={{
                  base: 2,
                  md: 4
                }}
                h={{
                  base: 2,
                  md: 4
                }}
                mr={2}
                // backgroundColor={item[1]['code']}
              ></Box>
              {item[0]}: {item[1] ? item[1][locale] : 'HATALI RENK'}
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

export default RenderMaterials;
