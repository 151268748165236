import React, { useState, useEffect } from 'react';
import {
  useOrderDetailQuery,
  useToggleOrderUpdateMutation,
  useUpdateShippingMutation,
  useReceivedManuelShippingMutation,
  useReceivedStoreShippingMutation,
  useUpdateOrderShippingAddressMutation,
  useUpdateOrderShippingPhoneMutation
} from '../../generated/graphql';
import { Layout } from './../Layout';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from 'axios';
import formatMoney from '../../utils/formatMoney';
import { GrCheckboxSelected, GrCheckbox } from 'react-icons/gr';
import { AiOutlineLoading } from 'react-icons/ai';
import { FcCheckmark, FcBookmark, FcPrint } from 'react-icons/fc';
import {
  FaCheck,
  FaPencilAlt,
  FaShippingFast,
  FaStore,
  FaTruck
} from 'react-icons/fa';
import RenderMaterials from '../../utils/renderMaterials';
import phoneFormater from '../../utils/phoneFormater';
import 'dayjs/locale/tr';

import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  SimpleGrid,
  Button,
  Icon,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  GridItem,
  Text,
  Image,
  Input,
  Textarea
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import { getSizeFromSku } from '../../utils/getSizeFromSku';

dayjs.locale('tr');

export const OrderDetail = () => {
  const params = useParams();
  const [toggleOrderUpdate] = useToggleOrderUpdateMutation();
  const [updateShipping] = useUpdateShippingMutation();
  const [receivedManuelShipping] = useReceivedManuelShippingMutation();
  const [receivedStoreShipping] = useReceivedStoreShippingMutation();
  const [disableShipping, setDisableShipping] = useState(false);
  const [cargoStatus, setCargoStatus] = useState('');

  const { data, loading, error, refetch } = useOrderDetailQuery({
    variables: {
      id: params.id as string
    }
  });

  const [address, setAddress] = useState(data?.order.shippingAddress || '');
  const [phone, setPhone] = useState(data?.order.shippingPhoneNumber || '');
  const [city, setCity] = useState(data?.order.shippingCity || '');
  const [isPhone, setIsPhone] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const toast = useToast();

  let totalPrice = 0;
  let totalPaidPrice = 0;
  let iyzico = 0;
  let iyzicoCurrency = 'TRY';
  let currency = 'TRY';

  const [updateAddress] = useUpdateOrderShippingAddressMutation();
  const [updatePhone] = useUpdateOrderShippingPhoneMutation();
  useEffect(() => {
    if (data?.order) {
      setAddress(data?.order.shippingAddress || '');
      setPhone(data?.order.shippingPhoneNumber || '');
      setCity(data?.order.shippingCity || '');
    }
  }, [data]);
  useEffect(() => {
    const getCargoStatus = async () => {
      if (data?.order.cargoKey) {
        const res = await axios.get(
          `${process.env.REACT_APP_CARGOAPI}cargo/tracking/${
            data?.order!.cargoKey
          }`
        );
        if (res.data) {
          setCargoStatus(res.data);
        }
      }
    };
    getCargoStatus();
  }, [cargoStatus, data]);

  const handleOrderUpdate = async (status: boolean) => {
    try {
      const result = await toggleOrderUpdate({
        variables: {
          id: params.id as string,
          isRead: status
        }
      });
      if (result) {
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateShipping = async () => {
    try {
      setDisableShipping(true);
      const result = await axios({
        url: `${process.env.REACT_APP_APIURL}createCargo`,
        method: 'POST',
        data: {
          token: process.env.REACT_APP_CARGOTOKEN,
          orderId: data?.order.id
        }
      });
      console.log('🌵💜🐢', result.data);

      if (result.data.result === 'Kargo oluşturuldu.') {
        toast({
          status: 'success',
          description:
            'Kargo barkodu başarıyla oluşturuldu. Yazıcıdan barkod çıktısını alarak ürünü kargoya hazır hale getirebilirsiniz.',
          title: 'Başarılı'
        });
        return refetch();
      } else {
        toast({
          status: 'warning',
          description: result.data.result,
          title: 'Uyarı'
        });
      }
    } catch (error: any) {
      setDisableShipping(false);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };
  const handleUpdatePhone = async () => {
    try {
      await updatePhone({
        variables: {
          id: params.id as string,
          phone: phone
        }
      });
      toast({
        status: 'success',
        description: 'Telefon numarası güncellendi.',
        title: 'Başarılı'
      });
      setIsPhone(false);
      return refetch();
    } catch (error: any) {
      console.log('🌵💜🐢 error', error);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };
  const handleCheckShipping = async () => {
    try {
      setDisableShipping(true);
      const result = await axios({
        url: `${process.env.REACT_APP_APIURL}followCargo?token=${process.env.REACT_APP_CARGOTOKEN}&orderId=${data?.order.id}`,
        method: 'GET'
      });
      console.log('🌵💜🐢', result.data);

      if (result.data.result === 'Kargo bilgileri alındı.') {
        toast({
          status: 'success',
          description: 'Kargo bilgileri alındı.',
          title: 'Başarılı'
        });
        return refetch();
      } else {
        toast({
          status: 'warning',
          description: result.data.result,
          title: 'Uyarı'
        });
      }
      setDisableShipping(false);
    } catch (error) {
      setDisableShipping(false);
    }
  };

  const handleCancelShipping = async () => {
    try {
      setDisableShipping(true);
      const result = await axios({
        url: `${process.env.REACT_APP_APIURL}cancelCargo`,
        method: 'POST',
        data: {
          token: process.env.REACT_APP_CARGOTOKEN,
          orderId: data?.order.id
        }
      });
      console.log('🌵💜🐢', result.data);

      if (result.data.result === 'Kargo iptal edildi.') {
        toast({
          status: 'success',
          description: 'Kargo iptal edildi.',
          title: 'Başarılı'
        });
        return refetch();
      } else {
        toast({
          status: 'warning',
          description: result.data.result,
          title: 'Uyarı'
        });
      }
      setDisableShipping(false);
    } catch (error) {
      setDisableShipping(false);
    }
  };

  const handleCreateArasShipping = async () => {
    try {
      const employerId = localStorage.getItem('userId');
      setDisableShipping(true);
      const result = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/create`,
        method: 'POST',
        data: {
          username: process.env.REACT_APP_CARGOUSER,
          password: process.env.REACT_APP_CARGOUSERPASS,
          ReceiverName:
            data?.order.shippingFirstName + ' ' + data?.order.shippingLastName,
          ReceiverAddress:
            data?.order.shippingAddress +
            ' ' +
            data?.order.shippingCity +
            ' ' +
            data?.order.shippingCountry,
          ReceiverPhone1: phoneFormater(
            data?.order.user?.phoneNumber?.trim() || ''
          ),
          ReceiverCityName: data?.order.shippingCity,
          ReceiverTownName: data?.order.shippingPostalCode,
          PayorTypeCode: 1,
          employerId: employerId,
          orderId: data?.order.conversationId,
          note: '',
          userId: data?.order.user?.id,
          email: data?.order.shippingEmail
            ? data?.order.shippingEmail
            : data?.order.user?.email,
          language: data?.order.language
        }
      });
      console.log('🌵💜🐢', result.data);

      if (result.data.message[0] === 'Başarılı') {
        await updateShipping({
          variables: {
            id: data?.order.id as string,
            cargoNumber: result.data.IntegrationCode
          }
        });
        toast({
          status: 'success',
          description: 'Kargo barkodu başarıyla oluşturuldu.',
          title: 'Başarılı'
        });
        setDisableShipping(false);

        return refetch();
      } else {
        toast({
          status: 'warning',
          description: 'hata',
          title: 'Uyarı'
        });
        setDisableShipping(false);
      }
    } catch (error: any) {
      setDisableShipping(false);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };

  const handlePrintBarcode = async () => {
    try {
      const employerId = localStorage.getItem('userId');
      setDisableShipping(true);
      const result = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/barcode`,
        method: 'POST',
        data: {
          username: process.env.REACT_APP_CARGOUSER,
          password: process.env.REACT_APP_CARGOUSERPASS,
          IntegrationCode: data?.order.cargoKey
        }
      });
      console.log('🌵💜🐢', result.data);

      if (
        result.data.message[0] ===
        'Başarılı bir şekilde barkod bilgileriniz oluşturulmuştur.'
      ) {
        await updateShipping({
          variables: {
            id: data?.order.id as string,
            trackingNumber: result.data.trackingNumber
          }
        });
        toast({
          status: 'success',
          description: 'Kargo barkodu başarıyla yazdırılmıştır.',
          title: 'Başarılı'
        });
        setDisableShipping(false);

        return refetch();
      } else {
        if (result.data.message[0]) {
          setDisableShipping(false);
          return toast({
            status: 'warning',
            description: result.data.message[0],
            title: 'Uyarı'
          });
        }
        toast({
          status: 'warning',
          description: 'hata',
          title: 'Uyarı'
        });
        setDisableShipping(false);
      }
    } catch (error: any) {
      setDisableShipping(false);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };
  const handleUpdateAddress = async () => {
    try {
      await updateAddress({
        variables: {
          id: data?.order.id as string,
          address: address,
          city: city
        }
      });
      toast({
        status: 'success',
        description: 'Adres başarıyla güncellendi.',
        title: 'Başarılı'
      });
      return refetch();
    } catch (error: any) {
      console.log('🌵💜🐢 error', error);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };
  const handleCancelCargo = async () => {
    try {
      const employerId = localStorage.getItem('userId');
      setDisableShipping(true);
      const result = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/cancel`,
        method: 'POST',
        data: {
          username: process.env.REACT_APP_CARGOUSER,
          password: process.env.REACT_APP_CARGOUSERPASS,
          IntegrationCode: data?.order.cargoKey,
          employerId: employerId
        }
      });
      console.log('🌵💜🐢', result.data);

      if (result.data.message[0] === 'Başarılı') {
        await updateShipping({
          variables: {
            id: data?.order.id as string,
            trackingNumber: '',
            cargoNumber: ''
          }
        });
        toast({
          status: 'success',
          description: 'Kargo barkodu başarıyla iptal edildi.',
          title: 'Başarılı'
        });
        setDisableShipping(false);

        return refetch();
      } else {
        toast({
          status: 'warning',
          description: 'hata',
          title: 'Uyarı'
        });
        setDisableShipping(false);
      }
    } catch (error: any) {
      setDisableShipping(false);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };

  const handleStoreShipping = async () => {
    try {
      setDisableShipping(true);

      const store = await receivedStoreShipping({
        variables: {
          id: data?.order.id as string
        }
      });
      if (store.data?.receivedStoreShipping.id) {
        toast({
          status: 'success',
          description: 'Kargo mağazaya teslim edildi.',
          title: 'Başarılı'
        });
        setDisableShipping(false);

        return refetch();
      }
    } catch (error) {
      console.log('🌵💜🐢 error', error);
    }
  };

  const handleManuelShipping = async () => {
    try {
      setDisableShipping(true);
      const manuel = await receivedManuelShipping({
        variables: {
          id: data?.order.id as string
        }
      });
      if (manuel.data?.receivedManuelShipping.id) {
        toast({
          status: 'success',
          description: 'Kargo manuel teslim edildi.',
          title: 'Başarılı'
        });
        setDisableShipping(false);

        return refetch();
      }
    } catch (error) {
      console.log('🌵💜🐢 error', error);
    }
  };

  const renderOrder = () => {
    if (loading) {
      return (
        <Box>
          <Spinner mx={'auto'} />
        </Box>
      );
    }
    if (error) {
      return <Box>Bir hata oluştu. {error.message}</Box>;
    }
    if (data) {
      data.order.payment.map((p: any) => {
        totalPrice = totalPrice + p.price;
        currency = p.currency ? p.currency : 'TRY';
        totalPaidPrice = totalPaidPrice + p.paidPrice;
      });
      return (
        <Box>
          <Box
            my={4}
            display="flex"
            experimental_spaceX={4}
            justifyContent={'flex-end'}
          >
            <Button
              className="inline-flex items-center px-4 py-2 font-semibold text-gray-800 bg-white rounded hover:bg-gray-400"
              // onClick={() => {
              //   handleOrderUpdate(true);
              //   window.print();
              // }}
              as={Link}
              to={`/order-print/${data.order.id}`}
            >
              <FcPrint className="text-xl" />
            </Button>

            <Menu>
              <MenuButton>Kargo İşlemleri</MenuButton>
              <MenuList>
                {data.order.shippingStatus === 'PENDING' &&
                  !data.order.cargoKey &&
                  !data.order.shippingNumber && (
                    <MenuItem>
                      <Button
                        disabled={disableShipping}
                        variant={'outline'}
                        onClick={handleCreateShipping}
                      >
                        <Icon as={FaTruck} mr={2} /> Yurtiçi ile Kargo Çık
                      </Button>
                    </MenuItem>
                  )}
                {data.order.shippingStatus !== 'PENDING' &&
                  data.order.shippingStatus !== 'SHIPPED' &&
                  data.order.shippingStatus !== 'COMPLETED' &&
                  data.order.shippingStatus !== 'Kargo teslim edilmiştir.' &&
                  data.order.shippingMethod !== 'Aras Kargo' &&
                  !data.order.shippingNumber && (
                    <MenuItem>
                      <Button
                        disabled={disableShipping}
                        variant={'outline'}
                        onClick={handleCheckShipping}
                      >
                        <Icon as={FaTruck} mr={2} /> Kargo Bilgilerini Güncelle
                      </Button>
                    </MenuItem>
                  )}
                {!data.order.shippingNumber && (
                  <MenuItem>
                    <Button
                      disabled={disableShipping}
                      variant={'outline'}
                      onClick={handleStoreShipping}
                    >
                      <Icon as={FaStore} mr={2} /> Mağazadan Teslim Alındı
                    </Button>
                  </MenuItem>
                )}
                {!data.order.shippingNumber && (
                  <MenuItem>
                    <Button
                      disabled={disableShipping}
                      variant={'outline'}
                      onClick={handleManuelShipping}
                    >
                      <Icon as={FaShippingFast} mr={2} /> Teslim Edildi
                    </Button>
                  </MenuItem>
                )}
                {!data.order.shippingNumber && (
                  <MenuItem>
                    <Button
                      disabled={disableShipping}
                      variant={'outline'}
                      onClick={handleCreateArasShipping}
                    >
                      <Icon as={FaTruck} mr={2} /> Aras ile Kargo Çık
                    </Button>
                  </MenuItem>
                )}
                {data.order.cargoKey &&
                  !data.order.shippingNumber &&
                  data.order.shippingMethod === 'Aras Kargo' && (
                    <MenuItem>
                      <Button
                        disabled={disableShipping}
                        variant={'outline'}
                        onClick={handlePrintBarcode}
                      >
                        <Icon as={FaTruck} mr={2} /> Barkodu Yazdır
                      </Button>
                    </MenuItem>
                  )}
                {data.order.cargoKey &&
                  !data.order.shippingNumber &&
                  data.order.shippingMethod === 'Aras Kargo' && (
                    <MenuItem>
                      <Button
                        disabled={disableShipping}
                        variant={'outline'}
                        onClick={handleCancelCargo}
                      >
                        <Icon as={FaTruck} mr={2} /> Aras Kargoyu İptal Et
                      </Button>
                    </MenuItem>
                  )}
              </MenuList>
            </Menu>
            {data.order.shippingStatus !== 'PENDING' &&
              data.order.shippingStatus !== 'SHIPPED' &&
              data.order.shippingStatus !== 'COMPLETED' &&
              data.order.shippingStatus !== 'Kargo teslim edilmiştir.' &&
              data.order.shippingStatus === 'WAITING' && (
                <Button
                  disabled={disableShipping}
                  variant={'outline'}
                  onClick={handleCancelShipping}
                >
                  <Icon as={FaTruck} mr={2} /> Kargoyu İptal Et
                </Button>
              )}
            {data.order.isRead && (
              <Button onClick={() => handleOrderUpdate(false)}>
                <Icon as={FcCheckmark} />
              </Button>
            )}
          </Box>
          <Box id="printArea">
            <SimpleGrid
              columns={{
                base: 1
              }}
              gap={4}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Üye Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> İsim Soyisim</Th>
                        <Td>
                          {data.order.user?.name} {data.order.user?.surname}
                        </Td>
                        -<Td>{data.order.user?.email}</Td>-
                        <Td>{data.order.user?.phoneNumber}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> İsim Soyisim</Th>
                        <Td>
                          {data.order.shippingFirstName}{' '}
                          {data.order.shippingLastName}
                        </Td>
                        -
                        <Td>
                          {data.order.shippingEmail
                            ? data.order.shippingEmail
                            : 'Email Adresi Yok'}
                        </Td>
                        - -
                        <Td w={'25%'}>
                          {isAddress ? (
                            <Box>
                              <Textarea
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                              <Input
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                              <Button
                                onClick={() => handleUpdateAddress()}
                                mx={2}
                              >
                                <Icon as={FaCheck} />
                              </Button>
                            </Box>
                          ) : (
                            <Box>
                              {data.order.shippingAddress}{' '}
                              {data.order.shippingCity}/
                              {data.order.shippingCountry}{' '}
                              <Button
                                onClick={() => setIsAddress(!isAddress)}
                                mx={2}
                              >
                                <Icon as={FaPencilAlt} />
                              </Button>
                            </Box>
                          )}
                        </Td>
                        -
                        <Td>
                          {isPhone ? (
                            <Box w={'55%'}>
                              <Input
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                              <Button
                                onClick={() => handleUpdatePhone()}
                                mx={2}
                              >
                                <Icon as={FaCheck} />
                              </Button>
                            </Box>
                          ) : (
                            <Box>
                              {data.order.shippingNumber
                                ? data.order.shippingPhoneNumber
                                : 'Telefon Numarası Yok'}
                              <Button
                                onClick={() => setIsPhone(!isPhone)}
                                mx={2}
                              >
                                <Icon as={FaPencilAlt} />
                              </Button>
                            </Box>
                          )}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <SimpleGrid
              columns={{
                base: 1
              }}
              gap={4}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Sipariş Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width="150px">Tarih</Th>
                        <Td>
                          {dayjs(data.order.createdAt).format(
                            'DD MMMM YYYY HH:mm:ss'
                          )}
                        </Td>
                        -<Td>{data.order.orderStatus}</Td>-
                        <Td>{data.order.conversationId}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={3} gap={4}>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Üretimi Yapan
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}></Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Denetleyen
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> </Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Yapan
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> </Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Ürünler
                </Box>
                <Table variant={'striped'}>
                  <Thead
                    sx={{
                      '@media print': {
                        display: 'table-header-group',
                        visibility: 'visible'
                      }
                    }}
                    display={{
                      base: 'none',
                      md: 'table-header-group'
                    }}
                  >
                    <Tr>
                      <Th className="w-40 px-5 py-2 text-center bg-default-600">
                        Fotoğraf
                      </Th>
                      <Th className="px-5 py-2 whitespace-nowrap bg-default-600">
                        SKU
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Ürün</Th>
                      <Th className="px-5 py-2 text-center w-42 bg-default-600">
                        Beden
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Seçenekler</Th>
                      <Th>Hediye Kartı</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.order.products.map((p, i) => {
                      const description = p.product.productGroup.description;
                      let title = 'Ürün İsimi Yok';
                      if (description) {
                        const getTitle = description.find(
                          (d) => d.language === 'tr'
                        );
                        title = getTitle?.title ? getTitle.title : '';
                      }
                      return (
                        <Tr key={i}>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'none',
                                visibility: 'hidden'
                              }
                            }}
                            display={{
                              base: 'table-cell',
                              md: 'none'
                            }}
                          >
                            <Box bgColor={'gray.100'} pb={4} textAlign="center">
                              <Box mb={2}>
                                <img
                                  src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=500&height=500&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}
                                  className="mx-auto"
                                  alt="product"
                                />
                              </Box>
                              <Box mb={2} fontWeight="semibold">
                                {title}
                              </Box>
                              <Box mb={2}>
                                <Box>{p.product.sku}</Box>
                                <Box>
                                  <div className="text-xs text-gray-500">
                                    {p.product.barcode}
                                  </div>
                                </Box>
                              </Box>
                              <Box my={4}>
                                {getSizeFromSku(p.product.sku)}{' '}
                                {p.ringSize && ` - ${p.ringSize}`}
                              </Box>
                              <Box>
                                <RenderMaterials
                                  locale="tr"
                                  sku={p.product.sku!}
                                />
                              </Box>
                            </Box>
                          </Td>

                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=50&quality=60`}
                              className="mx-auto"
                              alt="product"
                            />
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {p.product.sku}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <div className="text-xs text-gray-500">
                              {p.product.barcode}
                            </div>
                            <div>{title}</div>

                            {p.additionalInfo && (
                              <div className="font-semibold">
                                <hr className="my-2" />
                                {p.additionalInfo}
                              </div>
                            )}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {getSizeFromSku(p.product.sku)}{' '}
                            {p.ringSize && ` - ${p.ringSize}`}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <RenderMaterials locale="tr" sku={p.product.sku!} />
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {p.giftCard && (
                              <Box>
                                <Image
                                  width={250}
                                  height={150}
                                  src={`${process.env.REACT_APP_CDNURL}/card/${p.giftCard}?width=400&height=300`}
                                />
                                <Text fontWeight={'bold'}>{p.giftNote}</Text>
                              </Box>
                            )}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Box>

            <SimpleGrid
              columns={{
                base: 1,
                md: 2
              }}
              gap={4}
              sx={{
                '@media print': {
                  display: 'none',
                  visibility: 'hidden'
                }
              }}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th>İsim Soyisim</Th>
                        <Td>
                          {data.order.shippingFirstName}{' '}
                          {data.order.shippingLastName}
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Adres</Th>
                        <Td>
                          {data.order.shippingAddress}{' '}
                          {data.order.shippingPostalCode}
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Şehir</Th>
                        <Td>{data.order.shippingCity}</Td>
                      </Tr>
                      <Tr>
                        <Th>Ülke</Th>
                        <Td>{data.order.shippingCountry}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>

              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th>Kargo Firması</Th>
                        <Td>{data.order.shippingMethod}</Td>
                      </Tr>

                      <Tr>
                        <Th>Kargo Durumu</Th>
                        <Td>
                          {cargoStatus
                            ? cargoStatus
                            : data.order.shippingStatus}
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Kargo Ücreti</Th>
                        <Td>
                          {data.order.shippingPrice &&
                          data.order.shippingPrice.price
                            ? formatMoney(
                                data.order.shippingPrice.price,
                                data.order.shippingPrice.currency.code
                              )
                            : '-'}
                        </Td>
                      </Tr>
                      {data.order.orderStatus === 'SUCCESS' &&
                        data.order.shippingStatus === 'PENDING' &&
                        !data.order.isCompleted && (
                          <Tr>
                            <Th>Kargo Bilgisi</Th>
                            <Td>kargo</Td>
                          </Tr>
                        )}

                      <Tr>
                        <Th>Kargo Numarası</Th>
                        <Td>
                          <div className="flex items-center justify-between space-x-2">
                            <div>
                              {data.order.shippingNumber
                                ? data.order.shippingNumber
                                : '???'}
                            </div>
                            teslim
                          </div>
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Kargo Anahtarı</Th>
                        <Td>
                          <div className="flex items-center justify-between space-x-2">
                            <div>{data.order.cargoKey}</div>
                          </div>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
          </Box>
        </Box>
      );
    }
    return <Box>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Box>;
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Sipariş Detayı" />
        <Box>{renderOrder()}</Box>
      </Container>
    </Layout>
  );
};
