import React, { useEffect, useState } from 'react';
import { Layout } from '../Layout';
import axios from 'axios';
import 'dayjs/locale/tr';
import { Link } from 'react-router-dom';
import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Select,
  Spinner,
  Button,
  FormControl,
  useToast,
  Input,
  Flex
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import Pagination from '../ui/Pagination';
import dayjs from 'dayjs';
interface Cargo {
  id: string;
  name: string;
  phoneNumber: string;
  address: string;
  integrationId: string;
  trackingNumber: string;
  customCargo: boolean;
  note: string;
  status: string;
  orderId: string;
  createdAt: string;
  sendMail: boolean;
}

const AllCargo = () => {
  const [cargo, setCargo] = useState([]);
  const recordsPerPage = 30;
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const skip = (page - 1) * recordsPerPage;

  const toast = useToast();
  const getAllData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CARGOAPI}cargo/page?page=${page}&limit=${recordsPerPage}`
    );
    setCargo(res.data);
  };
  const getAllDataByName = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CARGOAPI}cargo/find?name=${name}`
    );
    setCargo(res.data);
  };
  useEffect(() => {
    getAllData();
  }, [page]);
  const onControl = async () => {
    try {
      const res = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/updateAll`,
        method: 'POST',
        data: {
          username: 'doruk.karaboncuk',
          password: 'Doruk123!!',
          IntegrationCode: '',
          queryType: '39'
        }
      });
      if (res.data === 'ok') {
        toast({
          status: 'success',
          description: 'Kargo durumları başarıyla güncellendi.',
          title: 'Başarılı'
        });
        getAllData();
      } else {
        toast({
          status: 'error',
          description: 'Kargo durumları güncellenemedi.',
          title: 'Hata'
        });
      }
    } catch (error: any) {
      console.log('🌵💜🐢 error', error);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata'
      });
    }
  };
  const cancelCargo = async (e: any, code: string) => {
    e.preventDefault();
    console.log('🌵💜🐢', 'aaa');

    const userId = localStorage.getItem('userId');
    const result = await axios({
      url: `${process.env.REACT_APP_CARGOAPI}cargo/cancel`,
      method: 'POST',
      data: {
        username: process.env.REACT_APP_CARGOUSER,
        password: process.env.REACT_APP_CARGOUSERPASS,
        employerId: userId,
        IntegrationCode: code
      }
    });
    if (result.data.message[0] === 'Başarılı') {
      toast({
        status: 'success',
        description: 'Kargo barkodu başarıyla iptal edildi.',
        title: 'Başarılı'
      });
      getAllData();
    } else {
      toast({
        status: 'warning',
        description: 'Kargo iptal edilemedi.',
        title: 'Uyarı'
      });
    }
  };
  const sendMail = async (id: string) => {
    const result = await axios({
      url: `${process.env.REACT_APP_CARGOAPI}cargo/send/${id}`,
      method: 'GET'
    });
    if (result.data.message) {
      toast({
        status: 'success',
        description: result.data.message,
        title: 'Başarılı'
      });
      getAllData();
    } else {
      toast({
        status: 'warning',
        description: 'Kargo iptal edilemedi.',
        title: 'Uyarı'
      });
    }
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Kargolar" />
        <Box mb={4}>
          <Flex>
            <Button onClick={onControl}>Kargoları Kontrol Et</Button>
            <Box mx={2} w={'25%'}>
              <Flex>
                <Input
                  placeholder="İsim"
                  name={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Button mx={2} onClick={getAllDataByName}>
                  Ara
                </Button>
              </Flex>
            </Box>
          </Flex>
          <Table variant={'striped'} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>İsim Soyisim</Th>
                <Th textAlign={'center'}>Telefon Numarası</Th>
                <Th>Adres</Th>
                <Th textAlign={'center'}>Kargo Kodu</Th>
                <Th textAlign={'center'}>Takip Kodu</Th>
                <Th textAlign={'center'}>Kargo Nedeni</Th>
                <Th textAlign={'center'}>Kargo Durumu</Th>
                <Th textAlign={'center'}>Kargo Notu</Th>
                <Th textAlign={'center'}>Oluşturma Tarihi</Th>
                <Th textAlign={'center'}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {cargo.length > 0 &&
                cargo.map((item: Cargo, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{index + skip + 1}</Td>
                      <Td>{item.name}</Td>
                      <Td textAlign={'center'}>{item.phoneNumber}</Td>
                      <Td>{item.address}</Td>
                      <Td textAlign={'center'}>{item.integrationId}</Td>
                      <Td textAlign={'center'}>{item.trackingNumber}</Td>
                      <Td textAlign={'center'}>
                        {item.customCargo ? (
                          'Özel Kargo'
                        ) : (
                          <Link to={`/order-detail/${item.orderId}`}>
                            Sipariş
                          </Link>
                        )}
                      </Td>
                      <Td textAlign={'center'}>{item.status}</Td>
                      <Td textAlign={'center'}>{item.note}</Td>
                      <Td textAlign={'center'}>
                        {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                      </Td>
                      <Td>
                        {item.status === 'Kargo Hazırlandı' ? (
                          <Button
                            onClick={(e) => cancelCargo(e, item.integrationId)}
                            bg={'red.500'}
                            _hover={{
                              bg: 'red.600'
                            }}
                            color={'white'}
                          >
                            İptal Et
                          </Button>
                        ) : item.status === 'Kargolandı' ? (
                          <Button
                            onClick={(e) => cancelCargo(e, item.integrationId)}
                            bg={'red.500'}
                            _hover={{
                              bg: 'red.600'
                            }}
                            color={'white'}
                          >
                            İptal Et
                          </Button>
                        ) : (
                          <></>
                        )}

                        {!item.sendMail && item.trackingNumber ? (
                          <Button
                            onClick={(e) => sendMail(item.id)}
                            color={'white'}
                            bg={'blue.400'}
                          >
                            Mail At
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Container>
      <Pagination page={page} setPage={setPage} totalPages={totalPages} />
    </Layout>
  );
};

export default AllCargo;
