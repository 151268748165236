import { extendTheme } from "@chakra-ui/react";

const components = {
   Table: {
      baseStyle: {
         thead: {
            th: {
               fontSize: "12px",
               paddingTop: "5px",
               paddingBottom: "5px",
               paddingLeft: "7px",
               paddingRight: "7px"
            }
         },
         tbody: {
            td: {
               fontSize: "14px",
               paddingTop: "5px",
               paddingBottom: "5px",
               paddingLeft: "7px",
               paddingRight: "7px"
            }
         }
      }
   },
   Button: {
      baseStyle: {
         _focus: { boxShadow: "none", outline: "none" },
      }
   }
};

const styles = {
   global: {
      "*:focus": {
         boxShadow: "none !important"
      },
      "html, body": {
         WebkitFontSmoothing: "antialiased",
         MozOsxFontSmoothing: "grayscale"
      }
   }
};

export const chakraTheme = extendTheme({ components, styles });