import { ColorModeScript } from '@chakra-ui/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import AppRouter from './router/index';
import './styles/style.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-medium-image-zoom/dist/styles.css';

const container: any = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ColorModeScript />
    </QueryClientProvider>
  </React.StrictMode>
);
