/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Box,
  Container,
  Text,
  Button,
  useToast,
  SimpleGrid,
  Spinner,
  GridItem,
  Input,
  Image,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormLabel
} from '@chakra-ui/react';
import Heading from '../ui/Heading';
import Select from 'react-select';
import {
  useProductionsReportQuery,
  UserType,
  useUserQuery
} from '../../generated/graphql';
import dayjs from 'dayjs';
import RenderMaterials from '../../utils/renderMaterials';
import Pagination from '../ui/Pagination';
import {
  FcFilledFilter,
  FcEmptyFilter,
  FcClearFilters,
  FcList
} from 'react-icons/fc';
import { ProductSelect } from '../selects/ProductSelect';
import { CategorySelect, SubCategorySelect } from '../selects/CategorySelect';
export const ProductionReports = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [product, setProduct] = useState('');
  const [userId, setUserId] = useState('');
  const [startAt, setStartAt] = useState(
    dayjs().format('YYYY-MM-DDT00:00:00.sssZ')
  );
  const [endAt, setEndAt] = useState(
    dayjs().format('YYYY-MM-DDT23:59:59.sssZ')
  );
  const [orderBy, setOrderBy] = useState('createdAt_DESC');
  const recordsPerPage = 100;
  const skip = (page - 1) * recordsPerPage;

  const { data, loading, error } = useProductionsReportQuery({
    variables: {
      first: recordsPerPage,
      skip,
      category: subCategory ? subCategory : category,
      endAt,
      startAt,
      productGroup: product,
      user: userId,
      orderBy: orderBy
    }
  });
  const {
    data: userData,
    loading: userLoading,
    error: userError
  } = useUserQuery({
    variables: {
      first: 1000,
      skip: 0,
      userType: UserType.Production
    }
  });

  useEffect(() => {
    if (!loading && data) {
      setTotalPages(
        Math.ceil(data.productionsReport.productionCount / recordsPerPage)
      );
    }
  }, [data, loading]);

  const handleDateChange = (e: any) => {
    setStartAt(dayjs(e).format('YYYY-MM-DDT00:00:00.sssZ'));
  };
  const handleEndDateChange = (e: any) => {
    setEndAt(dayjs(e).format('YYYY-MM-DDT23:59:59.sssZ'));
  };

  return (
    <Layout>
      <Container my={4} maxW={'container.xl'}>
        <Heading title="Üretim Raporları" />
        <Button
          as={'a'}
          href={`${process.env.REACT_APP_APIURL}report-production?${
            startAt && `startAt=${startAt}&`
          }${endAt && `endAt=${endAt}&`}${
            category && `category=${subCategory ? subCategory : category}&`
          }${product && `productGroup=${product}&`}${
            userId && `user=${userId}&`
          }${orderBy && `orderBy=${orderBy}`}`}
        >
          <FcList />
        </Button>
        <Box my={2}>
          <Button onClick={() => setIsFiltered(!isFiltered)}>
            <FcEmptyFilter />
          </Button>
        </Box>
        {isFiltered && (
          <Box my={2}>
            <Box>
              <SimpleGrid
                display={{
                  base: 'flex',
                  md: 'grid'
                }}
                flexDirection={{
                  base: 'column',
                  md: 'row'
                }}
                columns={3}
                gap={4}
              >
                <Box>
                  <Text mb={2} fontWeight={'bold'}>
                    Kategori
                  </Text>
                  <CategorySelect
                    category={category}
                    setCategory={setCategory}
                  />
                </Box>
                {category && (
                  <Box>
                    <Text mb={2} fontWeight={'bold'}>
                      Alt Kategori
                    </Text>

                    <SubCategorySelect
                      category={category}
                      setSubCategory={setSubCategory}
                    />
                  </Box>
                )}
                {subCategory && (
                  <Box>
                    <Text mb={2} fontWeight={'bold'}>
                      Ürün
                    </Text>
                    <ProductSelect
                      subCategory={subCategory}
                      setProduct={setProduct}
                    />
                  </Box>
                )}
              </SimpleGrid>
            </Box>
            <Box my={2}>
              <SimpleGrid
                display={{
                  base: 'flex',
                  md: 'grid'
                }}
                flexDirection={{
                  base: 'column',
                  md: 'row'
                }}
                columns={3}
                gap={4}
              >
                <Box>
                  <Text mb={2} fontWeight={'bold'}>
                    Üretimi Yapan Kişi
                  </Text>
                  <Select
                    options={userData?.users.users.map((user) => ({
                      value: user.id,
                      label: user.name + ' ' + user.surname
                    }))}
                    value={
                      userId
                        ? {
                            value: userId,
                            label:
                              userData?.users.users.find((u) => u.id === userId)
                                ?.name +
                                ' ' +
                                userData?.users.users.find(
                                  (u) => u.id === userId
                                )?.surname || ''
                          }
                        : {}
                    }
                    onChange={(e: any) => {
                      setUserId(e.value);
                    }}
                  />
                </Box>
                <Box>
                  <Text mb={2} fontWeight={'bold'}>
                    Sıralama
                  </Text>
                  <Select
                    options={[
                      { value: 'createdAt_ASC', label: 'En Eski' },
                      { value: 'createdAt_DESC', label: 'En Yeni' }
                    ]}
                    value={{
                      value: orderBy,
                      label:
                        orderBy === 'createdAt_ASC'
                          ? 'En Eski'
                          : orderBy === 'createdAt_DESC'
                          ? 'En Yeni'
                          : ''
                    }}
                    onChange={(e: any) => {
                      setOrderBy(e.value);
                    }}
                  />
                </Box>
              </SimpleGrid>
            </Box>
            <Flex
              alignItems={'center'}
              flexDirection={{
                base: 'column',
                md: 'row'
              }}
              justifyContent="start"
              gap={4}
            >
              <Box>
                Başlangıç
                <Input
                  type="date"
                  defaultValue={dayjs(startAt.toString()).format('YYYY-MM-DD')}
                  onChange={(e: any) => handleDateChange(e.target.value)}
                />
              </Box>

              <Box>
                Bitiş
                <Input
                  type="date"
                  defaultValue={dayjs(endAt.toString()).format('YYYY-MM-DD')}
                  onChange={(e: any) => handleEndDateChange(e.target.value)}
                />
              </Box>
            </Flex>
            <Box my={2}>
              <Button
                onClick={() => {
                  setCategory('');
                  setSubCategory('');
                  setProduct('');
                  setUserId('');
                  setStartAt(dayjs().format('YYYY-MM-DDT00:00:00.sssZ'));
                  setEndAt(dayjs().format('YYYY-MM-DDT23:59:59.sssZ'));
                }}
              >
                <FcClearFilters />
              </Button>
            </Box>
          </Box>
        )}
        <Box my={2}>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Ürün Kodu</Th>
                <Th>Ürün Adı</Th>
                <Th>Ürün Boyutu</Th>
                <Th>Ürün Rengi</Th>
                <Th>Üretim Tarihi</Th>
                <Th>Yapım Süresi</Th>
                <Th>Üretim Yapan Kişi</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Spinner />
              ) : (
                data?.productionsReport &&
                data.productionsReport.productions.map((production, i) => {
                  const minute = dayjs(production.finishedAt).diff(
                    dayjs(production.startedAt),
                    'minute'
                  );
                  const hour = dayjs(production.finishedAt).diff(
                    dayjs(production.startedAt),
                    'hour'
                  );
                  const second = dayjs(production.finishedAt).diff(
                    dayjs(production.startedAt),
                    'second'
                  );
                  return (
                    <Tr key={production.id}>
                      <Td>{i + 1 + skip}</Td>
                      <Td>
                        {production.product.sku?.split('-')[0]}-
                        {production.product.sku?.split('-')[1]}
                      </Td>
                      <Td>
                        {production.product.productGroup.description[0].title}
                      </Td>
                      <Td>{production.product.sku?.split('-')[2]}</Td>
                      <Td>
                        <RenderMaterials
                          sku={production.product.sku || ''}
                          locale="tr"
                        />
                      </Td>
                      <Td>
                        {dayjs(production.finishedAt).format('DD/MM/YYYY')}
                      </Td>
                      <Td>
                        {hour > 0
                          ? hour + ' saat '
                          : minute > 0
                          ? minute + ' dakika '
                          : second + ' saniye '}
                      </Td>
                      <Td>
                        {production.user.name} {production.user.surname}
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </Box>
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Layout>
  );
};
