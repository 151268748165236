/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  useOrderDetailQuery,
  useToggleOrderUpdateMutation,
  useUpdateShippingMutation
} from '../../generated/graphql';
import { Layout } from '../Layout';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from 'axios';
import formatMoney from '../../utils/formatMoney';
import { GrCheckboxSelected, GrCheckbox } from 'react-icons/gr';
import { AiOutlineLoading } from 'react-icons/ai';
import { FcCheckmark, FcBookmark, FcPrint } from 'react-icons/fc';
import { FaTruck } from 'react-icons/fa';
import RenderMaterials from '../../utils/renderMaterials';

import 'dayjs/locale/tr';

import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  SimpleGrid,
  Button,
  Icon,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  GridItem,
  Text,
  Image
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import { getSizeFromSku } from '../../utils/getSizeFromSku';

dayjs.locale('tr');

export const ShopifyOrderDetail = () => {
  const params = useParams();
  const [data, setData] = useState<any>({});
  const [disableShipping, setDisableShipping] = useState(false);
  const [cargoStatus, setCargoStatus] = useState<any>({});
  const toast = useToast();

  async function getOrders() {
    const res = await axios(
      `${process.env.REACT_APP_APIURL}shopify-order/${params?.id}`
    );
    console.log('🌵💜🐢', res.data, 'sa');

    if (res.data) {
      setData(res.data);
    }
  }

  async function getCargoStatus() {
    const res = await axios(
      `${process.env.REACT_APP_CARGOAPI}cargo/findOrder?orderId=${data.id}`
    );
    if (res.data) {
      setCargoStatus(res.data);
    }
  }

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (data.id) {
      getCargoStatus();
    }
  }, [data]);

  if (!data) {
    return <Spinner />;
  }

  const handleCreateArasShipping = async () => {
    try {
      const employerId = localStorage.getItem('userId');
      setDisableShipping(true);
      const shipping = data.shipping_address;
      const result = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/create`,
        method: 'POST',
        data: {
          username: process.env.REACT_APP_CARGOUSER,
          password: process.env.REACT_APP_CARGOUSERPASS,
          ReceiverName: shipping.first_name + ' ' + shipping.last_name,
          ReceiverAddress:
            shipping.address1 +
            ' ' +
            shipping.address2 +
            ' ' +
            shipping.city +
            ' ' +
            shipping.zip,
          ReceiverPhone1:
            shipping?.phone === ''
              ? shipping?.phone?.slice(3)
              : data?.customer?.phone?.slice(3),
          ReceiverCityName: shipping.city,
          ReceiverTownName: shipping.zip,
          PayorTypeCode: 1,
          employerId: employerId,
          orderId: data?.id.toString(),
          note: '',
          userId: data?.customer?.id.toString(),
          email: data?.contact_email ? data?.contact_email : data?.email,
          language: data?.customer_locale
        }
      });
      console.log('🌵💜🐢', result.data);

      if (result.data.message[0] === 'Başarılı') {
        toast({
          status: 'success',
          description: 'Kargo barkodu başarıyla oluşturuldu.',
          title: 'Başarılı'
        });
        setDisableShipping(false);

        getCargoStatus();
      } else {
        toast({
          status: 'warning',
          description: 'hata',
          title: 'Uyarı'
        });
        setDisableShipping(false);
      }
    } catch (error: any) {
      setDisableShipping(false);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };

  const handlePrintBarcode = async () => {
    try {
      const employerId = localStorage.getItem('userId');
      setDisableShipping(true);
      const result = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/barcode`,
        method: 'POST',
        data: {
          username: process.env.REACT_APP_CARGOUSER,
          password: process.env.REACT_APP_CARGOUSERPASS,
          IntegrationCode: cargoStatus.integrationId
        }
      });
      console.log('🌵💜🐢', result.data);

      if (
        result.data.message[0] ===
        'Başarılı bir şekilde barkod bilgileriniz oluşturulmuştur.'
      ) {
        toast({
          status: 'success',
          description: 'Kargo barkodu başarıyla yazdırılmıştır.',
          title: 'Başarılı'
        });
        setDisableShipping(false);
        getCargoStatus();
      } else {
        if (result.data.message[0]) {
          setDisableShipping(false);
          return toast({
            status: 'warning',
            description: result.data.message[0],
            title: 'Uyarı'
          });
        }
        toast({
          status: 'warning',
          description: 'hata',
          title: 'Uyarı'
        });
        setDisableShipping(false);
      }
    } catch (error: any) {
      setDisableShipping(false);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };
  const handleCancelCargo = async () => {
    try {
      const employerId = localStorage.getItem('userId');
      setDisableShipping(true);
      const result = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/cancel`,
        method: 'POST',
        data: {
          username: process.env.REACT_APP_CARGOUSER,
          password: process.env.REACT_APP_CARGOUSERPASS,
          IntegrationCode: cargoStatus?.integrationId,
          employerId: employerId
        }
      });
      console.log('🌵💜🐢', result.data);

      if (result.data.message[0] === 'Başarılı') {
        toast({
          status: 'success',
          description: 'Kargo barkodu başarıyla iptal edildi.',
          title: 'Başarılı'
        });
        setDisableShipping(false);
        getCargoStatus();
      } else {
        toast({
          status: 'warning',
          description: 'hata',
          title: 'Uyarı'
        });
        setDisableShipping(false);
      }
    } catch (error: any) {
      setDisableShipping(false);
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };
  const renderOrder = () => {
    if (data.id) {
      return (
        <Box>
          <Box
            my={4}
            display="flex"
            experimental_spaceX={4}
            justifyContent={'flex-end'}
          >
            <Button
              className="inline-flex items-center px-4 py-2 font-semibold text-gray-800 bg-white rounded hover:bg-gray-400"
              onClick={() => {
                window.print();
              }}
            >
              <FcPrint className="text-xl" />
            </Button>

            <Menu>
              <MenuButton>Kargo İşlemleri</MenuButton>
              <MenuList>
                {cargoStatus && !cargoStatus.integrationId && (
                  <MenuItem>
                    <Button
                      disabled={disableShipping}
                      variant={'outline'}
                      onClick={handleCreateArasShipping}
                    >
                      <Icon as={FaTruck} mr={2} /> Aras ile Kargo Çık
                    </Button>
                  </MenuItem>
                )}
                {cargoStatus && cargoStatus.integrationId && (
                  <MenuItem>
                    <Button
                      disabled={disableShipping}
                      variant={'outline'}
                      onClick={handlePrintBarcode}
                    >
                      <Icon as={FaTruck} mr={2} /> Barkodu Yazdır
                    </Button>
                  </MenuItem>
                )}
                {cargoStatus && cargoStatus.integrationId && (
                  <MenuItem>
                    <Button
                      disabled={disableShipping}
                      variant={'outline'}
                      onClick={handleCancelCargo}
                    >
                      <Icon as={FaTruck} mr={2} /> Aras Kargoyu İptal Et
                    </Button>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
          <Box id="printArea">
            <SimpleGrid
              columns={{
                base: 1
              }}
              gap={4}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Üye Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> İsim Soyisim</Th>
                        <Td>
                          {data.customer?.first_name} {data.customer?.last_name}
                        </Td>
                        -<Td>{data.customer?.email}</Td>-
                        <Td>{data.customer?.phone}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> İsim Soyisim</Th>
                        <Td>
                          {data.shipping_address.first_name}{' '}
                          {data.shipping_address.last_name}
                        </Td>
                        -
                        <Td>
                          {data.shipping_address.address1}{' '}
                          {data.shipping_address.address2}{' '}
                          {data.shipping_address.city}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <SimpleGrid
              columns={{
                base: 1
              }}
              gap={4}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Sipariş Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width="150px">Tarih</Th>
                        <Td>
                          {dayjs(data.created_at).format(
                            'DD MMMM YYYY HH:mm:ss'
                          )}
                        </Td>
                        -<Td>{data.financial_status}</Td>-
                        <Td>{data.order_number}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={3} gap={4}>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Üretimi Yapan
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}></Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Denetleyen
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> </Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Yapan
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> </Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Ürünler
                </Box>
                <Table variant={'striped'}>
                  <Thead
                    sx={{
                      '@media print': {
                        display: 'table-header-group',
                        visibility: 'visible'
                      }
                    }}
                    display={{
                      base: 'none',
                      md: 'table-header-group'
                    }}
                  >
                    <Tr>
                      <Th className="w-40 px-5 py-2 text-center bg-default-600">
                        Fotoğraf
                      </Th>
                      <Th className="px-5 py-2 whitespace-nowrap bg-default-600">
                        SKU
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Ürün</Th>
                      <Th className="px-5 py-2 text-center w-42 bg-default-600">
                        Beden
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Seçenekler</Th>
                      <Th>Hediye Kartı</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.line_items.map((p: any, i: number) => {
                      let title = 'Ürün İsimi Yok';

                      return (
                        <Tr key={i}>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'none',
                                visibility: 'hidden'
                              }
                            }}
                            display={{
                              base: 'table-cell',
                              md: 'none'
                            }}
                          >
                            <Box bgColor={'gray.100'} pb={4} textAlign="center">
                              <Box mb={2}>
                                {/* <img
                                  src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=500&height=500&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}
                                  className="mx-auto"
                                  alt="product"
                                /> */}
                              </Box>
                              <Box mb={2} fontWeight="semibold">
                                {p.name.split('-')[0]}
                              </Box>
                              <Box mb={2}>
                                {p.properties.map((m: any) => {
                                  return (
                                    <Box key={m.name}>
                                      <Box fontSize={'xs'} color={'gray.500'}>
                                        {m.name}
                                      </Box>{' '}
                                      <Box>{m.value}</Box>
                                    </Box>
                                  );
                                })}
                              </Box>
                              <Box my={4}>{p.name.split('-')[1]}</Box>
                              <Box>
                                <RenderMaterials
                                  locale="tr"
                                  sku={
                                    p.properties.find(
                                      (l: any) => l.name === 'Sku'
                                    ).value
                                  }
                                />
                              </Box>
                            </Box>
                          </Td>

                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {/* <img
                              src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=50&quality=60`}
                              className="mx-auto"
                              alt="product"
                            /> */}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {p.name.split('-')[0]}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <div>{p.title}</div>

                            {p.properties.find(
                              (l: any) => l.name === 'Message'
                            ) && (
                              <div className="font-semibold">
                                <hr className="my-2" />
                                {
                                  p.properties.find(
                                    (l: any) => l.name === 'Message'
                                  ).value
                                }
                              </div>
                            )}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {p.sku.split('-')[2]}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <RenderMaterials
                              locale="tr"
                              sku={
                                p.properties.find((l: any) => l.name === 'Sku')
                                  .value
                              }
                            />
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {/* {p.giftCard && (
                              <Box>
                                <Image
                                  width={250}
                                  height={150}
                                  src={`${process.env.REACT_APP_CDNURL}/card/${p.giftCard}?width=400&height=300`}
                                />
                                <Text fontWeight={'bold'}>{p.giftNote}</Text>
                              </Box>
                            )} */}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Box>

            <SimpleGrid
              columns={{
                base: 1,
                md: 2
              }}
              gap={4}
              sx={{
                '@media print': {
                  display: 'none',
                  visibility: 'hidden'
                }
              }}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th>İsim Soyisim</Th>
                        <Td>
                          {data.shipping_address.first_name}{' '}
                          {data.shipping_address.last_name}
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Adres</Th>
                        <Td>
                          {data.shipping_address.address1}{' '}
                          {data.shipping_address.address2}
                          {data.shipping_address.city}
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Şehir</Th>
                        <Td>{data.shipping_address.city}</Td>
                      </Tr>
                      <Tr>
                        <Th>Ülke</Th>
                        <Td>{data.shipping_address.country}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>

              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Bilgileri
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th>Kargo Firması</Th>
                        {/* <Td>{data.order.shippingMethod}</Td> */}
                      </Tr>

                      <Tr>
                        <Th>Kargo Durumu</Th>
                        {/* <Td>
                          {cargoStatus
                            ? cargoStatus
                            : data.order.shippingStatus}
                        </Td> */}
                      </Tr>

                      <Tr>
                        <Th>Kargo Ücreti</Th>
                        {/* <Td>
                          {data.order.shippingPrice &&
                          data.order.shippingPrice.price
                            ? formatMoney(
                                data.order.shippingPrice.price,
                                data.order.shippingPrice.currency.code
                              )
                            : '-'}
                        </Td> */}
                      </Tr>

                      <Tr>
                        <Th>Kargo Numarası</Th>
                        <Td>
                          <div className="flex items-center justify-between space-x-2">
                            <div>
                              {/* {data.order.shippingNumber
                                ? data.order.shippingNumber
                                : '???'} */}
                            </div>
                            teslim
                          </div>
                        </Td>
                      </Tr>

                      <Tr>
                        <Th>Kargo Anahtarı</Th>
                        <Td>
                          <div className="flex items-center justify-between space-x-2">
                            {/* <div>{data.order.cargoKey}</div> */}
                          </div>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
          </Box>
        </Box>
      );
    }
    return <Box>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Box>;
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Sipariş Detayı" />
        <Box>{renderOrder()}</Box>
      </Container>
    </Layout>
  );
};
