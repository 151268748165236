/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import Select from 'react-select';

interface MaterialSelectProps {
  material: string[];
  setMaterial: React.Dispatch<React.SetStateAction<string[] | any[]>>;
  data: any;
  i: number;
}

export const MaterialSelect = ({
  material,
  setMaterial,
  data,
  i
}: MaterialSelectProps) => {
  return (
    <Select
      isClearable={false}
      options={data.availableColors.map((c: any) => {
        return {
          value: data.sku + ':' + c.sku,
          label: c.description[0].title
        };
      })}
      onChange={(e: any, a) => {
        // const material = e.value.split(':');
        // const materialSku = material[0];
        // const colorSku = material[1];
        // const oldArray = material;
        // oldArray[i] = { [materialSku]: colorSku };
        // return setMaterial((m: any) => [...oldArray]);
        if (e) {
          const filtered = material.filter((p: any) => p.sku !== data.sku);

          setMaterial([
            ...filtered,
            {
              name: e.label,
              sku: e.value
            }
          ]);
          const materials = e.value.split(':');
          const materialSku = materials[0];
          const colorSku = materials[1];
          const oldArray: any[] = material;
          oldArray[i] = {
            [materialSku]: colorSku
          };
          return setMaterial(() => [...oldArray]);
        }
        if (a.action === 'clear') {
          const filtered = material.filter((p: any) => p.sku !== data.sku);
          setMaterial(() => [...filtered]);
        }
      }}
    />
  );
};
