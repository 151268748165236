import React, { useState, useEffect } from 'react';

import { Layout } from '../Layout';
import Heading from '../ui/Heading';
import {
  Box,
  Container,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Button
} from '@chakra-ui/react';
import formatMoney from '../../utils/formatMoney';
import axios from 'axios';

function isAtBottom({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean {
  return (
    currentTarget.scrollTop + 10 >=
    currentTarget.scrollHeight - currentTarget.clientHeight
  );
}
const Prices = ({
  data,
  size,
  priceType
}: {
  size?: string;
  priceType: string;
  data: any;
}) => {
  const xSmall = data.prices.find((xs: any) => xs.sizeSku === 'XS');
  const small = data.prices.find((s: any) => s.sizeSku === 'S');
  const smallP = data.prices.find((sp: any) => sp.sizeSku === 'SP');
  const medium = data.prices.find((md: any) => md.sizeSku === 'M');
  const mediumP = data.prices.find((md: any) => md.sizeSku === 'MP');
  const large = data.prices.find((lg: any) => lg.sizeSku === 'L');
  const xLarge = data.prices.find((xl: any) => xl.sizeSku === 'XL');
  const xxLarge = data.prices.find((xxl: any) => xxl.sizeSku === 'XXL');
  const xxxLarge = data.prices.find((xxxl: any) => xxxl.sizeSku === 'XXXL');
  const one = data.prices.find((o: any) => o.sizeSku === 'ONE');
  const ringsize = data.prices.find((r: any) => r.sizeSku === 'RS');
  const custom = data.prices.find((c: any) => c.sizeSku === 'C');

  const xs =
    xSmall && xSmall.prices.find((x: any) => x.priceType === priceType);
  const s = small && small.prices.find((x: any) => x.priceType === priceType);
  const sp =
    smallP && smallP.prices.find((x: any) => x.priceType === priceType);
  const md =
    medium && medium.prices.find((x: any) => x.priceType === priceType);
  const mp =
    mediumP && mediumP.prices.find((x: any) => x.priceType === priceType);
  const lg = large && large.prices.find((x: any) => x.priceType === priceType);
  const xl =
    xLarge && xLarge.prices.find((x: any) => x.priceType === priceType);
  const xxl =
    xxLarge && xxLarge.prices.find((x: any) => x.priceType === priceType);
  const xxxl =
    xxxLarge && xxxLarge.prices.find((x: any) => x.priceType === priceType);
  const onep = one && one.prices.find((x: any) => x.priceType === priceType);
  const ringsizep =
    ringsize && ringsize.prices.find((x: any) => x.priceType === priceType);
  const customp =
    custom && custom.prices.find((x: any) => x.priceType === priceType);

  return (
    <ul style={{ listStyle: 'none' }}>
      {xs && (
        <li>
          <strong>XS:</strong>{' '}
          {formatMoney(xs.price, xs.code === '$' ? 'USD' : xs.code)}
        </li>
      )}
      {s && (
        <li>
          <strong>S:</strong>{' '}
          {formatMoney(s.price, s.code === '$' ? 'USD' : s.code)}
        </li>
      )}
      {sp && (
        <li>
          <strong>SP:</strong>{' '}
          {formatMoney(sp.price, sp.code === '$' ? 'USD' : sp.code)}
        </li>
      )}
      {md && (
        <li>
          <strong>M:</strong>{' '}
          {formatMoney(md.price, md.code === '$' ? 'USD' : md.code)}
        </li>
      )}
      {mp && (
        <li>
          <strong>MP:</strong>{' '}
          {formatMoney(mp.price, mp.code === '$' ? 'USD' : mp.code)}
        </li>
      )}
      {lg && (
        <li>
          <strong>L:</strong>{' '}
          {formatMoney(lg.price, lg.code === '$' ? 'USD' : lg.code)}
        </li>
      )}
      {xl && (
        <li>
          <strong>XL:</strong>{' '}
          {formatMoney(xl.price, xl.code === '$' ? 'USD' : xl.code)}
        </li>
      )}

      {xxl && (
        <li>
          <strong>XXL:</strong>{' '}
          {formatMoney(xxl.price, xxl.code === '$' ? 'USD' : xxl.code)}
        </li>
      )}
      {xxxl && (
        <li>
          <strong>XXXL:</strong>{' '}
          {formatMoney(xxxl.price, xxxl.code === '$' ? 'USD' : xxxl.code)}
        </li>
      )}
      {onep && (
        <li>
          <strong>ONE:</strong>{' '}
          {formatMoney(onep.price, onep.code === '$' ? 'USD' : onep.code)}
        </li>
      )}
      {ringsizep && (
        <li>
          <strong>RS:</strong>{' '}
          {formatMoney(
            ringsizep.price,
            ringsizep.code === '$' ? 'USD' : ringsizep.code
          )}
        </li>
      )}
      {customp && (
        <li>
          <strong>C:</strong>{' '}
          {formatMoney(
            customp.price,
            customp.code === '$' ? 'USD' : customp.code
          )}
        </li>
      )}
    </ul>
  );
};

const ProductGroupList = () => {
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [web, setWeb] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_APIURL}allProductGroup?first=50&skip=${data.length}&web=${web}`
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [web]);

  async function handleScroll(event: React.UIEvent<HTMLDivElement>) {
    if (isLoading || !isAtBottom(event)) return;

    setIsLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_APIURL}allProductGroup?first=50&skip=${data.length}&web=${web}`
    );

    setData([...data, ...res.data]);
    setIsLoading(false);
  }

  return (
    <Layout>
      <Container maxW={'container.xl'}>
        <Heading title="Ürünler" />
        <Button
          bg={web ? 'green.400' : 'red.400'}
          textColor="white"
          _hover={{}}
          onClick={() => setWeb(!web)}
        >
          {web ? 'Sadece Web' : 'Tümünü Göster'}
        </Button>
        <Box onScroll={handleScroll} overflow={'scroll'} maxH={'80vh'}>
          <Table>
            <Thead position={'sticky'} top={0} bg="#862337">
              <Tr height={'50px'}>
                <Th textColor={'white'} textAlign="center">
                  Görsel
                </Th>
                <Th textColor={'white'}>SKU</Th>
                <Th textColor={'white'}>Ürün</Th>
                <Th textColor={'white'}>Kategori</Th>
                <Th textColor={'white'}>TL</Th>
                <Th textColor={'white'}>EUR</Th>
                <Th textColor={'white'}>EUR Toptan</Th>
                <Th textColor={'white'}>USD</Th>
                <Th textColor={'white'}>USD Toptan</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((d: any) => {
                const sku = d.sku.split('-')[1];
                return (
                  <Tr key={d.id}>
                    <Td>
                      <img
                        src={`https://images.jujumood.com/${d.photos[0]}?format=webp&height=150&quality=50`}
                        width={150}
                        height={150}
                        alt=""
                      />
                    </Td>
                    <Td>{d.sku}</Td>
                    <Td>
                      {d.showOnWeb ? (
                        <a href={`https://jujumood.com/en/product/${sku}`}>
                          {d.title}
                        </a>
                      ) : (
                        <p>{d.title}</p>
                      )}
                    </Td>
                    <Td textAlign={'left'}>{d.category}</Td>
                    <Td>
                      <Prices data={d} priceType="RETAIL" />
                    </Td>
                    <Td>
                      <Prices data={d} priceType="FOREIGN" />
                    </Td>
                    <Td>
                      <Prices data={d} priceType="WHOLESALE" />
                    </Td>
                    <Td>
                      <Prices data={d} priceType="USDRETAIL" />
                    </Td>
                    <Td>
                      <Prices data={d} priceType="WHOLESALEUS" />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        {isLoading && <div>Loading more rows...</div>}
      </Container>
    </Layout>
  );
};

export default ProductGroupList;
