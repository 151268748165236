import React, { useState, useEffect } from 'react';
import {
  OrderTypeType,
  useDelayedOrdersQuery,
  useOrdersQuery
} from '../../generated/graphql';
import { Layout } from '../Layout';
import { Link } from 'react-router-dom';
import { BsBullseye } from 'react-icons/bs';
import dayjs from 'dayjs';
import formatMoney from '../../utils/formatMoney';
import 'dayjs/locale/tr';
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag,
  Icon,
  Input,
  Text,
  Button,
  useToast
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import Pagination from '../ui/Pagination';
import { FaCheck, FaCheckCircle } from 'react-icons/fa';
import { meiliClient } from '../../utils/meili';
import axios from 'axios';
const index = meiliClient.index('orders');

dayjs.locale('tr');

export const MarketplaceOrders = () => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    async function fetchData() {
      const response: any = await axios(
        `${process.env.REACT_APP_APIURL}newOrders`
      );
      if (response.data) {
        setData(response.data.content);
      }
    }
    fetchData();
  }, []);

  const renderOrders = () => {
    if (data) {
      return data.map((order: any, i: number) => {
        let quantity = 0;
        order.lines.forEach((line: any) => {
          quantity += line.quantity;
        });

        return (
          <Tr key={order.id}>
            <Td>
              <Box
                textAlign={'center'}
                color="gray.200"
                fontWeight={'semibold'}
                bgColor={'green.500'}
                p={2}
              >
                {i + 1}
              </Box>
            </Td>

            <Td>
              {dayjs(order.orderDate).format('DD MMMM YYYY')} <br />
            </Td>
            <Td>
              {order.customerFirstName} {order.customerLastName}
            </Td>
            <Td>{quantity} adet</Td>
            <Td width={'200px'} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(order.totalPrice, order.currencyCode)}
              </Box>
            </Td>

            <Td textAlign="right">
              {formatMoney(
                order.totalDiscount ? order.totalDiscount : 0,
                order.currencyCode
              )}
            </Td>
            <Td>{order.status}</Td>
            <Td width="140px">
              <Flex flexDir={'column'} experimental_spaceY={1} width="140px">
                <Box>
                  <Link to={`/market-order/${order.orderNumber}`}>
                    <Tag
                      textAlign={'center'}
                      justifyContent="center"
                      variant={'solid'}
                      py={2}
                      colorScheme={'blue'}
                      width="100%"
                    >
                      Detay
                    </Tag>
                  </Link>
                </Box>
              </Flex>
            </Td>
          </Tr>
        );
      });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Siparişler" />

        <Box>
          <Table variant={'striped'} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>

                <Th>Tarih</Th>
                <Th>İsim Soyisim</Th>
                <Th>Ürünler</Th>
                <Th textAlign={'right'}>Toplam</Th>
                <Th textAlign={'right'}>İndirim</Th>
                <Th>Durum</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            {/* {JSON.stringify(data)} */}
            <Tbody>{renderOrders()}</Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};
