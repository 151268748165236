import { Layout } from '../Layout';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { SiMicrosoftexcel } from 'react-icons/si';
import RenderMaterials from '../../utils/renderMaterials';
import * as XLSX from 'xlsx';

import 'dayjs/locale/tr';

import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Flex,
  Button,
  SimpleGrid,
  GridItem
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import axios from 'axios';
import { useState, useEffect } from 'react';
export const InventoryDetail = () => {
  const params = useParams();

  const [data, setData] = useState<any>();

  async function getInvertory() {
    const stock = await axios.get(
      `${process.env.REACT_APP_STOCKURL}/stock/getStoreStockDetail/${params?.id}`
    );
    if (stock && stock.data) {
      setData(stock.data);
    }
  }

  useEffect(() => {
    getInvertory();
  }, []);

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Sevk Bilgisi" />
        <Box my={4} bgColor="gray.100" p={4}>
          <Flex justifyContent={'flex-end'} experimental_spaceX={4}>
            <Button
              colorScheme={'purple'}
              variant="outline"
              onClick={(e) => {
                var table_elt = document.getElementById('printArea');
                var workbook = XLSX.utils.table_to_book(table_elt);
                var ws = workbook.Sheets['Sheet1'];
                XLSX.utils.sheet_add_aoa(
                  ws,
                  [['Created ' + new Date().toISOString()]],
                  { origin: -1 }
                );
                XLSX.writeFile(workbook, 'Report.xlsx');
              }}
            >
              <SiMicrosoftexcel />
            </Button>
          </Flex>
        </Box>
        <div id="printArea">
          <SimpleGrid my={4} columns={3} gap={4}>
            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="md"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Mağaza Adeti
                </Box>
                <Box fontSize="2xl" fontWeight={'bold'}>
                  {data?.oldTotal}
                </Box>
              </Box>
            </Box>
            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="md"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Sayılan Adet
                </Box>
                <Box fontSize="2xl" fontWeight={'bold'}>
                  {data?.newTotal}
                </Box>
              </Box>
            </Box>
            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="md"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Fark
                </Box>
                <Box fontSize="2xl" fontWeight={'bold'}>
                  {data?.oldTotal - data?.newTotal}
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </div>
        <Box>
          <Table variant={'simple'}>
            <Thead>
              <Tr>
                <Th>Görsel </Th>
                <Th>SKU</Th>
                <Th>Barkod</Th>
                <Th>Sayılan Adeti</Th>
                <Th>Mevcut Adeti</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data?.products.map((product: any) => {
                  return (
                    <Tr
                      borderBottom={'1px'}
                      borderStyle={'solid'}
                      borderColor={'gray.500'}
                      key={product.id}
                    >
                      <Td>
                        <img
                          src={`${process.env.REACT_APP_CDNURL}/${product.photos}`}
                          alt={product.sku}
                          style={{ width: '100px' }}
                        />
                      </Td>
                      <Td>{product.sku}</Td>
                      <Td>{product.barcode}</Td>
                      <Td>{product.newAmount}</Td>
                      <Td>
                        {product.oldAmount || product.oldAmount === 0
                          ? product.oldAmount
                          : 'Mağazada Yokmuş'}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};
