import {
  Box,
  Button,
  Container,
  Flex,
  GridItem,
  Image,
  SimpleGrid,
  Td,
  Text,
  Tr
} from '@chakra-ui/react';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { useEffect, useState } from 'react';
import { FcPrint } from 'react-icons/fc';
import { useParams } from 'react-router-dom';
import { meiliClient } from '../../utils/meili';
import phoneFormater from '../../utils/phoneFormater';
import { Layout } from '../Layout';
import PageHeading from '../ui/Heading';
const index = meiliClient.index('orders');

dayjs.locale('tr');

export const MarketplaceOrder = () => {
  const [data, setData] = useState<any>([]);
  const params = useParams();

  const [images, setImages] = useState<any>([]);
  useEffect(() => {
    async function fetchData() {
      const response: any = await axios(
        `${process.env.REACT_APP_APIURL}newOrders?orderNumber=${params.id}`
      );
      if (response.data) {
        setData(response.data.content);
      }
    }
    fetchData();
  }, [params.id]);

  useEffect(() => {
    if (data && data[0] && data[0]?.lines) {
      console.log('🌵💜🐢', data[0]);

      data[0].lines.forEach(async (line: any) => {
        const res = await axios(
          `${process.env.REACT_APP_APIURL}marketProduct/${line.barcode}`
        );
        console.log('🌵💜🐢', res.data);

        if (res.data && res.data.content[0].images[0]) {
          setImages((prev: any) => {
            return [
              ...prev,
              {
                barcode: line.barcode,
                image: res.data.content[0].images[0].url
              }
            ];
          });
        }
      });
    }
  }, [data]);

  const renderOrders = () => {
    if (data) {
      const order = data[0];
      let quantity = 0;
      order?.lines.forEach((line: any) => {
        quantity += line.quantity;
      });
      return (
        <Box>
          <Box
            my={4}
            display="flex"
            experimental_spaceX={4}
            justifyContent={'flex-end'}
          >
            <Button
              className="inline-flex items-center px-4 py-2 font-semibold text-gray-800 bg-white rounded hover:bg-gray-400"
              onClick={() => {
                window.print();
              }}
            >
              <FcPrint className="text-xl" />
            </Button>
          </Box>
          <Box id="printArea">
            <PageHeading title="Trendyol Sipariş Detayı" />

            {order && (
              <SimpleGrid
                columns={12}
                sx={{
                  '@media print': {
                    gap: 0,
                    my: 0
                  }
                }}
                gap={2}
              >
                <GridItem colSpan={6}>
                  <Box
                    sx={{
                      '@media print': {
                        gap: 0,
                        mb: 0,
                        height: 80
                      }
                    }}
                    h={96}
                    mb={4}
                    bgColor="gray.50"
                    rounded="md"
                  >
                    <Box p={4}>
                      <Box
                        mb={4}
                        fontSize="lg"
                        fontWeight={'semibold'}
                        color="blue.500"
                      >
                        Müşteri Bilgileri
                      </Box>
                      <Box>
                        <Text>
                          <b>Ad Soyad:</b> {order.shipmentAddress?.firstName}{' '}
                          {order.shipmentAddress?.lastName}
                        </Text>
                        <Text>
                          <b>Telefon:</b>{' '}
                          {phoneFormater(data.order?.user?.phoneNumber || '')}
                        </Text>
                        <Text>
                          <b>Kargo Bilgileri:</b>{' '}
                          {order.shipmentAddress?.fullName}
                        </Text>
                        <Text>
                          <b>Adres:</b> {order.shipmentAddress?.fullAddress}
                        </Text>

                        <Text>
                          <b>Toplam Ürün Sayısı:</b> {quantity}
                        </Text>
                        <Text>
                          <b>Sipariş Numarası</b> {order.orderNumber}
                        </Text>
                        <Text>
                          <b>Sipariş Tarihi:</b>{' '}
                          {dayjs(order.orderDate).format('DD/MM/YYYY HH:mm')}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </GridItem>
                {order &&
                  order.lines &&
                  order?.lines.map((p: any, i: number) => {
                    if (order?.lines.length % i === 4) {
                      return (
                        <GridItem colSpan={6}>
                          <Box
                            sx={{
                              '@media print': {
                                gap: 0,
                                mb: 0,
                                height: 80
                              }
                            }}
                            h={96}
                            mb={4}
                            bgColor="gray.50"
                            rounded="md"
                          >
                            <Box p={4}>
                              <Box
                                mb={4}
                                fontSize="lg"
                                fontWeight={'semibold'}
                                color="blue.500"
                              >
                                Müşteri Bilgileri
                              </Box>
                              <Box>
                                <Text>
                                  <b>Ad Soyad:</b>{' '}
                                  {order.shipmentAddress?.firstName}{' '}
                                  {order.shipmentAddress?.lastName}
                                </Text>
                                <Text>
                                  <b>Telefon:</b>{' '}
                                  {phoneFormater(
                                    data.order?.user?.phoneNumber || ''
                                  )}
                                </Text>
                                <Text>
                                  <b>Kargo Bilgileri:</b>{' '}
                                  {order.shipmentAddress?.fullName}
                                </Text>
                                <Text>
                                  <b>Adres:</b>{' '}
                                  {order.shipmentAddress?.fullAddress}
                                </Text>

                                <Text>
                                  <b>Toplam Ürün Sayısı:</b> {quantity}
                                </Text>
                                <Text>
                                  <b>Sipariş Numarası</b> {order.orderNumber}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </GridItem>
                      );
                    }
                    return (
                      <GridItem
                        sx={{
                          '@media print': {
                            columnSpan: 3,
                            pageBreakInside: 'avoid'
                          }
                        }}
                        colSpan={6}
                      >
                        <Box
                          sx={{
                            '@media print': {
                              gap: 0,
                              mb: 0,
                              height: 80
                            }
                          }}
                          h={96}
                          mb={4}
                          bgColor="gray.50"
                          rounded="md"
                        >
                          <Box p={4}>
                            <Box
                              mb={4}
                              fontSize="lg"
                              fontWeight={'semibold'}
                              color="blue.500"
                            >
                              Ürün Bilgileri
                            </Box>
                            <Box>
                              <Flex gap={4}>
                                <Image
                                  w={'65%'}
                                  objectFit={'cover'}
                                  h={80}
                                  sx={{
                                    '@media print': {
                                      height: 40,
                                      width: 40
                                    }
                                  }}
                                  src={`${
                                    images.find(
                                      (a: any) => a.barcode === p.barcode
                                    )?.image
                                  }`}
                                />
                                <Box
                                  sx={{
                                    '@media print': {
                                      width: 'auto'
                                    }
                                  }}
                                  w={'35%'}
                                >
                                  <Text>
                                    <b>Ürün:</b> {p.productName}
                                  </Text>
                                  <Text>
                                    <b>SKU:</b> {p.merchantSku}
                                  </Text>
                                  <Text>
                                    <b>Beden:</b>{' '}
                                    {p.productSize && `${p.productSize}`}
                                  </Text>

                                  <Text>
                                    <b>Renk:</b> {p.productColor}
                                  </Text>
                                  <Text>
                                    <b>Adet:</b> {p.quantity}
                                  </Text>
                                </Box>
                              </Flex>
                            </Box>
                          </Box>
                        </Box>
                      </GridItem>
                    );
                  })}
              </SimpleGrid>
            )}
          </Box>
        </Box>
      );
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <Box>{data && renderOrders()}</Box>
      </Container>
    </Layout>
  );
};
