import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { useEffect, useState } from 'react';
import { FcPrint } from 'react-icons/fc';
import { useParams } from 'react-router-dom';
import {
  useOrderDetailQuery,
  useReceivedManuelShippingMutation,
  useReceivedStoreShippingMutation,
  useToggleOrderUpdateMutation,
  useUpdateShippingMutation
} from '../../generated/graphql';
import phoneFormater from '../../utils/phoneFormater';
import RenderMaterials from '../../utils/renderMaterials';
import { Layout } from './../Layout';

import {
  Box,
  Button,
  Container,
  Flex,
  GridItem,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react';
import { getSizeFromSku } from '../../utils/getSizeFromSku';
import PageHeading from '../ui/Heading';

dayjs.locale('tr');

export const OrderPrintPage = () => {
  const params = useParams();
  const [toggleOrderUpdate] = useToggleOrderUpdateMutation();
  const [updateShipping] = useUpdateShippingMutation();
  const [receivedManuelShipping] = useReceivedManuelShippingMutation();
  const [receivedStoreShipping] = useReceivedStoreShippingMutation();
  const [disableShipping, setDisableShipping] = useState(false);
  const [cargoStatus, setCargoStatus] = useState('');
  const toast = useToast();

  let totalPrice = 0;
  let totalPaidPrice = 0;
  let iyzico = 0;
  let iyzicoCurrency = 'TRY';
  let currency = 'TRY';

  const { data, loading, error, refetch } = useOrderDetailQuery({
    variables: {
      id: params.id as string
    }
  });

  useEffect(() => {
    const getCargoStatus = async () => {
      if (data?.order.cargoKey) {
        const res = await axios.get(
          `${process.env.REACT_APP_CARGOAPI}cargo/tracking/${
            data?.order!.cargoKey
          }`
        );
        if (res.data) {
          setCargoStatus(res.data);
        }
      }
    };
    getCargoStatus();
  }, [cargoStatus, data]);

  const handleOrderUpdate = async (status: boolean) => {
    try {
      const result = await toggleOrderUpdate({
        variables: {
          id: params.id as string,
          isRead: status
        }
      });
      if (result) {
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderOrder = () => {
    if (loading) {
      return (
        <Box>
          <Spinner mx={'auto'} />
        </Box>
      );
    }
    if (error) {
      return <Box>Bir hata oluştu. {error.message}</Box>;
    }
    if (data) {
      data.order.payment.map((p: any) => {
        totalPrice = totalPrice + p.price;
        currency = p.currency ? p.currency : 'TRY';
        totalPaidPrice = totalPaidPrice + p.paidPrice;
      });
      return (
        <Box>
          <Box
            my={4}
            display="flex"
            experimental_spaceX={4}
            justifyContent={'flex-end'}
          >
            <Button
              className="inline-flex items-center px-4 py-2 font-semibold text-gray-800 bg-white rounded hover:bg-gray-400"
              onClick={() => {
                handleOrderUpdate(true);
                window.print();
              }}
            >
              <FcPrint className="text-xl" />
            </Button>
          </Box>
          <Box id="printArea">
            <SimpleGrid
              columns={12}
              sx={{
                '@media print': {
                  gap: 0,
                  my: 0
                }
              }}
              gap={2}
            >
              <GridItem colSpan={6}>
                <Box
                  sx={{
                    '@media print': {
                      gap: 0,
                      mb: 0,
                      height: 80
                    }
                  }}
                  h={96}
                  mb={4}
                  bgColor="gray.50"
                  rounded="md"
                >
                  <Box p={4}>
                    <Box
                      mb={4}
                      fontSize="lg"
                      fontWeight={'semibold'}
                      color="blue.500"
                    >
                      Müşteri Bilgileri
                    </Box>
                    <Box>
                      <Text>
                        <b>Ad Soyad:</b> {data.order?.user?.name}{' '}
                        {data.order?.user?.surname}
                      </Text>
                      <Text>
                        <b>Telefon:</b>{' '}
                        {phoneFormater(data.order?.user?.phoneNumber || '')}
                      </Text>
                      <Text>
                        <b>Sipariş Numarası:</b> {data.order?.conversationId}
                      </Text>
                      <Text>
                        <b>Kargo Bilgileri:</b> {data.order?.shippingFirstName}
                        {data.order?.shippingLastName}{' '}
                      </Text>
                      <Text>
                        <b>Adres:</b> {data.order?.shippingAddress}
                      </Text>
                      <Text>
                        <b>İl:</b> {data.order?.shippingCity}
                      </Text>
                      <Text>
                        <b>Ülke:</b> {data.order?.shippingCountry}
                      </Text>
                      <Text>
                        <b>Toplam Ürün Sayısı:</b> {data.order?.products.length}
                      </Text>
                      <Text>
                        <b>Sipariş Tarihi:</b>{' '}
                        {dayjs(data.order?.createdAt).format(
                          'DD.MM.YYYY HH:mm'
                        )}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </GridItem>
              {data.order?.products.map((p, i: number) => {
                if (data.order.products.length % i === 4) {
                  return (
                    <GridItem colSpan={6}>
                      <Box
                        sx={{
                          '@media print': {
                            gap: 0,
                            mb: 0,
                            height: 80
                          }
                        }}
                        h={96}
                        mb={4}
                        bgColor="gray.50"
                        rounded="md"
                      >
                        <Box p={4}>
                          <Box
                            mb={4}
                            fontSize="lg"
                            fontWeight={'semibold'}
                            color="blue.500"
                          >
                            Müşteri Bilgileri
                          </Box>
                          <Box>
                            <Text>
                              <b>Ad Soyad:</b> {data.order?.user?.name}{' '}
                              {data.order?.user?.surname}
                            </Text>
                            <Text>
                              <b>Telefon:</b>{' '}
                              {phoneFormater(
                                data.order?.user?.phoneNumber || ''
                              )}
                            </Text>
                            <Text>
                              <b>Kargo Bilgileri:</b>{' '}
                              {data.order?.shippingFirstName}
                              {data.order?.shippingLastName}{' '}
                            </Text>
                            <Text>
                              <b>Adres:</b> {data.order?.shippingAddress}
                            </Text>
                            <Text>
                              <b>İl:</b> {data.order?.shippingCity}
                            </Text>
                            <Text>
                              <b>Ülke:</b> {data.order?.shippingCountry}
                            </Text>
                            <Text>
                              <b>Toplam Ürün Sayısı:</b>{' '}
                              {data.order?.products.length}
                            </Text>
                            <Text>
                              <b>Sipariş Tarihi:</b>{' '}
                              {dayjs(data.order?.createdAt).format(
                                'DD.MM.YYYY HH:mm'
                              )}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </GridItem>
                  );
                }
                return (
                  <GridItem
                    sx={{
                      '@media print': {
                        columnSpan: 3,
                        pageBreakInside: 'avoid'
                      }
                    }}
                    colSpan={6}
                  >
                    <Box
                      sx={{
                        '@media print': {
                          gap: 0,
                          mb: 0,
                          height: 80
                        }
                      }}
                      h={96}
                      mb={4}
                      bgColor="gray.50"
                      rounded="md"
                    >
                      <Box p={4}>
                        <Box
                          mb={4}
                          fontSize="lg"
                          fontWeight={'semibold'}
                          color="blue.500"
                        >
                          Ürünler
                        </Box>
                        <Box>
                          <Flex gap={4}>
                            <Image
                              h={80}
                              sx={{
                                '@media print': {
                                  height: 40
                                }
                              }}
                              src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?format=webp&quality=80&width=300&height=300&fit=crop`}
                            />
                            <Box>
                              <Text>
                                <b>Ürün:</b>{' '}
                                {p.product.productGroup.description[0].title}
                              </Text>
                              <Text>
                                <b>SKU:</b> {p.product.sku?.split('-')[0]}-
                                {p.product.sku?.split('-')[1]}
                              </Text>
                              <Text>
                                <b>Beden:</b>{' '}
                                {getSizeFromSku(p.product.sku || '')}
                                {p.ringSize && ` - ${p.ringSize}`}
                              </Text>

                              <Text>
                                <b>Renk:</b>{' '}
                                <RenderMaterials
                                  sku={p.product.sku || ''}
                                  locale="tr"
                                />
                              </Text>
                              {p.additionalInfo && (
                                <Text>
                                  <div className="font-semibold">
                                    <hr className="my-2" />
                                    {p.additionalInfo}
                                  </div>
                                </Text>
                              )}
                              {p.giftCard && (
                                <Box>
                                  <Image
                                    width={150}
                                    height={100}
                                    objectFit={'contain'}
                                    src={`${process.env.REACT_APP_CDNURL}/card/${p.giftCard}?width=400&height=300`}
                                  />
                                  <Text fontSize={'sm'}>{p.giftNote}</Text>
                                </Box>
                              )}
                            </Box>
                          </Flex>
                        </Box>
                      </Box>
                    </Box>
                  </GridItem>
                );
              })}
            </SimpleGrid>
          </Box>
        </Box>
      );
    }
    return <Box>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Box>;
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Sipariş Detayı" />
        <Box>{renderOrder()}</Box>
      </Container>
    </Layout>
  );
};
