import React, { useState, useRef } from 'react';
import { Layout } from '../Layout';
import {
  Input,
  Button,
  Box,
  Flex,
  Container,
  useToast,
  Spinner,
  chakra
} from '@chakra-ui/react';
import Heading from '../ui/Heading';
import {
  useTransferProductMutation,
  IsShippedType,
  useProductInfoQuery,
  useUserQuery,
  UserType
} from '../../generated/graphql';
import Select from 'react-select';
import RenderMaterials from '../../utils/renderMaterials';

export const SendStorage = () => {
  const [barcode, setBarcode] = useState('');
  const [tempBarcode, setTempBarcode] = useState('');
  const [userId, setUserId] = useState('');
  const inputRef = useRef<any>(null);
  const toast = useToast();
  const { data, loading, error } = useProductInfoQuery({
    variables: {
      barcode
    }
  });

  const {
    data: userData,
    loading: userLoading,
    error: userError
  } = useUserQuery({
    variables: {
      first: 1000,
      skip: 0,
      userType: UserType.Production
    }
  });

  const [transferProduct] = useTransferProductMutation();

  if (error || userError) return <div>Sayfa Yüklenirken Hata Oluştu!!! Sayfayı Yenileyin.</div>;

  async function onTransfer(id: string) {
    const res = await transferProduct({
      variables: {
        product: id,
        isShipped: IsShippedType.Storage,
        staff: userId
      }
    });
    if (res.data?.transferProduct.id) {
      toast({
        title: 'Success',
        description: 'Ürün Depoya Eklendi',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      inputRef.current.focus();
      setTempBarcode('');
      setBarcode('');
      setUserId('');
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (tempBarcode.length === 12) {
      setBarcode(tempBarcode);
    }
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <Heading title="Depoya Ürün Ekle" />
        <form onSubmit={handleSubmit}>
          <Flex
            my={4}
            mx={'auto'}
            justify="center"
            align="center"
            w={{
              base: '100%',
              md: '50%'
            }}
          >
            <Input
              ref={inputRef}
              placeholder="Barkod"
              type="number"
              value={tempBarcode}
              onChange={(e: any) => setTempBarcode(e.target.value)}
            />
            <Button mx={4} type="submit">
              ARA
            </Button>
          </Flex>
        </form>
      </Container>

      {loading || userLoading ? (
        <Container maxW={'container.xl'}>
          <Flex height={'200px'} justifyContent="center" alignItems={'center'}>
            <Spinner mx="auto" />
          </Flex>
        </Container>
      ) : (
        <Container maxW={'container.lg'}>
          <Box my={8}>
            {data?.productInfo[0] && (
              <Box
                border="1px solid"
                borderColor={'gray.100'}
                my="4"
                rounded={4}
              >
                <Flex
                  direction={{
                    base: 'column',
                    md: 'row'
                  }}
                >
                  <Box mb={6}>
                    <img
                      src={`${process.env.REACT_APP_CDNURL}/${data?.productInfo[0]?.productGroup?.photos[0]}?trim=30&extend=20&format=webp&quality=50&width=700&height=700&bg=ffffff&fit=contain`}
                      alt=""
                      width={500}
                      height={500}
                    />
                  </Box>
                  <Box
                    width={'100%'}
                    mt={{
                      base: 6,
                      md: 0
                    }}
                    bgColor="gray.50"
                    rounded="sm"
                    p={4}
                    textAlign={{
                      base: 'center',
                      md: 'left'
                    }}
                  >
                    <Box
                      fontWeight={'bold'}
                      textColor={'black'}
                      fontSize={{
                        base: '2xl',
                        md: '3xl'
                      }}
                    >
                      {data?.productInfo[0]?.productGroup?.description[0].title}
                    </Box>
                    <Box
                      fontSize={{
                        base: 'lg',
                        md: 'lg'
                      }}
                      color="gray.500"
                    >
                      {data?.productInfo[0]?.productGroup?.category.sku}-
                      {data?.productInfo[0]?.productGroup?.sku}
                    </Box>

                    <Box my={2}>
                      <Box fontSize={'lg'} fontWeight={'bold'}>
                        Beden
                      </Box>
                      <Box>{data?.productInfo[0]?.sku?.split('-')[2]}</Box>
                    </Box>
                    <Box my={2}>
                      <Box fontSize={'lg'} fontWeight={'bold'}>
                        Seçenekler
                      </Box>
                      <RenderMaterials
                        locale="tr"
                        sku={data?.productInfo[0]?.sku || ''}
                      />
                    </Box>
                    <form>
                      <Box my={4}>
                        <Box fontSize={'lg'} fontWeight={'bold'}>
                          Üretimi Yapan Kişi
                        </Box>
                        <Select
                          options={userData?.users.users.map((user) => ({
                            value: user.id,
                            label: user.name + ' ' + user.surname
                          }))}
                          onChange={(e: any) => {
                            setUserId(e.value);
                          }}
                        />
                      </Box>
                      <Box
                        textAlign={{
                          base: 'center',
                          md: 'right'
                        }}
                      >
                        <Button
                          colorScheme={'green'}
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            onTransfer(data.productInfo[0].id);
                          }}
                          type="submit"
                        >
                          <Box fontSize={'lg'} fontWeight={'bold'}>
                            Ürünü Depoya Ekle
                          </Box>
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        </Container>
      )}
    </Layout>
  );
};
