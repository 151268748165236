function phoneFormater(phone: string) {
  let phoneNumber = phone.trim().replace(/[^\d]/g, '');
  if (phoneNumber.startsWith('0')) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith('+90')) {
    return phoneNumber.slice(2);
  }
  if (phoneNumber.startsWith('90')) {
    return phoneNumber.slice(1);
  }
  if (!phoneNumber.startsWith('0') && phoneNumber.length === 10) {
    phoneNumber = '0' + phoneNumber;
  }
  return phoneNumber;
}

export default phoneFormater;
