import { useState, useEffect } from 'react';
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Stack,
  Heading,
  useToast
} from '@chakra-ui/react';
import { useLoginUserMutation } from '../../generated/graphql';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginUser] = useLoginUserMutation();
  const toast = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, [navigate]);
  async function onSubmit() {
    try {
      const userData = await loginUser({
        variables: {
          email,
          password
        }
      });
      const token = userData.data?.loginProduction.token;
      const role = userData.data?.loginProduction.user.userType;
      const id = userData.data?.loginProduction.user.id;
      localStorage.setItem('token', token || '');
      localStorage.setItem('userId', id || '');
      localStorage.setItem('role', role || '');
      toast({
        title: 'Giriş Başarılı',
        status: 'success',
        isClosable: true
      });
      navigate('/dashboard');
    } catch (error) {
      console.log('🌵💜🐢 error', error);
      toast({
        title: 'Yetkisiz Giriş',
        status: 'error'
      });
    }
  }

  return (
    <Box textAlign="center" fontSize="xl">
      <Box my={4}>
        <ColorModeSwitcher justifySelf="flex-end" />
      </Box>
      <Flex
        minH={{
          base: 'auto',
          md: '70vh'
        }}
        align={{
          md: 'flex-start',
          base: 'center'
        }}
        justify={'center'}
      >
        <Stack
          spacing={{
            base: 2,
            md: 8
          }}
          mx={'auto'}
          maxW={'lg'}
          width={'100%'}
          py={{
            base: 4,
            md: 12
          }}
          px={{
            base: 2,
            md: 6
          }}
        >
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Juju</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              Üretim
            </Text>
          </Stack>
          <Box
            w={{
              base: '100%',
              md: '25vw'
            }}
            rounded={'lg'}
            mx="auto"
            boxShadow={'lg'}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="username">
                <FormLabel>Mail</FormLabel>
                <Input
                  onChange={(e: any) => setEmail(e.target.value)}
                  type="text"
                  placeholder="Mail"
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Şifre</FormLabel>
                <Input
                  onChange={(e: any) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Şifre"
                />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  bg={'purple.500'}
                  color={'white'}
                  _hover={{
                    bg: 'purple.600'
                  }}
                  onClick={onSubmit}
                >
                  Giriş
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
};
