import React, { useState, useEffect } from 'react';
import {
  OrderTypeType,
  useDelayedOrdersQuery,
  useOrdersQuery
} from '../../generated/graphql';
import { Layout } from './../Layout';
import { Link } from 'react-router-dom';
import { BsBullseye } from 'react-icons/bs';
import dayjs from 'dayjs';
import formatMoney from '../../utils/formatMoney';
import 'dayjs/locale/tr';
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag,
  Icon,
  Input,
  Text,
  Button,
  useToast
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import Pagination from '../ui/Pagination';
import { FaCheck, FaCheckCircle } from 'react-icons/fa';
import { meiliClient } from '../../utils/meili';
import axios from 'axios';
const index = meiliClient.index('orders');

dayjs.locale('tr');

export const Orders = () => {
  const recordsPerPage = 20;
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('success');
  const [searchWord, setSearchWord] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const toast = useToast();
  const [totalPages, setTotalPages] = useState(0);
  const skip = (page - 1) * recordsPerPage;

  const { data, loading, error } = useOrdersQuery({
    variables: {
      orderType: OrderTypeType.Retail,
      first: recordsPerPage,
      status,
      store: 'ckoqhdhaw1vt60759sz8nso01',
      skip
    }
  });
  const {
    data: delayedOrders,
    loading: delayedOrdersLoading,
    error: delayedOrdersError
  } = useDelayedOrdersQuery({
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    async function searchWithMeili() {
      const search: any = await index.search(searchWord, {
        limit: 100
      });
      console.log('🌵💜🐢', search);

      setSearchResults(
        search.hits.filter((o: any) => o.orderStatus === 'SUCCESS')
      );
      setPage(1);
    }
    // Execute the created function directly
    if (searchWord) {
      searchWithMeili();
    }
  }, [searchWord]);
  useEffect(() => {
    if (!loading && data) {
      setTotalPages(Math.ceil(data.orders.orderCount / recordsPerPage));
    }
  }, [data, loading]);

  const renderOrders = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={'auto'} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu. {error.message}</Td>
        </Tr>
      );
    }
    if (data) {
      return data.orders.orders.map((order, i) => {
        let totalPrice = 0;
        let totalPaidPrice = 0;
        let currency = 'TRY';

        order.payment.map((p) => {
          return (totalPrice = totalPrice + p.price);
        });

        order.payment.map((p) => {
          totalPaidPrice = totalPaidPrice + p.paidPrice;
          return (currency = p.currency as string);
        });

        let background = 'transparent';
        if (order.payment.length === 0) {
          background = 'red.500';
        }

        if (order.payment.findIndex((f) => f.status === 'failure') > 0) {
          background = 'red.500';
        }

        if (order.isCompleted) {
          background = 'green.600';
        }

        if (order.orderStatus === 'SUCCESS') {
          if (order.shippingStatus === 'SHIPPED') {
            background = 'blue.500'; // Sipariş kağıdı basıldı üretimde
          }
          if (order.shippingStatus === 'PENDING') {
            background = 'yellow.500'; //
          }
          if (order.shippingStatus === 'WAITING') {
            // Kargo kağıdı basılmış ve hazırlanmış ama henüz kargoya ulaşılmadı
            background = 'blue.300';
          }
          if (order.shippingStatus === 'Kargo teslim edilmiştir.') {
            // Kargo teslim edildi demektir
            background = 'green.600';
          }
          if (
            order.shippingStatus !== 'PENDING' &&
            order.shippingStatus !== 'SHIPPED' &&
            order.shippingStatus !== 'COMPLETED' &&
            order.shippingStatus !== 'WAITING' &&
            order.shippingStatus !== 'Kargo teslim edilmiştir.'
          ) {
            // sipariş kargoda demek
            background = 'purple.500';
          }
        }

        return (
          <Tr key={order.id}>
            <Td>
              <Box
                textAlign={'center'}
                color="gray.200"
                fontWeight={'semibold'}
                bgColor={background}
                p={2}
              >
                {skip + i + 1}
              </Box>
            </Td>
            <Td>
              {!order.isRead ? (
                <Icon as={BsBullseye} color="red.600" fontSize={'18px'} />
              ) : (
                <Icon as={FaCheckCircle} color="green.500" fontSize={'18px'} />
              )}
            </Td>
            <Td>
              {dayjs(order.createdAt).format('DD MMMM YYYY')} <br />
              {dayjs(order.createdAt).format('HH:mm')}
            </Td>
            <Td>
              {order.paymentFirstName} {order.paymentLastName}
            </Td>
            <Td>{order.products.length} adet</Td>
            <Td width={'200px'} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(totalPrice, currency)}
              </Box>
            </Td>

            <Td width={'200px'} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(totalPaidPrice, currency)}
              </Box>
            </Td>
            <Td textAlign="right">
              {formatMoney(order.discount ? order.discount : 0, currency)}
            </Td>
            <Td>{order.orderStatus}</Td>
            <Td width="140px">
              <Flex flexDir={'column'} experimental_spaceY={1} width="140px">
                <Box>
                  <Link to={`/order-detail/${order.id}`}>
                    <Tag
                      textAlign={'center'}
                      justifyContent="center"
                      variant={'solid'}
                      py={2}
                      colorScheme={'blue'}
                      width="100%"
                    >
                      Detay
                    </Tag>
                  </Link>
                </Box>
              </Flex>
            </Td>
          </Tr>
        );
      });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };
  const renderMeili = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan={8}>
            <Spinner mx={'auto'} />
          </Td>
        </Tr>
      );
    }
    if (error) {
      return (
        <Tr>
          <Td colSpan={8}>Bir hata oluştu. {error.message}</Td>
        </Tr>
      );
    }
    if (data) {
      return searchResults.map((order: any, i) => {
        let background = 'transparent';

        if (order.orderStatus === 'SUCCESS') {
          if (order.shippingStatus === 'SHIPPED') {
            background = 'blue.500'; // Sipariş kağıdı basıldı üretimde
          }
          if (order.shippingStatus === 'PENDING') {
            background = 'yellow.500'; //
          }
          if (order.shippingStatus === 'WAITING') {
            // Kargo kağıdı basılmış ve hazırlanmış ama henüz kargoya ulaşılmadı
            background = 'blue.300';
          }
          if (order.shippingStatus === 'Kargo teslim edilmiştir.') {
            // Kargo teslim edildi demektir
            background = 'green.600';
          }
          if (
            order.shippingStatus !== 'PENDING' &&
            order.shippingStatus !== 'SHIPPED' &&
            order.shippingStatus !== 'COMPLETED' &&
            order.shippingStatus !== 'WAITING' &&
            order.shippingStatus !== 'Kargo teslim edilmiştir.'
          ) {
            // sipariş kargoda demek
            background = 'purple.500';
          }
        }

        return (
          <Tr key={order.id}>
            <Td>
              <Box
                textAlign={'center'}
                color="gray.200"
                fontWeight={'semibold'}
                bgColor={background}
                p={2}
              >
                {skip + i + 1}
              </Box>
            </Td>
            <Td>
              <Icon as={FaCheckCircle} color="green.500" fontSize={'18px'} />
            </Td>
            <Td>
              {dayjs(order.createdAt).format('DD MMMM YYYY')} <br />
              {dayjs(order.createdAt).format('HH:mm')}
            </Td>
            <Td>{order.paymentName}</Td>
            <Td>{order.productQuantity} adet</Td>
            <Td width={'200px'} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(order.price, order.currency)}
              </Box>
            </Td>

            <Td width={'200px'} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(order.paidPrice, order.currency)}
              </Box>
            </Td>
            <Td textAlign="right">
              {/* {formatMoney(order.discount ? order.discount : 0, order.currency)} */}
            </Td>
            <Td>{order.orderStatus}</Td>
            <Td width="140px">
              <Flex flexDir={'column'} experimental_spaceY={1} width="140px">
                <Box>
                  <Link to={`/order-detail/${order.id}`}>
                    <Tag
                      textAlign={'center'}
                      justifyContent="center"
                      variant={'solid'}
                      py={2}
                      colorScheme={'blue'}
                      width="100%"
                    >
                      Detay
                    </Tag>
                  </Link>
                </Box>
              </Flex>
            </Td>
          </Tr>
        );
      });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };
  const updateOrders = async () => {
    try {
      const res = await axios(
        `${process.env.REACT_APP_APIURL}shopify-create-orders`
      );
      if (res.data) {
        toast({
          title: 'Siparişler güncellendi.'
        });
      }
    } catch (error) {
      console.log('🌵💜🐢 error', error);
      toast({
        title: 'Siparişler güncellenemedi.'
      });
    }
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Siparişler" />
        {delayedOrdersLoading ? (
          <Spinner />
        ) : (
          <Flex alignItems={'center'} justifyContent={'space-between'} my={2}>
            <Text as={Link} to={`/delayed-orders`} color={'red.400'}>
              <strong>{delayedOrders?.delayedOrders.count}</strong> adet
              gecikmiş siparişiniz var.
            </Text>
            <Button onClick={updateOrders}>Siparişleri Güncelle</Button>
          </Flex>
        )}
        <Box>
          <Box>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              placeholder="İsim soyisim e-posta telefon mağaza id durum ..."
            />
          </Box>
          <Table variant={'striped'} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>
                  <BsBullseye />
                </Th>
                <Th>Tarih</Th>
                <Th>İsim Soyisim</Th>
                <Th>Ürünler</Th>
                <Th textAlign={'right'}>Toplam</Th>
                <Th textAlign={'right'}>Ödenen</Th>
                <Th textAlign={'right'}>İndirim</Th>
                <Th>Durum</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{searchWord ? renderMeili() : renderOrders()}</Tbody>
          </Table>
          <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        </Box>
      </Container>
    </Layout>
  );
};
