import { Layout } from '../Layout';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import formatMoney from '../../utils/formatMoney';
import { FcPrint, FcProcess, FcSearch, FcVoicemail } from 'react-icons/fc';
import { ImFilePdf } from 'react-icons/im';
import { SiMicrosoftexcel } from 'react-icons/si';
import RenderMaterials from '../../utils/renderMaterials';
import * as XLSX from 'xlsx';
import {
  useOrderQuery,
  useToggleOrderUpdateMutation,
  useProductInfoLazyQuery,
  useSendOrderMailLazyQuery
} from '../../generated/graphql';
import 'dayjs/locale/tr';
import React, { useState } from 'react';
import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  useToast,
  Tbody,
  Thead,
  Container,
  Spinner,
  Flex,
  Button,
  SimpleGrid,
  GridItem,
  Input,
  chakra
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import { getSizeFromSku } from '../../utils/getSizeFromSku';
import DeliveryDetailModal from '../deliveryDetailModal/DeliveryDetailModal';
import { BiMailSend } from 'react-icons/bi';

export const DeliveryDetail = () => {
  const params = useParams();
  let totalPrice = 0;
  let currency = 'TRY';
  const [isControl, setIsControl] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [toggleOrderUpdate] = useToggleOrderUpdateMutation();
  const [productInfo] = useProductInfoLazyQuery();
  const toast = useToast();
  const { data, loading, error, refetch } = useOrderQuery({
    variables: {
      id: params.id as string
    }
  });
  const [sendMail, { loading: sendLoading, data: mailData }] =
    useSendOrderMailLazyQuery();

  const handleOrderUpdate = async (status: boolean) => {
    try {
      const result = await toggleOrderUpdate({
        variables: {
          id: params.id as string,
          isRead: status
        }
      });
      if (result) {
        return refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (e: any) => {
    e.preventDefault();
    try {
      let searchProduct: any = localStorage.getItem('searchProduct');
      const result = await productInfo({
        variables: {
          barcode: barcode
        }
      });
      if (result.data?.productInfo[0].id) {
        searchProduct = JSON.parse(searchProduct || '[]');
        const randomId = Math.floor(Math.random() * 1000000000);
        localStorage.setItem(
          'searchProduct',
          JSON.stringify([
            ...searchProduct,
            {
              barcode: result.data?.productInfo[0].barcode,
              sku: result.data?.productInfo[0].sku,
              status: 0,
              id: randomId
            }
          ])
        );
      }
      setBarcode('');
    } catch (error) {
      console.log(error);
    }
  };

  const renderOrder = () => {
    if (loading) {
      return (
        <Box>
          <Spinner mx={'auto'} />
        </Box>
      );
    }
    if (error) {
      return <Box>Bir hata oluştu. {error.message}</Box>;
    }
    if (data) {
      // eslint-disable-next-line array-callback-return
      data.order.products.map((p) => {
        totalPrice = totalPrice + p.price.price;
        currency = p.price.currency.code || 'TRY';
      });
      return (
        <Box>
          <Box id="printArea">
            <Box mb={2} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={2}
                  fontSize="lg"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Sevk Yapan
                </Box>
                <Table className="w-full table-auto">
                  <Tbody>
                    <Tr>
                      <Th width={'150px'}> İsim Soyisim</Th>
                      <Td>
                        {data.order.user?.name} {data.order.user?.surname}
                      </Td>
                      -<Td>{data.order.user?.email}</Td>-
                      <Td>{data.order.user?.phoneNumber}</Td>
                    </Tr>
                    <Tr>
                      <Th>İmza</Th>
                      <Td></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>
            <hr />
            <SimpleGrid my={4} columns={3} gap={4}>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="md"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Denetleyen
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> </Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box py={4}>
                  <Box
                    mb={4}
                    fontSize="md"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kargo Yapan / Kurye
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> </Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="md"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Teslim Alan{' '}
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}></Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <Box w={'full'} mb={2} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={2}
                  fontSize="lg"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Sevk Bilgileri
                </Box>
                <Table className="w-full table-auto">
                  <Tbody>
                    <Tr>
                      <Th>Mağaza</Th>
                      <Td>{data.order.store?.title}</Td>
                    </Tr>

                    <Tr>
                      <Th>Sevk Adresi</Th>
                      <Td w={'full'}>
                        {data.order.store?.address}- <strong>Telefon</strong>
                        {data.order.store.phoneNumber}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th width="150px">Sevk Tarihi</Th>
                      <Td>
                        {dayjs(data.order.createdAt).format(
                          'DD MMMM YYYY HH:mm:ss'
                        )}
                      </Td>
                    </Tr>

                    <Tr>
                      <Th>Toplam Tutar</Th>
                      <Td>
                        {currency ? formatMoney(totalPrice, currency) : '-'} -{' '}
                        <strong>KDV Hariç</strong>
                        {currency
                          ? formatMoney(totalPrice / 1.18, currency)
                          : '-'}{' '}
                        - <strong>KDV</strong>
                        {currency
                          ? formatMoney(
                              totalPrice - totalPrice / 1.18,
                              currency
                            )
                          : '-'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Toplam Adet</Th>
                      <Td>{data.order.products.length} Ürün Bulunmaktadır </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>

            <Box mb={2} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Ürünler
                </Box>
                <Table variant={'striped'}>
                  <Thead
                    sx={{
                      '@media print': {
                        display: 'table-header-group',
                        visibility: 'visible'
                      }
                    }}
                    display={{
                      base: 'none',
                      md: 'table-header-group'
                    }}
                  >
                    <Tr>
                      <Th className="w-40 px-5 py-2 text-center bg-default-600">
                        Fotoğraf
                      </Th>
                      <Th className="px-5 py-2 whitespace-nowrap bg-default-600">
                        SKU
                      </Th>
                      <Th className="px-5 py-2 whitespace-nowrap bg-default-600">
                        Barkod
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Ürün</Th>
                      <Th className="px-5 py-2 text-center w-42 bg-default-600">
                        Beden
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Seçenekler</Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        KDV Hariç
                      </Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        KDV
                      </Th>
                      <Th className="px-5 py-2 text-right bg-default-600">
                        Fiyat
                      </Th>
                      <Th
                        sx={{
                          '@media print': {
                            display: 'none',
                            visibility: 'hidden'
                          }
                        }}
                        className="px-5 py-2 text-right bg-default-600"
                      >
                        İşlem
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.order.products.map((p, i) => {
                      const description = p.product.productGroup.description;
                      let title = 'Ürün İsimi Yok';
                      if (description) {
                        const getTitle = description.find(
                          (d) => d.language === 'tr'
                        );
                        title = getTitle?.title ? getTitle.title : '';
                      }
                      return (
                        <Tr key={i}>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'none',
                                visibility: 'hidden'
                              }
                            }}
                            display={{
                              base: 'table-cell',
                              md: 'none'
                            }}
                          >
                            <Box bgColor={'gray.100'} pb={4} textAlign="center">
                              <Box mb={2}>
                                <img
                                  src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=500&height=500&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}
                                  className="mx-auto"
                                  alt="product"
                                />
                              </Box>
                              <Box mb={2} fontWeight="semibold">
                                {title}
                              </Box>
                              <Box mb={2}>
                                <Box>{p.product.sku}</Box>
                              </Box>
                              <Box mb={2}>
                                <Box>
                                  <div className="text-xs text-gray-500">
                                    {p.product.barcode}
                                  </div>
                                </Box>
                              </Box>
                              <Box my={4}>
                                {getSizeFromSku(p.product.sku)}{' '}
                                {p.ringSize && ` - ${p.ringSize}`}
                              </Box>
                              <Box>
                                <RenderMaterials
                                  locale="tr"
                                  sku={p.product.sku!}
                                />
                              </Box>
                            </Box>
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=50&quality=60&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}
                              className="mx-auto"
                              alt="product"
                            />
                            <p
                              style={{ display: 'none' }}
                            >{`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=50&quality=60&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}</p>
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {p.product.sku}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <div className="text-xs text-gray-500">
                              {p.product.barcode}
                            </div>
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <div>{title}</div>

                            {p.additionalInfo && (
                              <div className="font-semibold">
                                <hr className="my-2" />
                                {p.additionalInfo}
                              </div>
                            )}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {getSizeFromSku(p.product.sku)}{' '}
                            {p.ringSize && ` - ${p.ringSize}`}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <RenderMaterials locale="tr" sku={p.product.sku!} />
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {formatMoney(
                              p.price.price / 1.18,
                              p.price.currency.code
                            )}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {formatMoney(
                              p.price.price - p.price.price / 1.18,
                              p.price.currency.code
                            )}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {formatMoney(p.price.price, p.price.currency.code)}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <DeliveryDetailModal
                              refetch={refetch}
                              sku={p.product.sku!}
                              createdAt={data.order.createdAt}
                              orderId={data.order.id}
                              id={p.id}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    return <Box>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Box>;
  };

  const renderSearch = () => {
    if (loading) {
      return (
        <Box>
          <Spinner mx={'auto'} />
        </Box>
      );
    }
    if (error) {
      return <Box>Bir hata oluştu. {error.message}</Box>;
    }
    if (data) {
      // eslint-disable-next-line array-callback-return

      return (
        <Box>
          <Box id="printArea">
            <hr />

            <Box mb={2} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Ürünler
                </Box>
                <Table variant={'striped'}>
                  <Thead
                    sx={{
                      '@media print': {
                        display: 'table-header-group',
                        visibility: 'visible'
                      }
                    }}
                    display={{
                      base: 'none',
                      md: 'table-header-group'
                    }}
                  >
                    <Tr>
                      <Th className="w-40 px-5 py-2 text-center bg-default-600">
                        Fotoğraf
                      </Th>
                      <Th className="px-5 py-2 whitespace-nowrap bg-default-600">
                        SKU
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Ürün</Th>
                      <Th className="px-5 py-2 text-center w-42 bg-default-600">
                        Var mı? Yok mu?
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.order.products.map((p, i) => {
                      const searchProducts = JSON.parse(
                        localStorage.getItem('searchProduct') || '[]'
                      );
                      const description = p.product.productGroup.description;
                      let title = 'Ürün İsimi Yok';
                      if (description) {
                        const getTitle = description.find(
                          (d) => d.language === 'tr'
                        );
                        title = getTitle?.title ? getTitle.title : '';
                      }

                      const searchProduct = searchProducts.find((s: any) => {
                        if (s.barcode === p.product.barcode && s.status === 1) {
                          return s;
                        }
                      });
                      if (searchProduct && searchProduct.status === 0) {
                        const updateSearchProduct = searchProducts.map(
                          (s: any) => {
                            if (s.id === searchProduct.id) {
                              s.status = 1;
                              return s;
                            }
                            return s;
                          }
                        );
                        localStorage.setItem(
                          'searchProduct',
                          JSON.stringify(updateSearchProduct)
                        );
                      }
                      return (
                        <Tr key={i}>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=50&quality=60&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}
                              className="mx-auto"
                              alt="product"
                            />
                            <p
                              style={{ display: 'none' }}
                            >{`${process.env.REACT_APP_CDNURL}/${p.product.productGroup.photos[0]}?width=50&quality=60&quality=60&trim=20&bg=ffffff&fit=contain&extend=20`}</p>
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            {p.product.sku}
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <div className="text-xs text-gray-500">
                              {p.product.barcode}
                            </div>
                          </Td>
                          <Td
                            sx={{
                              '@media print': {
                                display: 'table-cell',
                                visibility: 'visible'
                              }
                            }}
                            display={{
                              base: 'none',
                              md: 'table-cell'
                            }}
                          >
                            <div>{title}</div>

                            {p.additionalInfo && (
                              <div className="font-semibold">
                                <hr className="my-2" />
                                {p.additionalInfo}
                              </div>
                            )}
                          </Td>
                          <Td>
                            {searchProduct ? (
                              <div className="text-green-500">Var</div>
                            ) : (
                              <div className="text-red-500">Yok</div>
                            )}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    return <Box>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Box>;
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Sevk Bilgisi" />
        <Box my={4} bgColor="gray.100" p={4}>
          <Flex justifyContent={'flex-end'} experimental_spaceX={4}>
            <Button
              colorScheme={'blue'}
              variant="outline"
              onClick={() => {
                handleOrderUpdate(true);
                window.print();
              }}
            >
              <FcPrint className="text-xl" />
            </Button>
            <Button
              colorScheme={'purple'}
              variant="outline"
              onClick={() => setIsControl(!isControl)}
            >
              <FcProcess className="text-xl" />
            </Button>
            <Button
              colorScheme={'purple'}
              variant="outline"
              onClick={async () => {
                const data = await sendMail({
                  variables: {
                    id: params.id as string
                  }
                });
                if (data.data?.sendMail) {
                  toast({
                    title: 'Mail Gönderildi'
                  });
                } else {
                  toast({
                    title: 'Bir Hata Oluştu',
                    status: 'error'
                  });
                }
              }}
              isLoading={sendLoading}
              isDisabled={sendLoading}
            >
              <BiMailSend className="text-xl" />
            </Button>
            <Button
              colorScheme={'purple'}
              variant="outline"
              onClick={(e) => {
                var table_elt = document.getElementById('printArea');
                var workbook = XLSX.utils.table_to_book(table_elt);
                var ws = workbook.Sheets['Sheet1'];
                XLSX.utils.sheet_add_aoa(
                  ws,
                  [['Created ' + new Date().toISOString()]],
                  { origin: -1 }
                );
                XLSX.writeFile(workbook, 'Report.xlsx');
              }}
            >
              <SiMicrosoftexcel />
            </Button>
            <Button
              as={'a'}
              variant="outline"
              colorScheme={'green'}
              target="_blank"
              href={`https://juju-fatura-pdf.vercel.app/${data?.order.id}`}
              rel="noreferrer"
            >
              <ImFilePdf />
            </Button>
          </Flex>
        </Box>
        {isControl && (
          <Box my={4} bgColor="gray.100" p={4}>
            <chakra.form
              display={'flex'}
              onSubmit={(e) => handleSearch(e)}
              px={4}
            >
              <Input
                type="text"
                placeholder="Ara"
                onChange={(e: any) => setBarcode(e.target.value)}
                value={barcode}
              />
              <Button mx={2} type="submit">
                <FcSearch />
              </Button>
            </chakra.form>
          </Box>
        )}
        <Box>{isControl ? renderSearch() : renderOrder()}</Box>
      </Container>
    </Layout>
  );
};
