import { Container, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { useCustomBasketQuery } from '../../generated/graphql';
import RenderMaterials from '../../utils/renderMaterials';
import formatMoney from '../../utils/formatMoney';
const CustomBaskets = () => {
  const { data, loading, error } = useCustomBasketQuery();
  let i = 0;
  let total = 0;
  return (
    <Container maxW={'container.xl'}>
      <Table id="printArea">
        <Thead>
          <Tr>
            <Th>Photo</Th>
            <Th w={'15%'}>Name</Th>
            <Th textAlign={'center'}>Materials</Th>
            <Th>Price</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.baskets[0].items.map((basket) => {
            const size = basket.product.sku?.split('-')[2] as string;
            const price = basket.product.productGroup.sizePrices.find(
              (s) => s.size.sku === size
            );
            i = i + 1;
            total =
              total +
                basket.product.productGroup.price[0].price +
                price!.prices[0].price || 0;
            return (
              <Tr>
                <Td>
                  <img
                    id="basket-image"
                    src={
                      'https://images.jujumood.com/' +
                      basket.product.productGroup.photos[0]
                    }
                    width={150}
                    height={150}
                    alt=""
                  />
                </Td>
                <Td>{basket.product.productGroup.description[0].title}</Td>
                <Td mx={'auto'}>
                  <RenderMaterials locale="en" sku={basket.product.sku || ''} />
                </Td>
                <Td>
                  {formatMoney(
                    basket.product.productGroup.price[0].price +
                      price!.prices[0].price || 0,
                    'USD'
                  )}
                </Td>
              </Tr>
            );
          })}
          <Tr>
            <Td colSpan={3} textAlign="right">
              Quantity {i}
            </Td>
            <Td>Total Price {formatMoney(total, 'USD')}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Container>
  );
};

export default CustomBaskets;
