/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Box,
  Flex,
  Container,
  Text,
  Button,
  useToast,
  SimpleGrid,
  Spinner,
  GridItem,
  Select,
  Input,
  Image
} from '@chakra-ui/react';
import { CategorySelect, SubCategorySelect } from '../selects/CategorySelect';
import { ProductSelect } from '../selects/ProductSelect';
import Heading from '../ui/Heading';
import { MaterialSelect } from '../selects/MaterialSelect';
import {
  useWebAddGuestBasketMutation,
  useTransferProductMutation,
  IsShippedType,
  useProductGroupQuery,
  useMyStartedProductsLazyQuery,
  useFinishedProductMutation,
  useDeleteProductionMutation
} from '../../generated/graphql';
import dayjs from 'dayjs';
import { GiTimeTrap } from 'react-icons/gi';
import { BiTimeFive } from 'react-icons/bi';
import RenderMaterials from '../../utils/renderMaterials';

export const Production = () => {
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [product, setProduct] = useState('');
  const [size, setSize] = useState('');
  const [material, setMaterial]: any = useState([]);
  const [isRepair, setIsRepair] = useState(false);
  const [repairStatus, setRepairStatus] = useState('');
  const [customSize, setCustomSize] = useState('');
  const [customSizeUnit, setCustomSizeUnit] = useState('cm');
  const [isShowMyProducts, setIsShowMyProducts] = useState(false);
  const toast = useToast();
  const [
    showMyProducts,
    { data: myStartedProducts, refetch: myRefetch, loading: myLoading }
  ] = useMyStartedProductsLazyQuery();

  useEffect(() => {
    setSize('');
    setMaterial([]);
  }, [subCategory, category]);

  useEffect(() => {
    setSize('');
    setMaterial([]);
  }, [product]);

  useEffect(() => {
    if (isShowMyProducts) {
      showMyProducts();
    }
  }, [isShowMyProducts]);

  const { data, loading, error } = useProductGroupQuery({
    variables: {
      id: product
    }
  });

  const [generateBarcode, { loading: generateLoading }] =
    useWebAddGuestBasketMutation();
  const [transferProduct, { loading: transferLoading }] =
    useTransferProductMutation();
  const [finishedProduct, { loading: finishedLoading }] =
    useFinishedProductMutation();
  const [deleteMutation] = useDeleteProductionMutation();

  if (error) return <div>Error</div>;

  async function onSubmit() {
    const sizeData = data?.productGroup?.sizes.find((s) => s.id === size);

    let setMaterials: any[] = [];
    material.map((m: any) => {
      Object.keys(m).map(function (key) {
        setMaterials.push(`${key}:${m[key]}`);
      });
    });
    let mats = material.map((code: any) => {
      return Object.entries(code).map((k) => {
        return `${k[0]}:${k[1]}`;
      });
    });
    const wMath = mats.join('-');
    const formattedSku = `${data?.productGroup?.category.sku}-${
      data?.productGroup?.sku
    }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

    const removeNulledArray = material.filter((p: any) => p);
    if (
      removeNulledArray.length !== data?.productGroup?.materials.length ||
      !size
    ) {
      // playAlert('purr');
      return toast({
        title: 'Tüm özelliklerin seçilmesi gereklidir',
        status: 'warning'
      });
    }

    const res = await generateBarcode({
      variables: {
        sku: formattedSku
      }
    });
    const productId = res?.data?.webAddGuestBasket?.id;
    if (productId) {
      const transferRes = await transferProduct({
        variables: {
          product: productId,
          isShipped: IsShippedType.Progress,
          isRepair: isRepair,
          repairStatus: repairStatus,
          customSize: `${customSize} ${customSizeUnit}`,
          startedAt: dayjs().format('YYYY-MM-DDTHH:mm:ss')
        }
      });
      if (transferRes.data?.transferProduct.id) {
        setIsRepair(false);
        setRepairStatus('');
        toast({
          title: 'Başarılı',
          description: 'Ürün başarıyla sıraya eklendi',
          status: 'success'
        });
        // playAlert('glass');
      }
    }
  }

  async function onFinished(productId: string) {
    try {
      const res = await finishedProduct({
        variables: {
          productId: productId,
          finishedAt: dayjs().format('YYYY-MM-DDTHH:mm:ss')
        }
      });
      if (res.data?.finishedProduct === 'Ürün tamamlandı') {
        toast({
          title: 'Başarılı',
          description: 'Ürün başarıyla onaya gönderildi',
          status: 'success'
        });
        myRefetch();
      }
    } catch (error) {
      console.log('🌵💜🐢 error', error);
      toast({
        title: 'Hata',
        description: 'Ürün onaya gönderilemedi',
        status: 'error'
      });
    }
  }

  async function onDelete(id: string) {
    const deleteRes = await deleteMutation({
      variables: { id: id }
    });
    if (deleteRes.data?.deleteProduction.id) {
      myRefetch();
      toast({
        title: 'Success',
        description: 'Ürün Silindi',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    }
  }
  return (
    <Layout>
      <Container my={4} maxW={'container.xl'}>
        <Heading title="Ürün Oluşturma" />
        <Flex justifyItems={'end'} justify={'end'} my={4}>
          <Button
            display={'flex'}
            mx={2}
            onClick={() => {
              setIsShowMyProducts(!isShowMyProducts);
              myRefetch();
            }}
          >
            <BiTimeFive />
          </Button>
          <Button display={'flex'} onClick={() => myRefetch()}>
            Yenile
          </Button>
        </Flex>
        {!isShowMyProducts ? (
          <>
            {' '}
            <Box>
              <SimpleGrid
                display={{
                  base: 'flex',
                  md: 'grid'
                }}
                flexDirection={{
                  base: 'column',
                  md: 'row'
                }}
                columns={3}
                gap={4}
              >
                <Box>
                  <Text mb={2} fontWeight={'bold'}>
                    Kategori
                  </Text>
                  <CategorySelect
                    category={category}
                    setCategory={setCategory}
                  />
                </Box>
                {category && (
                  <Box>
                    <Text mb={2} fontWeight={'bold'}>
                      Alt Kategori
                    </Text>

                    <SubCategorySelect
                      category={category}
                      setSubCategory={setSubCategory}
                    />
                  </Box>
                )}
                {subCategory && (
                  <Box>
                    <Text mb={2} fontWeight={'bold'}>
                      Ürün
                    </Text>
                    <ProductSelect
                      subCategory={subCategory}
                      setProduct={setProduct}
                    />
                  </Box>
                )}
              </SimpleGrid>
            </Box>
            {loading && (
              <Flex
                justifyContent={'center'}
                alignItems="center"
                height={'200px'}
              >
                <Spinner mx="auto" />
              </Flex>
            )}
            {product ? (
              <Box
                border="1px solid"
                borderColor={'gray.100'}
                my="4"
                p={4}
                rounded={4}
              >
                <SimpleGrid
                  display={{
                    base: 'flex',
                    md: 'grid'
                  }}
                  flexDirection={{
                    base: 'column',
                    md: 'row'
                  }}
                  columns={5}
                  gap={6}
                >
                  <GridItem colSpan={2}>
                    <Box
                      border="1px solid"
                      borderColor={'gray.100'}
                      rounded="md"
                      overflow={'hidden'}
                    >
                      <img
                        src={`${process.env.REACT_APP_CDNURL}/${data?.productGroup?.photos[0]}?width=500&height=500&trim=20&extend=20&bg=ffffff&fit=contain`}
                        alt="Juju Görsel"
                        width={500}
                        height={500}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Box fontSize={'2xl'} fontWeight={'bold'}>
                      {data?.productGroup?.description[0].title}
                    </Box>
                    <Box fontSize={'lg'} mb={6}>
                      {data?.productGroup?.category.sku}-
                      {data?.productGroup?.sku}
                    </Box>

                    <Box mb={2} fontSize={'lg'} fontWeight={'bold'}>
                      Bedenler
                    </Box>
                    <SimpleGrid columns={3} gap={4}>
                      {data?.productGroup?.sizes.map((s) => {
                        if (s.sku === 'C') {
                          return (
                            <GridItem
                              colSpan={{
                                base: 3,
                                md: 2
                              }}
                            >
                              <Flex
                                w={{
                                  base: 'full',
                                  md: '75%'
                                }}
                                alignItems={'center'}
                                gap={2}
                              >
                                <Input
                                  type={'number'}
                                  value={customSize}
                                  onChange={(e) => {
                                    setCustomSize(e.target.value);
                                    setSize(s.id);
                                  }}
                                  placeholder={'Boyut Giriniz'}
                                />
                                <Select
                                  value={customSizeUnit}
                                  onChange={(e) =>
                                    setCustomSizeUnit(e.target.value)
                                  }
                                >
                                  <option value="cm">CM</option>
                                  <option value="m">M</option>
                                </Select>
                              </Flex>
                            </GridItem>
                          );
                        }
                        return (
                          <Button
                            key={s.id}
                            bg={`${size === s.id ? 'purple' : 'blue.100'}`}
                            color={`${size === s.id && 'white'}`}
                            mr={2}
                            onClick={(e: any) => setSize(s.id)}
                          >
                            {s.description[0].title}
                          </Button>
                        );
                      })}
                    </SimpleGrid>

                    <Box mt={6}>
                      <Box mb={2} fontSize={'lg'} fontWeight={'bold'}>
                        Seçenekler
                      </Box>
                      <Flex flexDirection={'column'}>
                        {data?.productGroup?.materials.map((m, i) => {
                          return (
                            <Box key={m.id} mb={2}>
                              <Text fontSize={'md'} fontWeight={'semibold'}>
                                {m.description[0].title}
                              </Text>
                              <MaterialSelect
                                data={m}
                                i={i}
                                material={material}
                                setMaterial={setMaterial}
                              />
                            </Box>
                          );
                        })}
                      </Flex>
                    </Box>
                    <Box mt={6}>
                      <Button
                        bg={isRepair ? 'blue.500' : 'orange.500'}
                        color={'white'}
                        _hover={{
                          bg: isRepair ? 'blue.600' : 'orange.600'
                        }}
                        onClick={(e) => setIsRepair(!isRepair)}
                        mb={2}
                      >
                        Tadilat
                      </Button>

                      {isRepair && (
                        <Box>
                          <Text mb={2} fontWeight={'bold'}>
                            Tadilat Seçenekleri
                          </Text>
                          <Select
                            value={repairStatus}
                            onChange={(e) => setRepairStatus(e.target.value)}
                          >
                            <option value="İp Değişimi">İp Değişimi</option>
                            <option value="Parça Değişimi">
                              Parça Değişimi
                            </option>
                            <option value="Uzunluk / Boyut Değişimi">
                              Uzunluk / Boyut Değişimi
                            </option>
                            <option value="Dolap Koruma">Dolap Koruma</option>
                            <option value="Kilit Değişimi">
                              Kilit Değişimi
                            </option>
                            <option value="Taş Yapıştırma">
                              Taş Yapıştırma
                            </option>
                          </Select>
                        </Box>
                      )}
                    </Box>
                    <Box mt={6} textAlign="right">
                      <Button
                        mx={3}
                        bg={'orange.300'}
                        _hover={{
                          bg: 'orange.400'
                        }}
                        disabled={transferLoading}
                        onClick={() => {
                          setCategory('');
                          setSubCategory('');
                          setProduct('');
                          setMaterial([]);
                          setSize('');
                          setIsRepair(false);
                          setRepairStatus('');
                        }}
                      >
                        Başa Dön
                      </Button>
                      <Button
                        bg={'green.300'}
                        _hover={{
                          bg: 'green.400'
                        }}
                        isLoading={generateLoading}
                        disabled={generateLoading}
                        onClick={onSubmit}
                      >
                        Sıraya Ekle
                      </Button>
                    </Box>
                  </GridItem>
                </SimpleGrid>
              </Box>
            ) : (
              <Box
                my={2}
                border="1px solid"
                borderColor={'gray.100'}
                p={4}
                textAlign="center"
              >
                Kategori, alt kategori ve ürün seçimi bekleniyor.
              </Box>
            )}
          </>
        ) : (
          <>
            <Box>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                Ürünlerim{' '}
              </Text>
              <Text fontSize={'lg'} mb={6}>
                Ürünlerinizi buradan yönetebilirsiniz.
              </Text>
            </Box>
            <Box>
              {myLoading ? (
                <Spinner />
              ) : (
                <SimpleGrid
                  gap={4}
                  columns={{
                    base: 1,
                    md: 2,
                    lg: 12
                  }}
                >
                  {myStartedProducts?.myStartedProductions.length === 0 ? (
                    <GridItem
                      my={2}
                      border="1px solid"
                      borderColor={'gray.100'}
                      p={4}
                      textAlign="center"
                      w={'full'}
                      colSpan={12}
                    >
                      Henüz ürününüz yok.
                    </GridItem>
                  ) : (
                    myStartedProducts?.myStartedProductions.map((p) => {
                      return (
                        <GridItem
                          bg={'gray.50'}
                          border="1px"
                          borderColor={'gray.100'}
                          borderStyle={'solid'}
                          rounded={'md'}
                          key={p.id}
                          colSpan={4}
                        >
                          <Box p={4}>
                            <Text fontWeight={'semibold'} fontSize={'xl'}>
                              {p.product.productGroup.description[0].title}
                            </Text>
                            <Text fontSize={'sm'}>
                              {p.product.sku?.split('-')[0]}-
                              {p.product.sku?.split('-')[1]}-
                              {p.product.sku?.split('-')[2]}
                            </Text>
                            <Box my={2} px={1}>
                              <Image
                                w={'full'}
                                src={`https://images.jujumood.com/${p.product.productGroup.photos[0]}?quality=80&width=300&height=300&fit=crop`}
                              />
                              <Box my={2}>
                                <Text fontSize={'sm'} fontWeight={'bold'}>
                                  Ürün Materyalleri
                                </Text>
                                <Box w={'auto'}>
                                  <RenderMaterials
                                    sku={p.product.sku || ''}
                                    locale="tr"
                                  />
                                </Box>
                              </Box>
                              {p.isRepair && (
                                <Box my={2}>
                                  <Text fontSize={'sm'} fontWeight={'bold'}>
                                    Tadilat Sebebi
                                  </Text>
                                  <Text fontSize={'sm'}>{p.repairStatus}</Text>
                                </Box>
                              )}
                              <Box my={2}>
                                <Text fontSize={'sm'} fontWeight={'bold'}>
                                  Başlama Zamanı
                                </Text>
                                <Text fontSize={'sm'}>
                                  {dayjs(p.startedAt?.split('Z')[0]).format(
                                    'DD.MM.YYYY HH:mm:ss'
                                  )}
                                </Text>
                              </Box>
                              <Flex>
                                <Button
                                  bg={'green.300'}
                                  _hover={{
                                    bg: 'green.400'
                                  }}
                                  isLoading={finishedLoading}
                                  disabled={finishedLoading}
                                  color={'white'}
                                  onClick={() => onFinished(p.id)}
                                >
                                  Onaya Gönder
                                </Button>

                                <Button
                                  bg={'red.300'}
                                  _hover={{
                                    bg: 'red.400'
                                  }}
                                  mx={4}
                                  isLoading={finishedLoading}
                                  disabled={finishedLoading}
                                  color={'white'}
                                  onClick={() => onDelete(p.id)}
                                >
                                  Kaldır
                                </Button>
                              </Flex>
                            </Box>
                          </Box>
                        </GridItem>
                      );
                    })
                  )}
                </SimpleGrid>
              )}
            </Box>
          </>
        )}
      </Container>
    </Layout>
  );
};
