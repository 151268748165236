import React, { useRef, useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Input,
  Box,
  Container,
  Flex,
  useToast,
  Text,
  Button,
  SimpleGrid,
  Spinner,
  GridItem
} from '@chakra-ui/react';
import {
  usePrintBarcodeWithBarcodeMutation,
  useProductInfoQuery,
  usePrintBarcodeMutation,
  usePrintCanadaBarcodeMutation,
  usePrintUsdRetailBarcodeMutation,
  usePrintWholeSaleBarcodeMutation,
  usePrintWholeSaleUsBarcodeMutation,
  usePrintEurBarcodeWithBarcodeMutation,
  usePrintGoldBarcodeMutation,
  PriceType,
  usePrintNoPriceBarcodeMutation,
  useProductGroupQuery,
  useSearchProductsLazyQuery,
  useProductInfoLazyQuery,
  usePrintNoCurrencyWholeSaleBarcodeMutation,
  usePrintUsdBarcodeWithBarcodeMutation
} from '../../generated/graphql';
import PageHeading from '../ui/Heading';
import RenderMaterials from '../../utils/renderMaterials';
import { MaterialSelect } from '../selects/MaterialSelect';
import { CategorySelect, SubCategorySelect } from '../selects/CategorySelect';
import { ProductSelect } from '../selects/ProductSelect';

export const PrintBarcode = () => {
  const [printBarcode] = usePrintBarcodeWithBarcodeMutation();
  const [barcode, setBarcode] = React.useState('');
  const [price, setPrice] = useState({
    foreign: 0,
    retail: 0,
    wholeSale: 0
  });
  const [sound, setSound] = useState('');
  const [showBarcode, setShowBarcode] = useState('');
  const [searchBarcode, setSearchBarcode] = useState(false);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [product, setProduct] = useState('');
  const [size, setSize] = useState('');
  const [material, setMaterial]: any = useState([]);
  const audioPlayer = useRef<any>(null);
  const toast = useToast();
  const [printCanadaBarcode] = usePrintCanadaBarcodeMutation();
  const [noPrice] = usePrintNoPriceBarcodeMutation();
  const [printUsdRetailBarcode] = usePrintUsdRetailBarcodeMutation();
  const [printWholeSaleBarcode] = usePrintWholeSaleBarcodeMutation();
  const [printWholeSaleUsBarcode] = usePrintWholeSaleUsBarcodeMutation();
  const [printForignBarcode] = usePrintEurBarcodeWithBarcodeMutation();
  const [printBarcodes] = usePrintBarcodeMutation();
  const [printGoldBarcode] = usePrintGoldBarcodeMutation();
  const [searchBarcodeLazy] = useSearchProductsLazyQuery();
  const [productInfoLazy] = useProductInfoLazyQuery();
  const [printUsdBarcode] = usePrintUsdBarcodeWithBarcodeMutation();
  const [printNoCurrencyWholeSaleBarcode] =
    usePrintNoCurrencyWholeSaleBarcodeMutation();
  function playAudio(link: string) {
    setSound(
      'https://res.cloudinary.com/jujumood/video/upload/v1654257483/' + link
    );
    audioPlayer.current.play();
  }

  const { data, loading, error } = useProductInfoQuery({
    variables: {
      barcode
    }
  });
  const {
    data: barcodeData,
    loading: barcodeLoading,
    error: barcodeError
  } = useProductGroupQuery({
    variables: {
      id: product
    }
  });
  useEffect(() => {
    setSize('');
    setMaterial([]);
  }, [subCategory, category]);

  useEffect(() => {
    setSize('');
    setMaterial([]);
    setBarcode('');
    setPrice({
      foreign: 0,
      retail: 0,
      wholeSale: 0
    });
  }, [product]);

  const onPrintBarcode = async (barcodes: string) => {
    // try {
    const res = await printBarcode({
      variables: {
        barcode: barcodes,
        printer: localStorage.getItem('printer')
          ? localStorage.getItem('printer')
          : 'ARGOX01'
      }
    });
    console.log('🌵💜🐢', res);
    // playAudio('sonar-beep_C_minor_eu0epu.wav');
    if (res.data?.printBarcodeWithBarcode?.result) {
      toast({
        title: 'Success',
        description: 'Barkod yazdırıldı',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
      setBarcode('');
    }
    // } catch (error: any) {
    //   console.log('🌵💜🐢 error', error);
    //   toast({
    //     title: 'Error',
    //     description: `${error.message}`,
    //     status: 'error'
    //   });
    // }
  };

  async function onSearchProduct() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const res = await searchBarcodeLazy({
        variables: {
          sku: formattedSku
        }
      });

      if (res.data?.searchProducts[0].barcode) {
        setBarcode(res.data?.searchProducts[0].barcode);
        let sku: any = res.data?.searchProducts[0].sku;
        const product = await productInfoLazy({
          variables: {
            barcode: res.data?.searchProducts[0].barcode
          }
        });
        sku = sku.split('-');
        let retailPrice: any =
          product.data?.productInfo[0].productGroup.price.find(
            (p) => p.priceType === PriceType.Retail
          );
        retailPrice = retailPrice?.price;
        let wholesalePrice: any =
          product.data?.productInfo[0].productGroup.price.find(
            (p) => p.priceType === PriceType.Wholesale
          );
        wholesalePrice = wholesalePrice?.price;
        let foreignPrice: any =
          product.data?.productInfo[0].productGroup.price.find(
            (p) => p.priceType === PriceType.Foreign
          );
        foreignPrice = foreignPrice?.price;

        const sizePrice =
          product.data?.productInfo[0].productGroup.sizePrices.find(
            (p) => p.size.sku === sku[2]
          );
        retailPrice =
          sizePrice?.prices.find((p) => p.priceType === PriceType.Retail)
            ?.price + retailPrice;
        wholesalePrice =
          sizePrice?.prices.find((p) => p.priceType === PriceType.Wholesale)
            ?.price + wholesalePrice;
        foreignPrice =
          sizePrice?.prices.find((p) => p.priceType === PriceType.Foreign)
            ?.price + foreignPrice;

        console.log('🌵💜🐢 SKU', sku);
        setPrice({
          foreign: foreignPrice,
          retail: retailPrice,
          wholeSale: wholesalePrice
        });
        console.log('🌵💜🐢', foreignPrice, price);
        toast({
          title: 'Success',
          description: 'Ürün Barkodu Bulundu',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      setBarcode('');
      toast({
        title: 'Uyarı',
        description: 'Seçili Özelliklerde Ürün Barkodu Bulunamadı',
        status: 'warning',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onRetailPrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const sizePrice = barcodeData?.productGroup?.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice?.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = barcodeData?.productGroup?.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = 0;
      if (price && sizePrices) defaultPrice = price.price + sizePrices.price;
      const res = await printBarcodes({
        variables: {
          sku: formattedSku,
          price: `${defaultPrice} ${price?.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onNoPrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const res = await noPrice({
        variables: {
          sku: formattedSku,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printNoPriceBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesalePrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const sizePrice = barcodeData?.productGroup?.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice?.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = barcodeData?.productGroup?.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = 0;
      if (price && sizePrices) defaultPrice = price.price + sizePrices.price;
      const res = await printWholeSaleBarcode({
        variables: {
          sku: formattedSku,
          price: `${defaultPrice} ${price?.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printWholeSaleBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onNoCurrencyWholesalePrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const sizePrice = barcodeData?.productGroup?.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice?.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = barcodeData?.productGroup?.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = 0;
      if (price && sizePrices) defaultPrice = price.price + sizePrices.price;
      const res = await printNoCurrencyWholeSaleBarcode({
        variables: {
          sku: formattedSku,
          price: `${defaultPrice} ${price?.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printNoCurrencyWholeSaleBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesaleUsPrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const sizePrice = barcodeData?.productGroup?.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice?.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = barcodeData?.productGroup?.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = 0;
      if (price && sizePrices) defaultPrice = price.price + sizePrices.price;
      const res = await printWholeSaleUsBarcode({
        variables: {
          sku: formattedSku,
          price: `${defaultPrice} ${price?.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      console.log('🌵💜🐢', res);

      if (res.data?.printUsdBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onRetailUsPrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const sizePrice = barcodeData?.productGroup?.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice?.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = barcodeData?.productGroup?.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = 0;
      if (sizePrices && price) {
        defaultPrice = price.price + sizePrices.price;
      }
      defaultPrice = defaultPrice * 2.8;
      const res = await printUsdRetailBarcode({
        variables: {
          sku: formattedSku,
          price: `${defaultPrice} ${price?.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printUsdRetailBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesaleCaPrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const sizePrice = barcodeData?.productGroup?.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice?.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = barcodeData?.productGroup?.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = 0;
      if (price && sizePrices) defaultPrice = price.price + sizePrices.price;
      const res = await printCanadaBarcode({
        variables: {
          sku: formattedSku,
          price: `${defaultPrice} ${price?.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printCanadaBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onForeginPrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const sizePrice = barcodeData?.productGroup?.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice?.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = barcodeData?.productGroup?.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = 0;
      if (price && sizePrices) defaultPrice = price.price + sizePrices.price;
      const res = await printForignBarcode({
        variables: {
          sku: formattedSku,
          price: `${defaultPrice} ${price?.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printEurBarcodeWithBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onGoldPrice() {
    try {
      const sizeData = barcodeData?.productGroup?.sizes.find(
        (s) => s.id === size
      );

      let setMaterials: any[] = [];
      material.map((m: any) => {
        Object.keys(m).map(function (key) {
          setMaterials.push(`${key}:${m[key]}`);
        });
      });
      let mats = material.map((code: any) => {
        return Object.entries(code).map((k) => {
          return `${k[0]}:${k[1]}`;
        });
      });
      const wMath = mats.join('-');
      const formattedSku = `${barcodeData?.productGroup?.category.sku}-${
        barcodeData?.productGroup?.sku
      }-${sizeData?.sku}${wMath.length > 0 ? `-${wMath}` : ''}`;

      const sizePrice = barcodeData?.productGroup?.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice?.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = barcodeData?.productGroup?.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = 0;
      if (price && sizePrices) defaultPrice = price.price + sizePrices.price;
      const res = await printGoldBarcode({
        variables: {
          sku: formattedSku,
          price: `${defaultPrice} ${price?.currency.code}`,
          priceType: PriceType.Retail,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printGoldBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  return (
    <Layout>
      <Container maxW={'container.xl'}>
        <PageHeading title="Barkod Basma" />
        <Button onClick={() => setSearchBarcode(!searchBarcode)}>
          Barkod Ara
        </Button>

        {searchBarcode ? (
          <Box>
            <Box>
              <SimpleGrid
                display={{
                  base: 'flex',
                  md: 'grid'
                }}
                flexDirection={{
                  base: 'column',
                  md: 'row'
                }}
                columns={3}
                gap={4}
              >
                <Box>
                  <Text mb={2} fontWeight={'bold'}>
                    Kategori
                  </Text>
                  <CategorySelect
                    category={category}
                    setCategory={setCategory}
                  />
                </Box>
                {category && (
                  <Box>
                    <Text mb={2} fontWeight={'bold'}>
                      Alt Kategori
                    </Text>

                    <SubCategorySelect
                      category={category}
                      setSubCategory={setSubCategory}
                    />
                  </Box>
                )}
                {category && (
                  <Box>
                    <Text mb={2} fontWeight={'bold'}>
                      Ürün
                    </Text>
                    <ProductSelect
                      subCategory={subCategory ? subCategory : category}
                      setProduct={setProduct}
                    />
                  </Box>
                )}
              </SimpleGrid>
            </Box>
            {loading && (
              <Flex
                justifyContent={'center'}
                alignItems="center"
                height={'200px'}
              >
                <Spinner mx="auto" />
              </Flex>
            )}
            {product ? (
              <Box
                border="1px solid"
                borderColor={'gray.100'}
                my="4"
                p={4}
                rounded={4}
              >
                <SimpleGrid
                  display={{
                    base: 'flex',
                    md: 'grid'
                  }}
                  flexDirection={{
                    base: 'column',
                    md: 'row'
                  }}
                  columns={5}
                  gap={6}
                >
                  <GridItem colSpan={2}>
                    <Box
                      border="1px solid"
                      borderColor={'gray.100'}
                      rounded="md"
                      overflow={'hidden'}
                    >
                      <img
                        src={`${process.env.REACT_APP_CDNURL}/${barcodeData?.productGroup?.photos[0]}?width=500&height=500&trim=20&extend=20&bg=ffffff&fit=contain`}
                        alt="Juju Görsel"
                        width={500}
                        height={500}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Box fontSize={'2xl'} fontWeight={'bold'}>
                      {barcodeData?.productGroup?.description[0].title} --{' '}
                      {barcode}
                    </Box>
                    <Box fontSize={'lg'} mb={6}>
                      {barcodeData?.productGroup?.category.sku}-
                      {barcodeData?.productGroup?.sku}
                    </Box>
                    {price.retail !== 0 && (
                      <Box>
                        <Text>TL:{price.retail}₺</Text>
                        <Text>Euro Satış: €{price.foreign}</Text>
                        <Text>Euro Toptan Satış: €{price.wholeSale}</Text>
                        <Text>Dolar Satış: ${price.foreign}</Text>
                        <Text>Dolar Toptan Satış: ${price.wholeSale}</Text>
                      </Box>
                    )}

                    <Box mb={2} fontSize={'lg'} fontWeight={'bold'}>
                      Bedenler
                    </Box>
                    <SimpleGrid columns={3} gap={4}>
                      {barcodeData?.productGroup?.sizes.map((s) => {
                        return (
                          <Button
                            key={s.id}
                            bg={`${size === s.id ? 'purple' : 'blue.100'}`}
                            color={`${size === s.id && 'white'}`}
                            mr={2}
                            onClick={(e: any) => setSize(s.id)}
                          >
                            {s.description[0].title}
                          </Button>
                        );
                      })}
                    </SimpleGrid>

                    <Box mt={6}>
                      <Box mb={2} fontSize={'lg'} fontWeight={'bold'}>
                        Seçenekler
                      </Box>
                      <Flex flexDirection={'column'}>
                        {barcodeData?.productGroup?.materials.map((m, i) => {
                          return (
                            <Box key={m.id} mb={2}>
                              <Text fontSize={'md'} fontWeight={'semibold'}>
                                {m.description[0].title}
                              </Text>
                              <MaterialSelect
                                data={m}
                                i={i}
                                material={material}
                                setMaterial={setMaterial}
                              />
                            </Box>
                          );
                        })}
                      </Flex>
                    </Box>
                    <Box mt={6} textAlign="right">
                      <SimpleGrid columns={[2, 2, 4]}>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          onClick={() => onRetailPrice()}
                        >
                          <Text>Tl Satış Fiyatı</Text>
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          onClick={() => onForeginPrice()}
                        >
                          <Text>Euro Satış Fiyatı</Text>
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          onClick={() => onWholesalePrice()}
                        >
                          <Text>Eur Toptan Fiyatı</Text>
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          onClick={() => onRetailUsPrice()}
                        >
                          <Text>Dolar Satış Fiyatı</Text>
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          my={2}
                          onClick={() => onWholesaleUsPrice()}
                        >
                          Dolar Toptan Fiyatı
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          my={2}
                          onClick={() => onWholesaleCaPrice()}
                        >
                          Kanada Toptan Fiyatı
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          bg={'gold'}
                          my={2}
                          onClick={() => onGoldPrice()}
                        >
                          Altın Etiket
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          my={2}
                          onClick={() => onNoPrice()}
                        >
                          Fiyatsız Etiket
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          my={2}
                          onClick={() => onNoCurrencyWholesalePrice()}
                        >
                          İşaretsiz <br /> Toptan Euro Etiket
                        </Button>
                        <Button
                          fontSize={['sm', 'sm', 'md']}
                          mx={2}
                          bg={'purple'}
                          textColor={'white'}
                          my={2}
                          onClick={() => onSearchProduct()}
                        >
                          Barkodu Göster
                        </Button>
                      </SimpleGrid>
                    </Box>
                  </GridItem>
                </SimpleGrid>
              </Box>
            ) : (
              <Box
                my={2}
                border="1px solid"
                borderColor={'gray.100'}
                p={4}
                textAlign="center"
              >
                Kategori, alt kategori ve ürün seçimi bekleniyor.
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <Box w={96}>
                <Input
                  placeholder="Barkod"
                  value={barcode}
                  type="number"
                  onChange={(e: any) => {
                    setBarcode(e.target.value);
                    // playAlert('morse');

                    if (e.target.value.length === 12) {
                      onPrintBarcode(e.target.value);
                    }
                  }}
                />
              </Box>
            </Flex>
            {data?.productInfo[0] && (
              <Box
                borderColor={'gray'}
                border="1px"
                my="4"
                rounded={4}
                shadow={'md'}
              >
                <Flex p={16}>
                  <Box>
                    <img
                      src={
                        process.env.REACT_APP_CDNURL +
                        '/' +
                        data?.productInfo[0]?.productGroup?.photos[0]
                      }
                      alt=""
                      width={500}
                      height={500}
                    />
                  </Box>
                  <Box ml={4}>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>
                      {data?.productInfo[0]?.productGroup?.description[0].title}
                    </Text>
                    <Text fontSize={'lg'}>
                      {data?.productInfo[0]?.productGroup?.category.sku}-
                      {data?.productInfo[0]?.productGroup?.sku}
                    </Text>
                    <Box fontSize={'2xl'}>
                      {data.productInfo[0] &&
                      data?.productInfo[0]?.productGroup.showOnWeb ? (
                        <Text color="green">Satışta Var</Text>
                      ) : (
                        <Text color="red">Satışta Yok</Text>
                      )}
                    </Box>
                    <Box my={2}>
                      <RenderMaterials
                        locale="tr"
                        sku={data?.productInfo[0]?.sku || ''}
                      />
                    </Box>
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Layout>
  );
};
