/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useEffect, useState } from 'react';

export const useProducts = ({ storeId }: { storeId: string }) => {
  const [products, setProducts] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [store, setStore] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const fetchProducts = async () => {
    setLoading(true);
    const stock = await axios.get(
      `${process.env.REACT_APP_STOCKURL}/stock/getFixedCount?storeId=${storeId}`
    );

    if (stock && stock.data) {
      if (storeId) {
        setProducts(stock.data);
      } else {
        setProducts(stock.data.newData);
      }
      setTotalPage(stock.data.count);
      setLoading(false);
    }
  };

  useEffect(() => {
    setStore(storeId);
    fetchProducts();
  }, [storeId]);

  return { products, loading, totalPage };
};
