/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import Select from 'react-select';
import { useProductGroupsCategoryQuery } from '../../generated/graphql';

interface ProductSelectProps {
  subCategory: string;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
}

export const ProductSelect = ({
  subCategory,
  setProduct
}: ProductSelectProps) => {
  const { data, loading, error } = useProductGroupsCategoryQuery({
    variables: { first: 1000, skip: 0, categoryId: subCategory }
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  return (
    <Select
      options={
        data &&
        data?.productGroups.productGroups.map((c) => {
          return {
            value: c.id,
            label: `${c.description[0].title} | ${c.category.sku}-${c.sku}`
          };
        })
      }
      onChange={(e: any) => setProduct(e.value)}
    />
  );
};
