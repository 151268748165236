import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button,
  Select,
  Icon
} from '@chakra-ui/react';
import { AiFillPrinter } from 'react-icons/ai';
import { GrDocumentConfig } from 'react-icons/gr';
export const TransferModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = React.useState<string>(
    localStorage.getItem('transferType') || 'server'
  );

  return (
    <Box>
      <Button bg={'orange.400'} onClick={onOpen} mx={2}>
        <Icon
          fontSize="17px"
          aria-label="messages"
          as={GrDocumentConfig}
          mt={1}
        />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Varsayılan Sayfa Seçimi</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Sayfa Seçiniz"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setPage(e.target.value);
              }}
              value={page}
            >
              <option value="elif">Elif</option>
              <option value="esma">Esma</option>
              <option value="simge">Simge</option>
              <option value="server">Server</option>
              <option value="other">Other</option>
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={(e: React.MouseEvent) => {
                localStorage.setItem('transferType', page);
                onClose();
                window.location.reload();
              }}
              bg={'green.500'}
              color="white"
            >
              Onayla
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
