import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Input,
  Button,
  Box,
  Flex,
  Container,
  Spinner,
  SimpleGrid
} from '@chakra-ui/react';
import {
  PriceType,
  useProductGroupSkuQuery,
  useProductGroupSkuLazyQuery
} from '../../generated/graphql';
import 'react-image-lightbox/style.css';

export const Dashboard = () => {
  const [sku, setSku] = useState('');
  const [price, setPrice] = useState('');
  const [photo, setPhoto] = useState('');
  const [tempSku, setTempSku] = useState('');
  const [search, { data, loading }] = useProductGroupSkuLazyQuery({
    variables: { sku: sku && sku }
  });

  useEffect(() => {
    if (data && data.productGroupSku) {
      setPhoto(data.productGroupSku.photos[0]);
      const getPrice = data.productGroupSku.price.find(
        (p) => p.priceType === PriceType.Retail
      );
      if (getPrice) {
        setPrice(`${getPrice.price} ${getPrice.currency.code}`);
      }
    }
  }, [data]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    search();
    return setSku(tempSku);
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <form onSubmit={handleSubmit}>
          <Flex
            my={4}
            mx={'auto'}
            justify="center"
            align="center"
            w={{
              base: '100%',
              md: '50%'
            }}
          >
            <Input
              placeholder="sku"
              type="number"
              min={0}
              onChange={(e: any) => setTempSku(e.target.value)}
              value={tempSku}
            />
            <Button mx={4} type="submit">
              ARA
            </Button>
          </Flex>
        </form>
      </Container>
      <Container maxW="container.lg">
        {loading ? (
          <Box>
            <Spinner mx="auto" />{' '}
          </Box>
        ) : (
          <Box my={8}>
            {data && (
              <Box mt={4} mx="auto" w={['full']}>
                <Flex
                  direction={{
                    base: 'column',
                    md: 'row'
                  }}
                >
                  <Box>
                    {data && sku && (
                      <Box mb={6}>
                        <img
                          src={`${process.env.REACT_APP_CDNURL}/${photo}?trim=30&extend=20&format=webp&quality=50&width=700&height=700&bg=ffffff&fit=contain`}
                          alt=""
                          width={500}
                          height={500}
                        />
                      </Box>
                    )}
                    <SimpleGrid
                      gap={4}
                      columns={{
                        base: 4,
                        md: 6
                      }}
                    >
                      {data &&
                        sku &&
                        data.productGroupSku?.photos.map((p, i) => {
                          return (
                            <Box
                              border="1px solid"
                              borderColor={'gray.100'}
                              rounded="md"
                              overflow={'hidden'}
                              p={1}
                              key={i + 1}
                            >
                              <img
                                onClick={() => {
                                  setPhoto(p);
                                }}
                                src={`${process.env.REACT_APP_CDNURL}/${p}`}
                                alt="Juju"
                                width={100}
                                height={100}
                              />
                            </Box>
                          );
                        })}
                    </SimpleGrid>
                  </Box>
                  <Box
                    width={'100%'}
                    mt={{
                      base: 6,
                      md: 0
                    }}
                    bgColor="gray.50"
                    rounded="sm"
                    p={4}
                    textAlign={{
                      base: 'center',
                      md: 'left'
                    }}
                  >
                    <Box
                      fontWeight={'bold'}
                      textColor={'black'}
                      fontSize={{
                        base: '2xl',
                        md: '3xl'
                      }}
                    >
                      {data && sku && data.productGroupSku.description[0].title}
                    </Box>
                    <Box
                      fontSize={{
                        base: 'lg',
                        md: 'lg'
                      }}
                      color="gray.500"
                    >
                      {sku &&
                        data &&
                        `${data.productGroupSku.category.sku}-${data.productGroupSku.sku}`}
                    </Box>
                    <Box
                      fontSize={{
                        base: 'xl',
                        md: 'xl'
                      }}
                    >
                      {sku &&
                        data &&
                        data.productGroupSku.category.description[0].title}
                    </Box>
                    <Box
                      fontSize={{
                        base: 'lg',
                        md: 'lg'
                      }}
                    >
                      {data && data.productGroupSku.showOnWeb ? (
                        <Box color="green">Satışta Var</Box>
                      ) : (
                        <Box color="red">Satışta Yok</Box>
                      )}
                    </Box>
                    <Box fontSize={'xl'} fontWeight={'bold'} my={2}>
                      Bedenler
                      <Flex
                        justifyContent={{
                          base: 'center',
                          md: 'flex-start'
                        }}
                      >
                        {data &&
                          data.productGroupSku.sizes.map((s) => {
                            return (
                              <Box key={s.id} fontWeight={'normal'} mr="2">
                                {s.sku}
                              </Box>
                            );
                          })}
                      </Flex>
                    </Box>
                    <Box fontSize={'xl'} fontWeight={'bold'} my="2">
                      Seçenekler
                      <Box>
                        {data &&
                          data.productGroupSku.materials.map((m) => {
                            return (
                              <Box key={m.id} fontWeight={'normal'} mr="2">
                                {m.description[0].title}{' '}
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>

                    <Box fontSize={'xl'} fontWeight={'bold'} my="2">
                      Satış Fiyatı
                    </Box>
                    <Box>{price}</Box>
                    <Box fontSize={'sm'} mt={3}>
                      * Gösterilen fiyatlar en küçük ölçü birimine göredir.
                      Fiyatlar bedende değişiklik gösterebilir.
                    </Box>
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Layout>
  );
};
