import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  useProductInfoLazyQuery,
  PriceType,
  useStoresWithTitleQuery,
  LocalType,
  usePrintBarcodeWithBarcodeMutation
} from '../../generated/graphql';
import {
  Input,
  Box,
  Container,
  Textarea,
  Button,
  useToast,
  SimpleGrid,
  GridItem,
  FormLabel,
  Select,
  Table,
  Th,
  Tbody,
  Thead,
  Tr,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex
} from '@chakra-ui/react';
import { Layout } from '../Layout';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
const Inventory = () => {
  const [barcode, setBarcode] = useState('');
  const [store, setStore] = useState('');
  const [products, setProducts] = useState<any[]>([]);
  const [note, setNote] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [printBarcode] = usePrintBarcodeWithBarcodeMutation();

  const fetchProducts = async () => {
    const stock = await axios.get(
      `${process.env.REACT_APP_STOCKURL}/stock/getStoreStockCount?storeId=${store}`
    );

    if (stock && stock.data) {
      setProducts(stock.data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [store]);
  const toast = useToast();
  const [searchProduct] = useProductInfoLazyQuery();
  const { data: stores, loading } = useStoresWithTitleQuery({
    variables: {
      isLocal: LocalType.Store
    }
  });
  const addStock = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { data } = await searchProduct({
        variables: {
          barcode
        }
      });


      const productPrice = data?.productInfo[0].productGroup.price.find(
        (p) => p.priceType === PriceType.Retail
      );
      const size = data?.productInfo[0].sku?.split('-')[1] as any;
      const productSize = data?.productInfo[0].productGroup.sizePrices.find(
        (p) => p.size === size
      );
      const productSizePrice = productSize?.prices.find(
        (p) => p.priceType === PriceType.Retail
      );
      let price = productPrice?.price as number;
      if (productSizePrice) {
        price += productSizePrice.price;
      }
      const res = await axios.post(
        `${process.env.REACT_APP_STOCKURL}/stock/createStockCount`,
        {
          sku: data?.productInfo[0].sku,
          orderId: '',
          barcode: data?.productInfo[0].barcode,
          price: price,
          storeId: store,
          currency: productPrice?.currency.code,
          description: `${localStorage.getItem(
            'userId'
          )} nolu kullanıcı tarafından ${store} nolu mağazanın stoğuna eklendi (Stok Sayım)`,
          amount: 1,
          photo: data?.productInfo[0].productGroup.photos[0]
        }
      );
      if (res.data.status === 'success') {
        fetchProducts();
        toast({
          title: 'Stock added successfully',
          status: 'success',
          duration: 2000,
          isClosable: true
        });
        setBarcode('');
      }
    } catch (error) {
      console.log('🌵💜🐢 error', error);
    }
  };
  async function confirmStock() {
    const res = await axios.post(
      `${process.env.REACT_APP_STOCKURL}/stock/confirmStockCount`,
      {
        storeId: store,
        userId: localStorage.getItem('userId'),
        note: note
      }
    );
    if (res.data.status) {
      fetchProducts();
      toast({
        title: 'Stock confirmed successfully'
      });
      onClose();
      setNote('');
      setStore('');
      navigate(`/stock-count/${res.data.count.id}`);
    }
  }
  let totalAmount = 0;
  if (products) {
    products.map((product) => {
      totalAmount += product.amount;
    });
  }
  const deleteStock = async (id: string) => {
    await axios.get(
      `${process.env.REACT_APP_STOCKURL}/stock/deleteStockCount/${id}`
    );
    fetchProducts();
  };

  const handlePrintBarcode = async (barcode: string) => {
    const print = await printBarcode({
      variables: {
        barcode: barcode,
        printer: localStorage.getItem('printer')
          ? localStorage.getItem('printer')
          : 'ARGOX01'
      }
    });
    console.log('🌵💜🐢', print);
    // playAudio('sonar-beep_C_minor_eu0epu.wav');
    if (print.data?.printBarcodeWithBarcode?.result) {
      toast({
        title: 'Success',
        description: 'Barkod yazdırıldı',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    }
  };

  return (
    <Layout>
      <Container maxW={'container.xl'}>
        <SimpleGrid h={'xl'} gap={8} my={6} columns={12}>
          <GridItem rounded={'md'} bg={'gray.100'} px={4} colSpan={4}>
            <Box my={3}>
              <FormLabel>Mağaza Seçiniz</FormLabel>
              <Select
                borderColor={'gray.400'}
                value={store}
                onChange={(e) => setStore(e.target.value)}
              >
                <option value="">Select Store</option>
                {stores?.stores.map((store) => {
                  return (
                    <option value={store.id} key={store.id}>
                      {store.title}
                    </option>
                  );
                })}
              </Select>
            </Box>
            <Box my={3}>
              <form onSubmit={addStock}>
                <FormLabel>Barkod</FormLabel>
                <Input
                  placeholder="Barkod"
                  borderColor={'gray.400'}
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                />
                <Button type="submit" mt={3} colorScheme="teal">
                  Ekle
                </Button>
              </form>
            </Box>
          </GridItem>
          <GridItem
            h={'full'}
            colSpan={8}
            rounded={'md'}
            bg={'gray.100'}
            px={4}
          >
            <Box fontWeight={'bold'} m={4}>
              Toplam Adet {totalAmount}
            </Box>
            <Box overflowY={'auto'} height={'lg'}>
              {store === '' ? (
                <Flex
                  alignItems={'center'}
                  h={'full'}
                  justifyContent={'center'}
                >
                  <Text fontSize={'xl'} textAlign={'center'}>
                    Lütfen Mağaza Seçiniz
                  </Text>
                </Flex>
              ) : (
                <Table my={3} variant="simple">
                  <Thead position={'sticky'} top={0} bg={'gray.300'} py={3}>
                    <Tr>
                      <Th>Görsel</Th>
                      <Th>SKU</Th>
                      <Th>Barkod</Th>
                      <Th>Adet</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {products.map((product) => {
                      totalAmount += product.amount;
                      return (
                        <Tr
                          borderBottom={'1px'}
                          borderStyle={'solid'}
                          borderColor={'gray.500'}
                          key={product.id}
                        >
                          <Td>
                            <img
                              src={`${process.env.REACT_APP_CDNURL}/${product.photos}`}
                              alt={product.sku}
                              style={{ width: '100px' }}
                            />
                          </Td>
                          <Td>{product.sku}</Td>
                          <Td>
                            <Button
                              onClick={() =>
                                handlePrintBarcode(product.barcode)
                              }
                            >
                              {product.barcode}
                            </Button>
                          </Td>
                          <Td>{product.amount}</Td>
                          <Td>
                            <Button onClick={() => deleteStock(product.id)}>
                              <DeleteIcon />
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </Box>

            {products.length > 0 && (
              <Button
                my={4}
                px={2}
                bg={'purple.400'}
                _hover={{ bg: 'purple.500' }}
                color="white"
                onClick={onOpen}
              >
                Sayımı Tamamla
              </Button>
            )}
          </GridItem>
        </SimpleGrid>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Stok Sayımı</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{totalAmount} Adet Ürün Sayılmıştır</Text>
            <FormLabel>
              Notunuzu giriniz (Stok sayımı için kullanılacaktır)
            </FormLabel>
            <Textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              resize={'none'}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              İptal Et
            </Button>
            <Button onClick={confirmStock} variant="ghost">
              Onayla
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  );
};

export default Inventory;
