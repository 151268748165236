import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import { OrderTypeType, useInvoicesQuery } from '../../generated/graphql';
import formatMoney from '../../utils/formatMoney';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag,
  Input,
  Editable,
  EditableInput,
  SimpleGrid,
  GridItem,
  EditablePreview,
  ButtonGroup,
  IconButton,
  useEditableControls,
  Button,
  Text
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getSizeFromSku } from '../../utils/getSizeFromSku';
import RenderMaterials from '../../utils/renderMaterials';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { FcPrint } from 'react-icons/fc';

interface Request {
  id: string;
  status: string;
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
  storeId: string;
  sellerId: string;
  productRequest: ProductRequest[];
}

type ProductRequest = {
  id: string;
  sku: string;
  quantity: number;
  notes: string;
  createdAt: string;
  updatedAt: string;
  productId: string;
  isCompleted: number;
  product: Product;
  isDelivered: boolean;
};
type Product = {
  id: string;
  sku: string;
  category: string;
  name: string;
  photos: string[];
  createdAt: string;
  updatedAt: string;
};

function EditableControls({
  value,
  id,
  fetch
}: {
  value: string;
  id: string;
  fetch: () => void;
}) {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps
  } = useEditableControls();
  const onSubmit = () => {
    axios({
      url: `${process.env.REACT_APP_PRODUCTURL}/request/changeStatus/${id}`,
      method: 'PUT',
      data: {
        status: value
      }
    }).then((res) => {
      console.log(res.data);
      fetch();
    });
  };
  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton
        icon={<CheckIcon />}
        {...getSubmitButtonProps()}
        aria-label="Check"
        onClick={onSubmit}
      />
      <IconButton
        aria-label="Cancel"
        icon={<CloseIcon />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        aria-label="Edit"
        size="sm"
        icon={<EditIcon />}
        {...getEditButtonProps()}
      />
    </Flex>
  );
}

const ProductRequests = () => {
  const [data, setData] = useState<Request>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');
  const params = useParams<{ id: string }>();

  console.log('🌵💜🐢', params.id);

  const fetchData = async () => {
    try {
      const result = await axios({
        url: `${process.env.REACT_APP_PRODUCTURL}/request/get/${params.id}`,
        method: 'GET'
      });
      console.log('ss', result.data);
      setData(result.data);
      setStatus(result.data.status);
      setLoading(false);
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function renderStore(id: string) {
    switch (id) {
      case 'ckoq4cr691mfa0759r1n8nddz':
        return 'Nişantaşı';
      case 'ckoq4krsy1mvv0759k9mrf1dr':
        return 'Bebek';
      case 'ckwz1vfu8fkca08599m67a6mk':
        return 'Galataport';
      default:
        return id;
    }
  }
  function renderUser(id: string) {
    const users = localStorage.getItem('users');
    if (users !== null) {
      const user = JSON.parse(users).find((user: any) => user.id === id);

      return user?.name + ' ' + user?.surname;
    }
  }

  function onChangeStatus(id: string) {
    axios({
      url: `${process.env.REACT_APP_PRODUCTURL}/productRequest/delivered/${id}`,
      method: 'GET'
    }).then((res) => {
      console.log(res.data);
      fetchData();
    });
  }
  function onChangeIsRead(id: string) {
    axios({
      url: `${process.env.REACT_APP_PRODUCTURL}/request/changeIsRead/${id}`,
      method: 'GET'
    }).then((res) => {
      console.log(res.data);
      fetchData();
    });
  }
  function onSetQuantity(
    id: string,
    type: string,
    quantity: number,
    data: number
  ) {
    setLoading(true);
    if (data <= quantity) {
      axios({
        url: `${process.env.REACT_APP_PRODUCTURL}/productRequest/changeIsCompleted/${id}`,
        method: 'PUT',
        data: {
          isCompleted: quantity
        }
      }).then((res) => {
        console.log(res.data);
        fetchData();
        setLoading(false);
      });
    }
  }

  return (
    <Layout>
      <Container my={8} maxW="container.xl">
        <Button
          ml={'auto'}
          colorScheme={'blue'}
          variant="outline"
          onClick={() => {
            window.print();
          }}
        >
          <FcPrint className="text-xl" />
        </Button>

        <Box id="printArea">
          <PageHeading title="Mağaza Talepleri" />
          <Box>
            <SimpleGrid columns={3} gap={4}>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="md"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Denetleyen
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> </Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="md"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Kurye
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> </Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="md"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Teslim Alan{' '}
                  </Box>
                  <Table className="w-full table-auto">
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}></Th>
                        <Td></Td>
                      </Tr>

                      <Tr>
                        <Th></Th>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <Table>
              <Tbody>
                <Tr>
                  <Th>Mağaza</Th>
                  <Td>JUJU {renderStore(data?.storeId || '')}</Td>
                </Tr>
                <Tr>
                  <Th>Talep Tarihi</Th>
                  <Td>
                    {dayjs(data?.createdAt).format('DD MMMM YYYY HH:mm:ss')}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Talep Eden</Th>
                  <Td>{renderUser(data?.sellerId || '')}</Td>
                </Tr>
                <Tr>
                  <Th>Okundu Bilgisi</Th>
                  <Td>
                    {data?.isRead ? (
                      <Button
                        bg={'green.200'}
                        onClick={() => onChangeIsRead(data.id)}
                      >
                        <ImCheckboxChecked />
                      </Button>
                    ) : (
                      <Button
                        bg={'red.200'}
                        onClick={() => onChangeIsRead(data?.id || '')}
                      >
                        <ImCheckboxUnchecked />
                      </Button>
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Durumu</Th>
                  <Td>
                    <Editable
                      textAlign="center"
                      defaultValue={status}
                      value={status}
                      isPreviewFocusable={false}
                      display="flex"
                    >
                      <EditablePreview />
                      {/* Here is the custom input */}
                      <Input
                        value={status ? status : 'aa'}
                        onChange={(e) => setStatus(e.target.value)}
                        as={EditableInput}
                      />
                      <EditableControls
                        fetch={fetchData}
                        value={status}
                        id={data?.id || ''}
                      />
                    </Editable>
                  </Td>
                </Tr>
                <Tr>
                  <Th>Son Güncelleme</Th>
                  <Td>
                    {dayjs(data?.updatedAt).format('DD MMMM YYYY HH:mm:ss')}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Table>
            <Thead>
              <Tr>
                <Td>Fotoğraf</Td>
                <Td>SKU</Td>
                <Td>Ürün</Td>
                <Td>Beden</Td>
                <Td>Seçenekler</Td>
                <Td>Adet</Td>
                <Td>Not</Td>
                <Td>Gönderildi Adet</Td>
                <Td>Kontrol</Td>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.productRequest.map((item: ProductRequest, i) => {
                  return (
                    <Tr key={i}>
                      <Td>
                        <img
                          src={`${process.env.REACT_APP_CDNURL}/${item.product.photos[0]}?format=webp&height=100&quality=50`}
                          className="mx-auto"
                          alt="product"
                        />
                      </Td>
                      <Td>
                        {item.product.category}-{item.product.sku}
                      </Td>
                      <Td>
                        <div className="text-xs text-gray-500">{item.sku}</div>
                        <hr />
                        <div>{item.product.name}</div>
                      </Td>
                      <Td>{getSizeFromSku(item.sku)}</Td>
                      <Td>
                        {' '}
                        <RenderMaterials locale="tr" sku={item?.sku || ''} />
                      </Td>
                      <Td>{item.quantity}</Td>
                      <Td>{item.notes}</Td>
                      {/* <Td>
                        {item.isDelivered ? (
                          <Button
                            onClick={() => onChangeStatus(item.id)}
                            bg={'green.200'}
                          >
                            <ImCheckboxChecked />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => onChangeStatus(item.id)}
                            bg={'red.200'}
                          >
                            <ImCheckboxUnchecked />
                          </Button>
                        )}
                      </Td>
                       */}
                      <Td>
                        {item.isCompleted === item.quantity ? (
                          <>Tamamlandı</>
                        ) : (
                          <Flex flexDir={'column'} alignItems={'center'}>
                            <Text my={2} fontWeight={'medium'}>
                              {item.isCompleted}/{item.quantity}
                            </Text>
                            {loading ? (
                              <Spinner />
                            ) : (
                              <Box>
                                <Button
                                  mx={1}
                                  onClick={() =>
                                    onSetQuantity(
                                      item.id,
                                      '+',
                                      item.isCompleted + 1,
                                      item.quantity
                                    )
                                  }
                                >
                                  +
                                </Button>
                                <Button
                                  mx={1}
                                  onClick={() =>
                                    onSetQuantity(
                                      item.id,
                                      '-',
                                      item.isCompleted - 1,
                                      item.quantity
                                    )
                                  }
                                >
                                  -
                                </Button>
                              </Box>
                            )}
                          </Flex>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default ProductRequests;
