import { useEffect, useState } from 'react';
import { Layout } from './../Layout';
import { useParams, Link } from 'react-router-dom';
import { useSearchQuery } from '../../utils/useQuery';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import axios from 'axios';
import RenderMaterials from '../../utils/renderMaterials';
import 'dayjs/locale/tr';

import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Select,
  Spinner,
  FormControl,
  useToast,
  Button
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import { usePrintBarcodeMutation } from '../../generated/graphql';
dayjs.locale('tr');

interface StockItem {
  _sum: {
    amount: number;
  };
  sku: string;
}
const Dashboard = () => {
  const params = useParams();
  const query = useSearchQuery();
  const [printBarcodes] = usePrintBarcodeMutation();
  const toast = useToast();
  const [storeId, setStoreId] = useState<string | null | undefined>(
    query.get('storeId')
  );
  const { isLoading: storesLoading, data: storesData } = useQuery(
    'query-get-stores',
    async () => {
      return await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_APIURL}external/stores?key=${process.env.REACT_APP_EXTERNALKEY}`
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  const { isLoading, data, refetch } = useQuery(
    'query-get-stocks-by-sku',
    async () => {
      const url = `${process.env.REACT_APP_STOCKURL}/stock/byProductGroup?productGroupId=${params.productGroupId}&storeId=${storeId}&categoryId=${params.categoryId}`;
      return await axios({
        method: 'GET',
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  useEffect(() => {
    refetch();
  }, [storeId, refetch]);

  async function onRetailPrice(formattedSku: string) {
    try {
      let defaultPrice = 0;
      const res = await printBarcodes({
        variables: {
          sku: formattedSku,
          price: `0`
        }
      });
      if (res.data?.printBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading
          title={`${params.categoryId ? params.categoryId : ''}-${
            params.productGroupId ? params.productGroupId : ''
          } Stoklar`}
        />
        <Box mb={4}>
          <FormControl mb={2}>Mağaza Seçimi</FormControl>
          {storesLoading ? (
            <Spinner />
          ) : (
            <Select
              onChange={(e) => {
                setStoreId(e.target.value);
              }}
              value={storeId ? storeId : ''}
              name="storeSelect"
              colorScheme={'whiteAlpha'}
            >
              <option value={''}>Tüm Mağazalar</option>
              {storesData?.data.map((store: { id: string; title: string }) => {
                return (
                  <option key={store.id} value={store.id}>
                    {store.title}
                  </option>
                );
              })}
            </Select>
          )}
        </Box>
        <Box overflowX={'auto'}>
          <Table variant={'striped'} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>SKU </Th>

                <Th>Kategori </Th>
                <Th>Materyaller </Th>
                <Th textAlign={'right'}>Adet</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={3}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : (
                data?.data.data.stockData.map((item: StockItem, i: number) => {
                  return (
                    <Tr key={i}>
                      <Td width="50px">{i + 1}</Td>
                      <Td>
                        {' '}
                        <Link
                          to={`/stok/${params.categoryId}/${params.productGroupId}/${item.sku}?storeId=${storeId}`}
                        >
                          <Box textDecor={'underline'} color="blue.500">
                            {item.sku}
                          </Box>
                        </Link>
                      </Td>

                      <Td>
                        <Link
                          to={`/stok/${params.categoryId}?storeId=${storeId}`}
                        >
                          <Box textDecor={'underline'} color="blue.500">
                            {params.categoryId}
                          </Box>
                        </Link>
                      </Td>

                      <Td>
                        <RenderMaterials locale="tr" sku={item.sku} />
                      </Td>
                      <Td width="100px" textAlign={'right'}>
                        {item._sum.amount}
                      </Td>
                      <Td>
                        <Button
                          bg={'#862337'}
                          color="white"
                          _hover={{ bg: '#862337', opacity: 0.8 }}
                          onClick={() => onRetailPrice(item.sku)}
                        >
                          Barkod Bas
                        </Button>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default Dashboard;
