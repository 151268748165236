import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  Button,
  Box,
  Input,
  Flex,
  Text,
  useToast,
  Icon
} from '@chakra-ui/react';
import {
  BiBadgeCheck,
  BiBadge,
  BiSend,
  BiCurrentLocation,
  BiSearchAlt2
} from 'react-icons/bi';
import { Layout } from '../Layout';
import PageHeading from '../ui/Heading';
import { StorageModal } from '../ui/StorageModal';
import {
  useStoreMessagesReportQuery,
  useToggleReadStoreMessageMutation,
  useToggleSendStoreMessageMutation
} from '../../generated/graphql';

export const StoreMessage = () => {
  const [startDate, setStartDate] = useState(
    dayjs().format('YYYY-MM-DDT00:00:00.sssZ')
  );
  const [endDate, setEndDate] = useState(
    dayjs().format('YYYY-MM-DDT23:59:59.sssZ')
  );
  const [startAt, setStartAt] = useState(
    dayjs().format('YYYY-MM-DDT00:00:00.sssZ')
  );
  const [endAd, setEndAt] = useState(
    dayjs().format('YYYY-MM-DDT23:59:59.sssZ')
  );

  const onStartDate = (e: any) => {
    console.log('🌵💜🐢', e);

    setStartDate(dayjs(e).format('YYYY-MM-DDT00:00:00.sssZ'));
  };
  const onEndDate = (e: any) => {
    setEndDate(dayjs(e).format('YYYY-MM-DDT23:59:59.sssZ'));
  };
  const toast = useToast();

  const { data, loading, error, refetch } = useStoreMessagesReportQuery({
    variables: {
      startAt: startAt,
      endAt: endAd
    }
  });
  const [toggleRead] = useToggleReadStoreMessageMutation();
  const [toggleSend] = useToggleSendStoreMessageMutation();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  const onIsRead = async (id: string) => {
    const res = await toggleRead({
      variables: {
        id
      }
    });
    if (res.data?.toggleReadStoreMessage.id) {
      refetch();
      toast({
        title: 'Success',
        description: 'Mesaj Okundu',
        status: 'success'
      });
    }
  };
  const onIsSend = async (id: string) => {
    const res = await toggleSend({
      variables: {
        id
      }
    });
    if (res.data?.toggleSendStoreMessage.id) {
      refetch();
      toast({
        title: 'Success',
        description: 'Ürün Gönderildi',
        status: 'success'
      });
    }
  };

  return (
    <Layout>
      <Container maxW={'container.xl'}>
        <PageHeading title="Mağaza Mesajları" />

        <Flex
          alignItems={'center'}
          flexDirection={{
            base: 'column',
            md: 'row'
          }}
        >
          <Box
            mr={{
              base: 0,
              mr: 4
            }}
            width="100%"
          >
            <Text fontWeight={'bold'}>Başlangıç Tarihi</Text>
            <Box
              w={{
                base: '100%',
                md: 64
              }}
              my={2}
            >
              <Input
                value={dayjs(startDate.toString()).format('YYYY-MM-DD')}
                type={'date'}
                onChange={(e: any) => onStartDate(e.target.value)}
              />
            </Box>
          </Box>

          <Box width="100%">
            <Text fontWeight={'bold'}>Bitiş Tarihi</Text>
            <Box
              w={{
                base: '100%',
                md: 64
              }}
              my={2}
            >
              <Input
                value={dayjs(endDate.toString()).format('YYYY-MM-DD')}
                type={'date'}
                onChange={(e: any) => onEndDate(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            mt={{
              base: 2,
              md: 6
            }}
            ml={{
              base: 0,
              md: 4
            }}
          >
            <Button
              width={{
                base: 'full',
                md: 'initial'
              }}
              onClick={(e: React.MouseEvent) => {
                setStartAt(startDate);
                setEndAt(endDate);
              }}
            >
              <Icon as={BiSearchAlt2} />
              <Box
                ml={4}
                display={{
                  base: 'block',
                  md: 'none'
                }}
              >
                Mesajları Bul
              </Box>
            </Button>
          </Box>
        </Flex>

        <Table>
          <Thead
            display={{
              base: 'none',
              md: 'table-header-group'
            }}
          >
            <Tr>
              <Th>Sıra</Th>
              <Th>Tarih</Th>
              <Th>Mağaza</Th>
              <Th maxW={'lg'}>Mesaj</Th>
              <Th>Okundu</Th>
              <Th>Gönderildi</Th>
              <Th>Gönderilme Tarihi</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.storeMessagesReport.map((message, index) => (
              <Tr key={message.id}>
                <Td
                  display={{
                    base: 'table-cell',
                    md: 'none'
                  }}
                >
                  <Box>
                    <Box mb={2} textAlign="center">
                      {dayjs(message.createdAt).format('YYYY-MM-DD')}
                    </Box>
                    <Box fontSize={'lg'} textAlign="center" mb={4}>
                      {message.title}
                    </Box>
                    <Box fontSize={'lg'} textAlign="center" mb={4}>
                      {message.description}
                    </Box>
                    <Box>
                      <Flex justifyContent={'center'}>
                        <Box mr={2}>
                          {message.isRead ? (
                            <Button
                              bg={'green.500'}
                              color="white"
                              _hover={{ bg: 'green.600' }}
                            >
                              <Icon fontSize={'xl'} as={BiBadgeCheck} />
                            </Button>
                          ) : (
                            <Button
                              bg={'red.500'}
                              _hover={{ bg: 'red.600' }}
                              onClick={(e: any) => onIsRead(message.id)}
                              color="white"
                            >
                              <Icon fontSize={'xl'} as={BiBadge} />
                            </Button>
                          )}
                        </Box>

                        <Box ml={2}>
                          {message.isSend ? (
                            <Button
                              bg={'green.500'}
                              color="white"
                              _hover={{ bg: 'green.600' }}
                            >
                              <Icon fontSize={'xl'} as={BiSend} />
                            </Button>
                          ) : (
                            <StorageModal
                              id={message.id}
                              icon
                              text="Mağazaya"
                              onSubmit={onIsSend}
                            />
                          )}
                        </Box>
                      </Flex>
                    </Box>
                    <Box mt={6} textAlign="center">
                      {message.isSend ? (
                        <Text color={'green.600'} fontWeight="bold">
                          {dayjs(message.updatedAt).format('DD-MMM-YYYY HH:mm')}
                        </Text>
                      ) : (
                        <Text color={'orange.600'} fontWeight="bold">
                          Henüz Gönderilmedi
                        </Text>
                      )}
                    </Box>
                  </Box>
                </Td>
                <Td
                  display={{
                    base: 'none',
                    md: 'table-cell'
                  }}
                >
                  {index + 1}
                </Td>
                <Td
                  display={{
                    base: 'none',
                    md: 'table-cell'
                  }}
                >
                  {dayjs(message.createdAt).format('YYYY-MM-DD')}
                </Td>
                <Td
                  display={{
                    base: 'none',
                    md: 'table-cell'
                  }}
                >
                  {message.title}
                </Td>
                <Td
                  display={{
                    base: 'none',
                    md: 'table-cell'
                  }}
                  maxW={'lg'}
                >
                  {message.description}
                </Td>
                <Td
                  display={{
                    base: 'none',
                    md: 'table-cell'
                  }}
                >
                  {message.isRead ? (
                    <Button
                      bg={'green.500'}
                      color="white"
                      _hover={{ bg: 'green.600' }}
                    >
                      <Icon fontSize={'xl'} as={BiBadgeCheck} />
                    </Button>
                  ) : (
                    <Button
                      bg={'red.500'}
                      _hover={{ bg: 'red.600' }}
                      onClick={(e: any) => onIsRead(message.id)}
                      color="white"
                    >
                      <Icon fontSize={'xl'} as={BiBadge} />
                    </Button>
                  )}
                </Td>
                <Td
                  display={{
                    base: 'none',
                    md: 'table-cell'
                  }}
                  ml={8}
                >
                  {message.isSend ? (
                    <Button
                      bg={'green.500'}
                      color="white"
                      _hover={{ bg: 'green.600' }}
                    >
                      <Icon fontSize={'xl'} as={BiSend} />
                    </Button>
                  ) : (
                    <StorageModal
                      id={message.id}
                      icon
                      text="Mağazaya"
                      onSubmit={onIsSend}
                    />
                  )}
                </Td>
                <Td
                  display={{
                    base: 'none',
                    md: 'table-cell'
                  }}
                >
                  {message.isSend ? (
                    <Text color={'green.600'} fontWeight="bold">
                      {dayjs(message.updatedAt).format('DD-MMM-YYYY HH:mm')}
                    </Text>
                  ) : (
                    <Text color={'orange.600'} fontWeight="bold">
                      Henüz Gönderilmedi
                    </Text>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Container>
    </Layout>
  );
};
