import React from 'react';
import {
  Box,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { useRemoveFromOrderMutation } from './../../generated/graphql';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { FaTrash } from 'react-icons/fa';
dayjs.extend(isSameOrAfter);

interface Props {
  createdAt: string;
  orderId: string;
  id: string;
  sku: string;
  refetch: any;
}

const DeliveryDetailModal: React.FC<Props> = ({
  createdAt,
  id,
  orderId,
  refetch,
  sku
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [removeFromOrder] = useRemoveFromOrderMutation();
  const toast = useToast();

  const handleRemoveFromOrder = async () => {
    try {
      await removeFromOrder({
        variables: {
          orderId,
          removeFromOrderId: id
        }
      });

      await refetch();

      toast({
        title: 'Başarılı',
        description: 'Ürün sevk listesinden silindi',
        status: 'success',
        isClosable: true
      });
      return onClose();
    } catch (error) {
      return toast({
        title: 'Hata',
        description: 'Bir hata oluştu',
        status: 'error',
        isClosable: true
      });
    }
  };
  if (dayjs(createdAt).add(7, 'days').isSameOrAfter(dayjs())) {
    return (
      <Box>
        <Box
          p={2}
          bgColor="red.500"
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          onClick={onOpen}
        >
          <Icon as={FaTrash} color="white" />
        </Box>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Sevk Silme Onayı</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {sku} kodlu ürün sevkten silinerek depoya tekrar eklenecektir.
              Onaylıyor musunuz?
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Vazgeç
              </Button>
              <Button
                onClick={() => handleRemoveFromOrder()}
                variant="outline"
                colorScheme={'red'}
              >
                Sil
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  }
  return <Box>-</Box>;
};

export default DeliveryDetailModal;
