import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  useProductionsQuery,
  IsShippedType,
  usePrintBarcodeMutation,
  usePrintCanadaBarcodeMutation,
  usePrintUsdRetailBarcodeMutation,
  usePrintWholeSaleBarcodeMutation,
  usePrintWholeSaleUsBarcodeMutation,
  PriceType,
  useStorageProductionsQuery
} from '../../generated/graphql';
import { BiBarcode } from 'react-icons/bi';
import dayjs from 'dayjs';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Stack,
  useToast,
  Box,
  Spinner,
  Input
} from '@chakra-ui/react';
import RenderMaterials from '../../utils/renderMaterials';
import Heading from '../ui/Heading';
import Pagination from '../ui/Pagination';

export const Storage = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sku, setSku] = useState('');
  const defaultPageSize = 50;
  const skip = (page - 1) * defaultPageSize;
  const toast = useToast();
  let where = {};
  const { data, loading, error, refetch } = useStorageProductionsQuery({
    variables: {
      isShipped: IsShippedType.Storage,
      first: defaultPageSize,
      skip,
      order: 'updatedAt_DESC',
      ...where
    }
  });

  useEffect(() => {
    where = {
      sku: sku
    };
  }, [sku]);
  useEffect(() => {
    if (!loading && data) {
      setTotalPages(
        Math.ceil(data.storageProductions.productionCount / defaultPageSize)
      );
    }
  }, [data, loading]);
  const [printBarcode] = usePrintBarcodeMutation();
  const [printCanadaBarcode] = usePrintCanadaBarcodeMutation();
  const [printUsdRetailBarcode] = usePrintUsdRetailBarcodeMutation();
  const [printWholeSaleBarcode] = usePrintWholeSaleBarcodeMutation();
  const [printWholeSaleUsBarcode] = usePrintWholeSaleUsBarcodeMutation();
  useEffect(() => {
    refetch();
  }, [refetch]);

  if (error) return <div>Error</div>;

  async function onRetailPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      const defaultPrice = price.price + sizePrices.price;
      console.log('🌵💜🐢', sku, price);
      const res = await printBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesalePrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesale
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesale
      );
      const defaultPrice = price.price + sizePrices.price;
      console.log('🌵💜🐢', sku, price);
      const res = await printWholeSaleBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printWholeSaleBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesaleUsPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesaleus
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesaleus
      );
      let defaultPrice = price.price;
      if (sizePrices) {
        defaultPrice = price.price + sizePrices.price;
      }
      const res = await printWholeSaleUsBarcode({
        variables: {
          sku: sku,
          price: `${Math.ceil(Number(defaultPrice) * 1.1)} ${
            price.currency.code
          }`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      console.log('🌵💜🐢', res);

      if (res.data?.printUsdBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onRetailUsPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesaleus
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesaleus
      );
      let defaultPrice = price.price;
      if (sizePrices) {
        defaultPrice = price.price + sizePrices.price;
      }
      defaultPrice = defaultPrice * 2.8;
      const res = await printUsdRetailBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printUsdRetailBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesaleCaPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesaleca
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesaleca
      );
      let defaultPrice = price.price;
      if (sizePrices) {
        defaultPrice = price.price + sizePrices.price;
      }
      const res = await printCanadaBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printCanadaBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        <Heading title="Depo" />
        <Box>
          <Input
            w={'35%'}
            placeholder="Product SKU"
            value={sku}
            onChange={(e) => setSku(e.target.value.toUpperCase())}
          />
        </Box>
        <Table my="4">
          <Thead
            display={{
              base: 'none',
              md: 'table-header-group'
            }}
          >
            <Tr>
              <Th>Sıra</Th>
              <Th>Üretim Tarihi</Th>
              <Th>Resim</Th>
              <Th>İsim</Th>
              <Th>SKU</Th>
              <Th>Barkod</Th>
              <Th>Seçenekler</Th>
              <Th>Üreten Kişi</Th>
              <Th>İşlemler</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={7}>
                  <Spinner mx="auto" />
                </Td>
              </Tr>
            ) : (
              data?.storageProductions.productions.map((production, index) => (
                <Tr key={production.id}>
                  <Td
                    colSpan={8}
                    display={{
                      base: 'table-cell',
                      md: 'none'
                    }}
                    textAlign="center"
                  >
                    <Box bgColor="gray.50" mb={4} p={4}>
                      <Box
                        border="1px solid"
                        borderColor={'gray.300'}
                        rounded="md"
                        overflow="hidden"
                        mb={4}
                      >
                        <img
                          src={`${process.env.REACT_APP_CDNURL}/${production.product.productGroup.photos[0]}?width=600&height=600&fit=contain&trim=20&extend=20&bg=ffffff`}
                          alt={
                            production.product.productGroup?.description[0]
                              .title
                          }
                          width="600"
                          height="600"
                        />
                      </Box>
                      <Box fontSize={'xl'} fontWeight="semibold">
                        {production.product.productGroup?.description[0].title}
                      </Box>
                      <Box>
                        {production.product.productGroup.category.sku +
                          '-' +
                          production.product.productGroup.sku}
                      </Box>
                      <Box>{production.product.barcode}</Box>
                      <Box>
                        <RenderMaterials
                          locale="tr"
                          sku={production.product.sku || ''}
                        />
                      </Box>
                      <Box>
                        {production.user.name + ' ' + production.user.surname}
                      </Box>
                      <Box my={2}>
                        <Popover placement="bottom" isLazy>
                          <PopoverTrigger>
                            <Button width={'full'}>Barkod Bas</Button>
                          </PopoverTrigger>
                          <PopoverContent w="fit-content">
                            <PopoverArrow />
                            <PopoverBody>
                              <Stack>
                                <Button
                                  rightIcon={<BiBarcode />}
                                  onClick={(e: React.MouseEvent) =>
                                    onRetailPrice(production.product)
                                  }
                                >
                                  TL Barkod
                                </Button>
                                <Button
                                  onClick={(e: any) =>
                                    onWholesalePrice(production.product)
                                  }
                                  rightIcon={<BiBarcode />}
                                >
                                  Euro Toptan Barkod
                                </Button>
                                <Button
                                  onClick={(e: any) =>
                                    onWholesaleUsPrice(production.product)
                                  }
                                  rightIcon={<BiBarcode />}
                                >
                                  Dolar Toptan Barkod
                                </Button>
                                <Button
                                  onClick={(e: any) =>
                                    onRetailUsPrice(production.product)
                                  }
                                  rightIcon={<BiBarcode />}
                                >
                                  Dolar Satış Barkod
                                </Button>
                                <Button
                                  onClick={(e: any) =>
                                    onWholesaleCaPrice(production.product)
                                  }
                                  rightIcon={<BiBarcode />}
                                >
                                  Kanada Toptan Barkod
                                </Button>
                              </Stack>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Box>
                    </Box>
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {skip + index + 1}
                  </Td>
                  <Td>
                    {dayjs(production.updatedAt).format('DD/MM/YYYY HH:mm')}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_CDNURL}/${production.product.productGroup.photos[0]}?width=100&height=100&fit=contain&trim=20&extend=20&bg=ffffff`}
                      alt={
                        production.product.productGroup?.description[0].title
                      }
                      width="100"
                      height="100"
                    />
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.product.productGroup?.description[0].title}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.product.productGroup.category.sku +
                      '-' +
                      production.product.productGroup.sku}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.product.barcode}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    <RenderMaterials
                      locale="tr"
                      sku={production.product.sku || ''}
                    />
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.user.name + ' ' + production.user.surname}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    <Box>
                      <Popover placement="bottom" isLazy>
                        <PopoverTrigger>
                          <Button width={'full'}>Barkod Bas</Button>
                        </PopoverTrigger>
                        <PopoverContent w="fit-content">
                          <PopoverArrow />
                          <PopoverBody>
                            <Stack>
                              <Button
                                rightIcon={<BiBarcode />}
                                onClick={(e: React.MouseEvent) =>
                                  onRetailPrice(production.product)
                                }
                              >
                                TL Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onWholesalePrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Euro Toptan Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onWholesaleUsPrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Dolar Toptan Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onRetailUsPrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Dolar Satış Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onWholesaleCaPrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Kanada Toptan Barkod
                              </Button>
                            </Stack>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Container>
      <Box mx="auto" my="8" w="70%">
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Box>
    </Layout>
  );
};
