import React, { useState, useEffect } from 'react';
import { OrderTypeType, useOrdersQuery } from '../../generated/graphql';
import { Layout } from './../Layout';
import { Link } from 'react-router-dom';
import { BsBullseye } from 'react-icons/bs';
import dayjs from 'dayjs';
import formatMoney from '../../utils/formatMoney';
import 'dayjs/locale/tr';
import {
  Box,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  Tag,
  Icon,
  Input
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';
import Pagination from '../ui/Pagination';
import { FaCheck, FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';
dayjs.locale('tr');

export const ShopifyOrders = () => {
  const [data, setData] = useState([]);
  async function getOrders() {
    const res = await axios(`${process.env.REACT_APP_APIURL}shopify-orders`);
    if (res.data) {
      setData(res.data);
    }
  }
  useEffect(() => {
    getOrders();
  }, [data]);

  const renderOrders = () => {
    if (data) {
      return data.map((order: any, i) => {
        let totalPrice = 0;
        let totalPaidPrice = 0;
        let currency = 'TRY';

        return (
          <Tr key={order.id}>
            <Td>
              <Box
                textAlign={'center'}
                color="gray.200"
                fontWeight={'semibold'}
                //  bgColor={background}
                p={2}
              >
                {i + 1}
              </Box>
            </Td>

            <Td>
              {dayjs(order.createdAt).format('DD MMMM YYYY')} <br />
              {dayjs(order.createdAt).format('HH:mm')}
            </Td>
            <Td>
              {order.customer.first_name} {order.customer.last_name}
            </Td>
            <Td>{order.line_items.length} adet</Td>
            <Td width={'200px'} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(
                  order.total_line_items_price,
                  order.presentment_currency
                )}
              </Box>
            </Td>

            <Td width={'200px'} textAlign="right">
              <Box mb={2} pb={1} fontWeight="bold">
                {formatMoney(
                  order.total_line_items_price,
                  order.presentment_currency
                )}
              </Box>
            </Td>
            <Td textAlign="right">
              {formatMoney(
                order.total_discounts ? order.total_discounts : 0,
                order.presentment_currency
              )}
            </Td>
            <Td>{order.financial_status}</Td>
            {/* <Td>{order.orderStatus}</Td> */}
            <Td width="140px">
              <Flex flexDir={'column'} experimental_spaceY={1} width="140px">
                <Box>
                  <Link to={`/shopify-order-detail/${order.id}`}>
                    <Tag
                      textAlign={'center'}
                      justifyContent="center"
                      variant={'solid'}
                      py={2}
                      colorScheme={'blue'}
                      width="100%"
                    >
                      Detay
                    </Tag>
                  </Link>
                </Box>
              </Flex>
            </Td>
          </Tr>
        );
      });
    }
    return (
      <Tr>
        <Td colSpan={8}>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Td>
      </Tr>
    );
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="Siparişler" />
        <Box>
          <Table variant={'striped'} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>

                <Th>Tarih</Th>
                <Th>İsim Soyisim</Th>
                <Th>Ürünler</Th>
                <Th textAlign={'right'}>Toplam</Th>
                <Th textAlign={'right'}>Ödenen</Th>
                <Th textAlign={'right'}>İndirim</Th>
                <Th>Durum</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>{renderOrders()}</Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};
