import React, { useState } from 'react';
import {
  Input,
  Box,
  Container,
  Textarea,
  Button,
  useToast,
  SimpleGrid,
  GridItem,
  FormLabel,
  Select,
  Table,
  Th,
  Tbody,
  Thead,
  Tr,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex
} from '@chakra-ui/react';
import { Layout } from '../Layout';
import axios from 'axios';
const PrintExcel = () => {
  const [excel, setExcel] = useState<any>(null);
  const [excelData, setExcelData] = useState<any>(null);
  const toast = useToast();
  const uploadExcel = async () => {
    if (!excel) {
      toast({
        title: 'Lütfen Excel Dosyası Seçiniz',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      return;
    }
    const formData = new FormData();
    formData.append('file', excel[0]);
    const result = await axios.post(
      `${process.env.REACT_APP_APIURL}excelUpload`,
      formData
    );
    if (result.data) {
      setExcelData(result.data);
      return toast({
        title: 'Excel Yüklendi',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } else {
      return toast({
        title: 'Hata',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };
  const printExcel = async () => {
    if (!excel) {
      toast({
        title: 'Lütfen Excel Dosyası Seçiniz',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      return;
    }
    const formData = new FormData();
    formData.append('file', excel[0]);
    const result = await axios.post(
      `${process.env.REACT_APP_APIURL}excelPrint`,
      formData
    );
    if (result.data === 'OK') {
      return toast({
        title: 'Excel Yazdırılıyor...',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } else {
      return toast({
        title: 'Hata',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };
  return (
    <Layout>
      <Container my={4} maxW={'container.xl'}>
        <Flex justifyContent={'space-between'}>
          <Flex gap={4} alignItems={'center'}>
            <Box>
              <Text>Excel Listenizi Yükleyiniz</Text>
              <form encType="multipart/form-data">
                <Input
                  onChange={(e) => {
                    console.log('🌵💜🐢', e.target);

                    setExcel(e.target.files);
                  }}
                  type="file"
                  bg={'#862337'}
                  color={'white'}
                />
              </form>
            </Box>
            <Button mt={6} onClick={uploadExcel}>
              Yükle
            </Button>
            {excelData && (
              <Button mt={6} onClick={printExcel}>
                Yazdır
              </Button>
            )}
          </Flex>
          <Button mt={6} as={'a'} download href="/BarkodSablon.xlsx">
            Şablon İndir
          </Button>
        </Flex>
        {excelData && (
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th>Tarih</Th>
                <Th>SKU</Th>
                <Th>Barkod</Th>
                <Th>Kategori</Th>
                <Th>Beymen Kod</Th>
                <Th>Fiyat</Th>
                <Th>Adet</Th>
              </Tr>
            </Thead>
            <Tbody>
              {excelData &&
                excelData.map((item: any) => (
                  <Tr>
                    <Td>{item.date}</Td>
                    <Td>{item.sku}</Td>
                    <Td>{item.barcode}</Td>
                    <Td>{item.category}</Td>
                    <Td>{item.beymenCode}</Td>
                    <Td>{item.price}</Td>
                    <Td>{item.qty}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        )}
      </Container>
    </Layout>
  );
};

export default PrintExcel;
