import React, { useState } from 'react';
import { Layout } from '../Layout';
import {
  Container,
  FormControl,
  Input,
  Textarea,
  Button,
  SimpleGrid,
  GridItem,
  Box,
  useToast,
  Text,
  Select
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Heading from '../ui/Heading';
import axios from 'axios';

const CreateCargo = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [note, setNote] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState('tr' || 'en');
  const [town, setTown] = useState('');
  const [payorTpyeCode, setPayorTpyeCode] = useState<number>(1);
  const [integrationId, setIntegrationId] = useState('');
  const [findUsers, setFindUsers] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();

  const createCargo = async () => {
    const userId = localStorage.getItem('userId');
    const result = await axios({
      url: `${process.env.REACT_APP_CARGOAPI}cargo/create`,
      method: 'POST',
      data: {
        username: process.env.REACT_APP_CARGOUSER,
        password: process.env.REACT_APP_CARGOUSERPASS,
        ReceiverName: name,
        ReceiverPhone1: phoneNumber,
        ReceiverAddress: address,
        ReceiverCityName: city,
        ReceiverTownName: town,
        PayorTypeCode: payorTpyeCode,
        employerId: userId,
        customCargo: true,
        orderId: '',
        userId: '',
        email: email,
        language: language,
        note: note
      }
    });
    if (result.data.message[0] === 'Başarılı') {
      toast({
        status: 'success',
        description: 'Kargo barkodu başarıyla oluşturuldu.',
        title: 'Başarılı'
      });
      setIntegrationId(result.data.IntegrationCode);
    } else {
      toast({
        status: 'warning',
        description: 'Kargo Oluşturulmadı',
        title: 'Uyarı'
      });
    }
  };
  const getBarcode = async () => {
    try {
      const result = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/barcode`,
        method: 'POST',
        data: {
          username: process.env.REACT_APP_CARGOUSER,
          password: process.env.REACT_APP_CARGOUSERPASS,
          IntegrationCode: integrationId
        }
      });
      console.log('🌵💜🐢aa', result.data);

      if (
        result.data.message[0] ===
        'Başarılı bir şekilde barkod bilgileriniz oluşturulmuştur.'
      ) {
        toast({
          status: 'success',
          description: 'Kargo barkodu başarıyla yazdırılmıştır.',
          title: 'Başarılı'
        });
        navigate('/cargo-list');
      } else {
        if (result.data.message[0]) {
          toast({
            status: 'warning',
            description: result.data.message[0],
            title: 'Uyarı'
          });
        }
        toast({
          status: 'warning',
          description: 'hata',
          title: 'Uyarı'
        });
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };
  const findUser = async () => {
    try {
      const result = await axios({
        url: `${process.env.REACT_APP_CARGOAPI}cargo/findUser?name=${name}`,
        method: 'get'
      });
      console.log('🌵💜🐢aa', result.data);

      if (result.data) {
        setFindUsers(result.data);
        toast({
          status: 'success',
          description: 'Adresleri Seçebilirsiniz',
          title: 'Başarılı'
        });
      } else {
        toast({
          status: 'warning',
          description: 'Adresler Bulunamadı',
          title: 'Uyarı'
        });
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: error.message,
        title: 'Hata!'
      });
    }
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <Heading title="Özel Kargo Oluşturma Ekranı" />
        <Box>
          {name && (
            <Button type="button" onClick={findUser}>
              Adres Bul
            </Button>
          )}
          <SimpleGrid my={10} columns={2} spacing={10}>
            <GridItem>
              <FormControl id="name" isRequired>
                <Input
                  placeholder="Ad Soyad"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="phoneNumber" isRequired>
                <Input
                  placeholder="Telefon Numarası"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="email" isRequired>
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="language" isRequired>
                <Select
                  placeholder="Language"
                  onChange={(e) => setLanguage(e.target.value)}
                  value={language}
                >
                  <option value="tr">TR</option>
                  <option value="en">EN</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="address" isRequired>
                <Textarea
                  resize={'none'}
                  placeholder="Adres"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="city" isRequired>
                <Input
                  placeholder="Şehir"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="town" isRequired>
                <Input
                  placeholder="İlçe"
                  value={town}
                  onChange={(e) => setTown(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="note">
                <Textarea
                  placeholder="Not"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="payorTpyeCode" isRequired>
                <Button
                  onClick={(e) => setPayorTpyeCode(2)}
                  bg={payorTpyeCode === 2 ? 'green.400' : 'gray.400'}
                  textColor={'white'}
                  mx={2}
                >
                  Alıcı Ödemeli
                </Button>
                <Button
                  onClick={(e) => setPayorTpyeCode(1)}
                  bg={payorTpyeCode === 1 ? 'green.400' : 'gray.400'}
                  textColor={'white'}
                  mx={2}
                >
                  Gönderici Ödemeli
                </Button>
              </FormControl>
            </GridItem>
          </SimpleGrid>
          {findUsers.length > 0 && (
            <SimpleGrid my={10} columns={1} spacing={10}>
              {findUsers.map((item: any) => (
                <GridItem
                  py={2}
                  rounded={'md'}
                  _hover={{
                    cursor: 'pointer',
                    bg: 'gray.100'
                  }}
                  px={4}
                  bg={'ButtonFace'}
                  onClick={(e) => {
                    setName(item.name);
                    setAddress(item.address);
                    setPhoneNumber(item.phoneNumber);
                  }}
                >
                  <Box>
                    <Text>
                      {item.name} - {item.phoneNumber}
                    </Text>{' '}
                  </Box>
                  <Box>
                    <Text>{item.address}</Text>
                  </Box>
                </GridItem>
              ))}
            </SimpleGrid>
          )}
          {!integrationId && (
            <Button onClick={() => createCargo()}>Kargoyu Oluştur</Button>
          )}
          {integrationId && (
            <Button mx={2} onClick={() => getBarcode()}>
              Etiketi Yazdır
            </Button>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default CreateCargo;
