import React, { useEffect } from 'react';
import {
  useOrderReturnQuery,
  useOrderReturnReadMutation
} from '../../generated/graphql';
import { Layout } from './../Layout';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import formatMoney from '../../utils/formatMoney';
import { FcPrint } from 'react-icons/fc';

import RenderMaterials from '../../utils/renderMaterials';

import 'dayjs/locale/tr';

import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Spinner,
  SimpleGrid,
  Button
} from '@chakra-ui/react';
import PageHeading from '../ui/Heading';

dayjs.locale('tr');

export const OrderReturn = () => {
  const params = useParams();

  const [updateReturn] = useOrderReturnReadMutation();

  const { data, loading, error } = useOrderReturnQuery({
    variables: {
      orderReturnId: params.id as string
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function updateReadStatus() {
    await updateReturn({
      variables: {
        orderReturnReadId: params.id as string
      }
    });
  }

  useEffect(() => {
    if (!data?.orderReturn.isRead) {
      updateReadStatus();
    }
  }, [data]);

  const renderOrder = () => {
    if (loading) {
      return (
        <Box>
          <Spinner mx={'auto'} />
        </Box>
      );
    }
    if (error) {
      return <Box>Bir hata oluştu. {error.message}</Box>;
    }
    if (data) {
      return (
        <Box>
          <Box
            my={4}
            display="flex"
            experimental_spaceX={4}
            justifyContent={'flex-end'}
          >
            <Button
              className="inline-flex items-center px-4 py-2 font-semibold text-gray-800 bg-white rounded hover:bg-gray-400"
              onClick={() => {
                window.print();
              }}
            >
              <FcPrint className="text-xl" />
            </Button>
          </Box>
          <Box id="printArea">
            <SimpleGrid
              columns={{
                base: 1
              }}
              gap={4}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    Üye Bilgileri
                  </Box>
                  <Table variant={'striped'}>
                    <Tbody>
                      <Tr>
                        <Th width={'150px'}> İsim Soyisim</Th>
                        <Td>
                          {data.orderReturn.user?.name}{' '}
                          {data.orderReturn.user?.surname}
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>Mail Adresi</Th>
                        <Td>{data.orderReturn.user?.email}</Td>
                      </Tr>
                      <Tr>
                        <Th>Telefon Numarası</Th>
                        <Td>{data.orderReturn.user?.phoneNumber}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>
            <SimpleGrid
              columns={{
                base: 1
              }}
              gap={4}
            >
              <Box mb={4} bgColor="gray.50" rounded="md">
                <Box p={4}>
                  <Box
                    mb={4}
                    fontSize="lg"
                    fontWeight={'semibold'}
                    color="blue.500"
                  >
                    İade Bilgileri
                  </Box>
                  <Table variant={'striped'}>
                    <Tbody>
                      <Tr>
                        <Th width="150px">İade Tarihi</Th>
                        <Td>
                          {dayjs(data.orderReturn.createdAt).format(
                            'DD MMMM YYYY HH:mm:ss'
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>Durumu</Th>
                        <Td>{data.orderReturn.status}</Td>
                      </Tr>
                      <Tr>
                        <Th>İade Bilgileri</Th>
                        <Td>{data.orderReturn.reason}</Td>
                      </Tr>
                      <Tr>
                        <Th>İade Notu</Th>
                        <Td>{data.orderReturn.message}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>
            </SimpleGrid>

            <Box mb={4} bgColor="gray.50" rounded="md">
              <Box p={4}>
                <Box
                  mb={4}
                  fontSize="lg"
                  fontWeight={'semibold'}
                  color="blue.500"
                >
                  Ürün
                </Box>
                <Table variant={'striped'}>
                  <Thead>
                    <Tr>
                      <Th className="w-40 px-5 py-2 text-center bg-default-600">
                        Fotoğraf
                      </Th>
                      <Th className="px-5 py-2 whitespace-nowrap bg-default-600">
                        SKU
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Ürün</Th>
                      <Th className="px-5 py-2 text-center w-42 bg-default-600">
                        Beden
                      </Th>
                      <Th className="px-5 py-2 bg-default-600">Seçenekler</Th>
                      <Th className="px-5 py-2 bg-default-600">Fiyatı</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <img
                          src={`https://images.jujumood.com/${data.orderReturn.productOrder.product.productGroup.photos[0]}?format=webp&width=100&height=100`}
                          alt=""
                        />
                      </Td>
                      <Td>
                        {
                          data.orderReturn.productOrder.product.sku?.split(
                            '-'
                          )[0]
                        }
                        -
                        {
                          data.orderReturn.productOrder.product.sku?.split(
                            '-'
                          )[1]
                        }
                      </Td>
                      <Td>
                        {
                          data.orderReturn.productOrder.product.productGroup
                            .description[0].title
                        }
                      </Td>
                      <Td>
                        {
                          data.orderReturn.productOrder.product.sku?.split(
                            '-'
                          )[2]
                        }
                      </Td>
                      <Td>
                        <RenderMaterials
                          locale="tr"
                          sku={
                            data.orderReturn.productOrder.product.sku as string
                          }
                        />
                      </Td>
                      <Td>
                        {formatMoney(
                          data.orderReturn.productOrder.price.price,
                          data.orderReturn.productOrder.price.currency.code
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    return <Box>Veritabanına bağlanmakta bir sorun yaşıyoruz.</Box>;
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading title="İade Detayı" />
        <Box>{renderOrder()}</Box>
      </Container>
    </Layout>
  );
};
