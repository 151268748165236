import React, { useState } from 'react';
import { Layout } from '../Layout';
import {
  Input,
  Button,
  Box,
  Flex,
  Container,
  useToast,
  Spinner
} from '@chakra-ui/react';
import Heading from '../ui/Heading';
import {
  useStoresWithTitleQuery,
  useProductInfoQuery,
  useCreateCustomStockMutation
} from '../../generated/graphql';
import Select from 'react-select';
import RenderMaterials from '../../utils/renderMaterials';

const AddStock = () => {
  const [barcode, setBarcode] = useState('');
  const [store, setStore] = useState('');
  const [tempBarcode, setTempBarcode] = useState('');
  const [createCustomStock, { loading: createCustomStockLoading }] =
    useCreateCustomStockMutation();

  const toast = useToast();

  const { data, loading, error } = useProductInfoQuery({
    variables: {
      barcode
    }
  });

  const { data: storeData, loading: storeLoading } = useStoresWithTitleQuery();

  if (error) return <div>Error</div>;

  const onTransfer = async () => {
    if (!store) {
      return toast({
        status: 'error',
        description: 'Mağaza seçimi gereklidir',
        isClosable: true,
        title: 'Hata'
      });
    }
    try {
      await createCustomStock({
        variables: {
          barcode,
          storeId: store
        }
      });
      setTempBarcode('');
      setBarcode('');
      setStore('');
      toast({
        status: 'success',
        description: 'Ürün başarıyla stoklara eklendi',
        isClosable: true,
        title: 'Başarılı'
      });
    } catch (error) {
      console.log(error);
      toast({
        status: 'error',
        description: 'Bir hata oluştu',
        isClosable: true,
        title: 'Hata'
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (tempBarcode.length === 12) {
      setBarcode(tempBarcode);
    }
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <Heading title="Stok Ekle" />
        <form onSubmit={handleSubmit}>
          <Flex
            my={4}
            mx={'auto'}
            justify="center"
            align="center"
            w={{
              base: '100%',
              md: '50%'
            }}
          >
            <Input
              placeholder="Barkod"
              type="number"
              value={tempBarcode}
              onChange={(e: any) => setTempBarcode(e.target.value)}
            />
            <Button mx={4} type="submit">
              ARA
            </Button>
          </Flex>
        </form>
      </Container>

      <Container maxW={'container.lg'}>
        {loading ? (
          <Spinner />
        ) : (
          <Box my={8}>
            {data?.productInfo[0] && (
              <Box
                border="1px solid"
                borderColor={'gray.100'}
                my="4"
                rounded={4}
              >
                <Flex
                  direction={{
                    base: 'column',
                    md: 'row'
                  }}
                >
                  <Box mb={6}>
                    <img
                      src={`${process.env.REACT_APP_CDNURL}/${data?.productInfo[0]?.productGroup?.photos[0]}?trim=30&extend=20&format=webp&quality=50&width=700&height=700&bg=ffffff&fit=contain`}
                      alt=""
                      width={500}
                      height={500}
                    />
                  </Box>
                  <Box
                    width={'100%'}
                    mt={{
                      base: 6,
                      md: 0
                    }}
                    bgColor="gray.50"
                    rounded="sm"
                    p={4}
                    textAlign={{
                      base: 'center',
                      md: 'left'
                    }}
                  >
                    <Box
                      fontWeight={'bold'}
                      textColor={'black'}
                      fontSize={{
                        base: '2xl',
                        md: '3xl'
                      }}
                    >
                      {data?.productInfo[0]?.productGroup?.description[0].title}
                    </Box>
                    <Box
                      fontSize={{
                        base: 'lg',
                        md: 'lg'
                      }}
                      color="gray.500"
                    >
                      {data?.productInfo[0]?.productGroup?.category.sku}-
                      {data?.productInfo[0]?.productGroup?.sku}
                    </Box>

                    <Box my={2}>
                      <Box fontSize={'lg'} fontWeight={'bold'}>
                        Beden
                      </Box>
                      <Box>{data?.productInfo[0]?.sku?.split('-')[2]}</Box>
                    </Box>
                    <Box my={2}>
                      <Box fontSize={'lg'} fontWeight={'bold'}>
                        Seçenekler
                      </Box>
                      <RenderMaterials
                        locale="tr"
                        sku={data?.productInfo[0]?.sku || ''}
                      />
                    </Box>

                    {storeLoading ? (
                      <Spinner />
                    ) : (
                      <Box mb={4}>
                        <Select
                          onChange={(e) => setStore(e!.value)}
                          options={storeData?.stores.map((s) => ({
                            value: s.id,
                            label: s.title
                          }))}
                        />
                      </Box>
                    )}
                    <Box
                      textAlign={{
                        base: 'center',
                        md: 'right'
                      }}
                    >
                      <Button
                        colorScheme={'green'}
                        onClick={() => onTransfer()}
                      >
                        <Box fontSize={'lg'} fontWeight={'bold'}>
                          Stok Ekle
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default AddStock;
