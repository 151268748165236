import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button
} from '@chakra-ui/react';
import { BiTrashAlt } from 'react-icons/bi';

export const DeleteModal = ({
  id,
  onDelete,
  button
}: {
  id: string;
  onDelete: (id: string) => void;
  button?: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      {!button ? (
        <Button
          w={'full'}
          bg={'red.500'}
          color="white"
          rightIcon={<BiTrashAlt />}
          onClick={onOpen}
        >
          Ürünü Sil
        </Button>
      ) : (
        <Button
          _hover={{ bg: 'red.600' }}
          w={'full'}
          bg={'red.500'}
          color="white"
          onClick={onOpen}
        >
          <BiTrashAlt />
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody fontWeight={'bold'}>
            Ürünü Silmek İstediğinize Emin misiniz?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={(e: React.MouseEvent) => onDelete(id)}
              bg={'red.500'}
              color="white"
            >
              Sil
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
