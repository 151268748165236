import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button,
  Select,
  Icon
} from '@chakra-ui/react';
import { AiFillPrinter } from 'react-icons/ai';
export const PrinterModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [printer, setPrinter] = React.useState<string>(
    localStorage.getItem('printer') || 'ZEBRA01'
  );

  return (
    <Box>
      <Button
        bg={
          printer === 'ZEBRA01'
            ? 'blue.400'
            : printer === 'ARGOX01'
            ? 'orange.400'
            : 'green.400'
        }
        onClick={onOpen}
        mx={2}
        _hover={{
          bg:
            printer === 'ZEBRA01'
              ? 'blue.500'
              : printer === 'ARGOX01'
              ? 'orange.500'
              : 'green.500'
        }}
      >
        <Icon fontSize="17px" aria-label="messages" as={AiFillPrinter} mt={1} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Varsayılan Yazıcı Seçimi</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Yazıcı Seçiniz"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setPrinter(e.target.value);
              }}
              value={printer}
            >
              <option value="ZEBRA01">ZEBRA-01</option>
              <option value="ARGOX01">ARGOX-01</option>
              <option value="ARGOX02">ARGOX-02</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={(e: React.MouseEvent) => {
                localStorage.setItem('printer', printer);
                onClose();
              }}
              bg={'green.500'}
              color="white"
            >
              Onayla
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
