import React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  BrowserRouter as Router
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import client from '../lib/index';
import { chakraTheme } from '../theme';

import { Login } from '../components/page/Login';
import { Dashboard } from '../components/page/Dashboard';
import { Storage } from '../components/page/Storage';
import { Production } from '../components/page/Production';
import { Progress } from '../components/page/Progress';
import { SendStorage } from '../components/page/SendStorage';
import { CreateDelivery } from '../components/page/CreateDelivery';
import { DeliveryList } from '../components/page/DeliveryList';
import { DeliveryDetail } from '../components/page/DeliveryDetail';
import { StoreMessage } from '../components/page/StoreMessage';
import { PrintBarcode } from '../components/page/PrintBarcode';
import { Orders } from '../components/page/Orders';
import { OrderDetail } from '../components/page/OrderDetail';
import AddStock from '../components/page/AddStock';
import { ProductGroups } from '../components/page/ProductGroups';
import { ProductGroupDetail } from '../components/page/ProductGroupDetail';
import ProductGroupList from '../components/page/ProductList';
import AllStocks from '../components/stock/All';
import ProductGroup from '../components/stock/ProductGroup';
import Sku from '../components/stock/Sku';
import Detail from '../components/stock/Detail';
import AllCargo from '../components/page/AllCargo';
import CreateCargo from '../components/page/CreateCargo';
import ProductRequests from '../components/page/ProductRequest';
import ProductRequestDetail from '../components/page/ProductRequestDetail';
import { OrderReturns } from '../components/page/OrderReturns';
import { OrderReturn } from '../components/page/OrderReturn';
import CustomBaskets from '../components/page/CustomBaskets';
import Inventory from '../components/page/Inventory';
import StoreStock from '../components/page/StoreStock';
import { InventoryDetail } from '../components/page/InventoryDetail';
import StoreCounts from '../components/page/StoreCounts';
import { StartedProduction } from '../components/page/StartedProduction';
import { ProductionReports } from '../components/page/ProductionReports';
import { ShopifyOrders } from '../components/page/ShopifyOrders';
import { ShopifyOrderDetail } from '../components/page/ShopifyOrderDetail';
import { DelayedOrders } from '../components/page/DelayedOrders';
import { OrderPrintPage } from '../components/page/OrderPrintPage';
import { MarketplaceOrders } from '../components/page/MarketplaceOrders';
import { MarketplaceOrder } from '../components/page/MarketPlaceOrderDetail';
import PrintExcel from '../components/page/PrintExcel';

function RequireAuth() {
  const token = localStorage.getItem('token');
  const location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return <Outlet />;
}

const AppRouter = () => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/custom-baskets" element={<CustomBaskets />} />

            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/storage" element={<Storage />} />
              <Route path="/new-production" element={<Production />} />
              <Route path="/pending-products" element={<Progress />} />
              <Route path="/send-to-storage" element={<SendStorage />} />
              <Route path="/create-delivery" element={<CreateDelivery />} />
              <Route path="/delivery-list" element={<DeliveryList />} />
              <Route path="/delivery-detail/:id" element={<DeliveryDetail />} />
              <Route path="/store-messages" element={<StoreMessage />} />
              <Route path="/print-barcode" element={<PrintBarcode />} />
              <Route path="/order-list" element={<Orders />} />
              <Route path="/order-detail/:id" element={<OrderDetail />} />
              <Route path="/add-stock" element={<AddStock />} />
              <Route path="/product-groups" element={<ProductGroups />} />
              <Route path="/cargo-list" element={<AllCargo />} />
              <Route path="/create-cargo" element={<CreateCargo />} />
              <Route path="/market-orders" element={<MarketplaceOrders />} />
              <Route path="/market-order/:id" element={<MarketplaceOrder />} />
              <Route path="/order-print/:id" element={<OrderPrintPage />} />
              <Route
                path="/stok/:categoryId/:productGroupId/:sku"
                element={<Detail />}
              />
              <Route path="/stok" element={<AllStocks />} />
              <Route path="/stok/:productGroupId" element={<ProductGroup />} />
              <Route
                path="/product-group-list"
                element={<ProductGroupList />}
              />
              <Route path="/inventory" element={<Inventory />} />
              <Route
                path="/stok/:categoryId/:productGroupId"
                element={<Sku />}
              />
              <Route
                path="/product-group/detail/:id"
                element={<ProductGroupDetail />}
              />
              <Route
                path="/product-request/list"
                element={<ProductRequests />}
              />
              <Route
                path="/product-request/:id"
                element={<ProductRequestDetail />}
              />
              <Route path="/order-returns" element={<OrderReturns />} />
              <Route path="/order-return/:id" element={<OrderReturn />} />
              <Route path="/store-stock" element={<StoreStock />} />
              <Route path="/stock-count/:id" element={<InventoryDetail />} />
              <Route path="/counts" element={<StoreCounts />} />
              <Route path="/production" element={<StartedProduction />} />
              <Route path="/shopify-orders" element={<ShopifyOrders />} />
              <Route
                path="/shopify-order-detail/:id"
                element={<ShopifyOrderDetail />}
              />

              <Route
                path="/production-reports"
                element={<ProductionReports />}
              />
              <Route path="/beymen-print" element={<PrintExcel />} />
              <Route path="/delayed-orders" element={<DelayedOrders />} />
            </Route>
          </Routes>
        </Router>
      </ApolloProvider>
    </ChakraProvider>
  );
};

export default AppRouter;
