import { useEffect, useState } from "react";
import { Layout } from "./../Layout";
import { useParams, Link } from "react-router-dom";
import { useSearchQuery } from "../../utils/useQuery";
import formatMoney from "../../utils/formatMoney";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import axios from "axios";

import RenderMaterials from "../../utils/renderMaterials";
import "dayjs/locale/tr";

import {
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Container,
  Select,
  Spinner,
  FormControl
} from "@chakra-ui/react";
import PageHeading from "../ui/Heading";

dayjs.locale("tr");

interface StockItem {
  id: string;
  category: string;
  productGroup: string;
  size: string;
  sku: string;
  barcode: string;
  amount: number;
  price: number;
  currency: string;
  orderId?: string;
  description?: string;
  covertedPrice: number;
  store: string;
  createdAt: string;
  updatedAt: string;
}
const Dashboard = () => {
  const params = useParams();
  const query = useSearchQuery();

  const [storeId, setStoreId] = useState<string | null | undefined>(
    query.get("storeId")
  );
  const { isLoading: storesLoading, data: storesData } = useQuery(
    "query-get-stores",
    async () => {
      return await axios({
        method: "GET",
        url: `${process.env.REACT_APP_APIURL}external/stores?key=${process.env.REACT_APP_EXTERNALKEY}`
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  const { isLoading, data, refetch } = useQuery(
    "query-get-stocks-by-productGroup",
    async () => {
      const url = `${process.env.REACT_APP_STOCKURL}/stock/bySku?productGroupId=${params.productGroupId}&storeId=${storeId}&categoryId=${params.categoryId}&sku=${params.sku}`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  useEffect(() => {
    refetch();
  }, [storeId, refetch]);
  return (
    <Layout>
      <Container maxW="container.xl">
        <PageHeading
          title={`${params.sku ? params.sku : ""} Stok Hareketleri`}
        />
        <Box mb={4}>
          <FormControl mb={2}>Mağaza Seçimi</FormControl>
          {storesLoading ? (
            <Spinner />
          ) : (
            <Select
              onChange={(e) => {
                setStoreId(e.target.value);
              }}
              value={storeId ? storeId : ""}
              name="storeSelect"
              colorScheme={"whiteAlpha"}
            >
              <option value={""}>Tüm Mağazalar</option>
              {storesData?.data.map((store: { id: string; title: string }) => {
                return (
                  <option key={store.id} value={store.id}>
                    {store.title}
                  </option>
                );
              })}
            </Select>
          )}
        </Box>
        <Box overflowX={"auto"}>
          <Table variant={"striped"} className="w-full table-auto">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Tarih</Th>
                <Th>Açıklama </Th>
                <Th>Mağaza</Th>
                <Th>Fiyat</Th>
                <Th>Kur</Th>
                <Th>TL Fiyat</Th>
                <Th textAlign={"center"}>-</Th>
                <Th textAlign={"center"}>+</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={3}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : (
                data?.data.data.stockData.map((item: StockItem, i: number) => {
                  let title = "";
                  const store = storesData?.data.find(
                    (s: { id: string; title: string }) => s.id === item.store
                  );
                  if (store) {
                    title = store.title;
                  }
                  return (
                    <Tr key={i}>
                      <Td width="50px">{i + 1}</Td>
                      <Td>
                        {dayjs(item.createdAt).format("DD MMM YYYY - HH:mm")}
                      </Td>
                      <Td>{item.description}</Td>
                      <Td>
                        {title ? (
                          <Link to={`/stok?storeId=${item.store}`}>
                            <Box textDecor={"underline"} color="blue.500">
                              {title}
                            </Box>
                          </Link>
                        ) : (
                          "-"
                        )}
                      </Td>
                      <Td>{formatMoney(item.price, item.currency)}</Td>
                      <Td>{item.currency}</Td>
                      <Td>{formatMoney(item.covertedPrice, "TRY")}</Td>
                      <Td>
                        {item.amount < 0 && (
                          <Box
                            fontWeight={"bold"}
                            textAlign={"center"}
                            background={
                              item.amount < 0 ? "red.100" : "transparent"
                            }
                            p={2}
                          >
                            {item.amount < 0 && item.amount}
                          </Box>
                        )}
                      </Td>
                      <Td>
                        {item.amount > 0 && (
                          <Box
                            fontWeight={"bold"}
                            textAlign={"center"}
                            background={
                              item.amount > 0 ? "green.100" : "transparent"
                            }
                            p={2}
                          >
                            {item.amount > 0 && item.amount}
                          </Box>
                        )}
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Layout>
  );
};

export default Dashboard;
