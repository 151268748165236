import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  Button,
  Box,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Stack,
  Flex,
  Input,
  Icon,
  Spinner,
  Text
} from '@chakra-ui/react';
import RenderMaterials from '../../utils/renderMaterials';
import Heading from '../ui/Heading';
import Zoom from 'react-medium-image-zoom';

import {
  useProductionsQuery,
  useDeleteTransferProductMutation,
  IsShippedType,
  usePrintNoPriceBarcodeMutation,
  useUpdateTransferProductMutation,
  usePrintBarcodeMutation,
  usePrintCanadaBarcodeMutation,
  usePrintUsdRetailBarcodeMutation,
  usePrintWholeSaleBarcodeMutation,
  usePrintWholeSaleUsBarcodeMutation,
  useUserQuery,
  usePrintGoldBarcodeMutation,
  UserType,
  useProductGroupQuery,
  PriceType,
  useDeleteProductionMutation,
  usePrintEurBarcodeWithBarcodeMutation
} from '../../generated/graphql';
import { FcClearFilters, FcFilledFilter } from 'react-icons/fc';
import { BiBarcode } from 'react-icons/bi';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { DeleteModal } from '../ui/DeleteModal';
import { StorageModal } from '../ui/StorageModal';
import Pagination from '../ui/Pagination';
import Select from 'react-select';

export const Progress = () => {
  const [page, setPage] = useState(1);
  const defaultPageSize = 20;
  const skip = (page - 1) * defaultPageSize;
  const toast = useToast();
  const [totalPages, setTotalPages] = useState(0);
  const [userId, setUserId] = useState({ label: '', value: '' });
  const [sku, setSku] = useState('');

  const [tempSku, setTempSku] = useState('');
  const { data, loading, error, refetch } = useProductionsQuery({
    variables: {
      isShipped: IsShippedType.Progress,
      first: defaultPageSize,
      skip,
      userId: userId.value ? userId.value : null,
      sku: sku ? sku : null
    }
  });
  const { data: users, loading: userLoad } = useUserQuery({
    variables: {
      userType: UserType.Production,
      first: 1000,
      skip: 0
    }
  });
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!loading && data) {
      setTotalPages(
        Math.ceil(data.productions.productionCount / defaultPageSize)
      );
    }
  }, [data, loading]);
  const [deleteTransferProduct] = useDeleteTransferProductMutation();
  const [updateTransferProduct] = useUpdateTransferProductMutation();
  const [printBarcode] = usePrintBarcodeMutation();
  const [printCanadaBarcode] = usePrintCanadaBarcodeMutation();
  const [printEurBarcode] = usePrintEurBarcodeWithBarcodeMutation();
  const [printUsdRetailBarcode] = usePrintUsdRetailBarcodeMutation();
  const [printWholeSaleBarcode] = usePrintWholeSaleBarcodeMutation();
  const [printWholeSaleUsBarcode] = usePrintWholeSaleUsBarcodeMutation();
  const [printGoldBarcode] = usePrintGoldBarcodeMutation();
  const [noPrice] = usePrintNoPriceBarcodeMutation();
  const [deleteMutation] = useDeleteProductionMutation();

  if (error) return <div>Error</div>;

  async function onDelete(id: string) {
    const deleteRes = await deleteMutation({
      variables: { id: id }
    });
    if (deleteRes.data?.deleteProduction.id) {
      refetch();
      toast({
        title: 'Success',
        description: 'Ürün Silindi',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    }
  }

  async function onTransfer(id: string) {
    const transferRes = await updateTransferProduct({
      variables: {
        updateTransferProductId: id,
        isShipped: IsShippedType.Storage
      }
    });
    if (transferRes.data?.updateTransferProduct.id) {
      refetch();
      toast({
        title: 'Success',
        description: 'Ürün Depoya Eklendi',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      // playAlert('submarine');
    }
  }

  async function onNoPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const res = await noPrice({
        variables: {
          sku: sku,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printNoPriceBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onRetailPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      const defaultPrice = price.price + sizePrices.price;
      console.log('🌵💜🐢', sku, price);
      const res = await printBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesalePrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesale
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesale
      );
      const defaultPrice = price.price + sizePrices.price;
      console.log('🌵💜🐢', sku, price);
      const res = await printWholeSaleBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printWholeSaleBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesaleUsPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesale
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesale
      );
      let defaultPrice = price.price;
      if (sizePrices) {
        defaultPrice = price.price + sizePrices.price;
      }
      const res = await printWholeSaleUsBarcode({
        variables: {
          sku: sku,
          price: `${Math.ceil(Number(defaultPrice) * 1.1)} ${
            price.currency.code
          }`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      console.log('🌵💜🐢', res);

      if (res.data?.printUsdBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onRetailUsPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesaleus
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesaleus
      );
      let defaultPrice = price.price;
      if (sizePrices) {
        defaultPrice = price.price + sizePrices.price;
      }
      defaultPrice = defaultPrice * 2.4;
      const res = await printUsdRetailBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printUsdRetailBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  async function onRetailEurPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesaleus
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesaleus
      );
      let defaultPrice = price.price;
      if (sizePrices) {
        defaultPrice = price.price + sizePrices.price;
      }
      defaultPrice = defaultPrice * 2.4;
      const res = await printEurBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printEurBarcodeWithBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onWholesaleCaPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Wholesaleca
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Wholesaleca
      );
      let defaultPrice = price.price;
      if (sizePrices) {
        defaultPrice = price.price + sizePrices.price;
      }
      const res = await printCanadaBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printCanadaBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }
  async function onGoldPrice(product: any) {
    try {
      const sku = product.sku;

      const size = sku.split('-')[2];
      const sizePrice = product.productGroup.sizePrices.find(
        (s: any) => s.size.sku === size
      );
      console.log('🌵💜🐢', sizePrice);

      const sizePrices = sizePrice.prices.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let price = product.productGroup.price.find(
        (p: any) => p.priceType === PriceType.Retail
      );
      let defaultPrice = price.price;
      if (sizePrices) {
        defaultPrice = price.price + sizePrices.price;
      }
      const res = await printGoldBarcode({
        variables: {
          sku: sku,
          price: `${defaultPrice} ${price.currency.code}`,
          priceType: PriceType.Retail,
          printer: localStorage.getItem('printer')
            ? localStorage.getItem('printer')
            : 'ARGOX01'
        }
      });
      if (res.data?.printGoldBarcode?.result) {
        toast({
          title: 'Success',
          description: 'Ürün Barkod Yazdırıldı',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Ürün Barkod Yazdırılamadı',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  return (
    <Layout>
      {/* <audio src={as} controls>
        a
      </audio> */}
      <Container maxW="8xl">
        <Heading title="Depoya Gitmeyi Bekleyenler" />
        <Button onClick={(e: any) => refetch()}>Yenile</Button>
        <Flex my={8} alignItems={'center'}>
          <Flex alignItems={'center'} w="95%">
            <Box w="45%">
              <Select
                options={
                  users?.users &&
                  users.users.users.map((u) => {
                    return {
                      label: u.name,
                      value: u.id
                    };
                  })
                }
                onChange={(e: any) =>
                  setUserId({ label: e.label, value: e.value })
                }
                value={userId}
              />
            </Box>
            <Button mx={2} onClick={() => setUserId({ label: '', value: '' })}>
              <Icon as={FcClearFilters} />
            </Button>
          </Flex>
          <Flex w={'50%'}>
            <Input
              onChange={(e: any) => setTempSku(e.target.value)}
              placeholder="Ürün Kodu"
              value={tempSku}
            />
            <Button mx={2} onClick={() => setSku(tempSku)}>
              <Icon as={FcFilledFilter} />
            </Button>
            <Button
              mx={2}
              onClick={() => {
                setSku('');
                setTempSku('');
              }}
            >
              <Icon as={FcClearFilters} />
            </Button>
          </Flex>
        </Flex>
        <Table my="4">
          <Thead
            display={{
              base: 'none',
              md: 'table-header-group'
            }}
          >
            <Tr>
              <Th>Sıra</Th>
              <Th>Resim</Th>
              <Th>İsim</Th>
              <Th>SKU</Th>
              <Th>Barkod</Th>
              <Th>Beden</Th>
              <Th>Seçenekler</Th>
              <Th>Üreten Kişi</Th>
              <Th>Üretildiği Tarih</Th>
              <Th>Tadilat</Th>
              <Th>İşlemler</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading || userLoad ? (
              <Box>
                <Spinner />
              </Box>
            ) : (
              data?.productions.productions.map((production, index) => (
                <Tr key={production.id}>
                  <Td
                    colSpan={8}
                    display={{
                      base: 'table-cell',
                      md: 'none'
                    }}
                    textAlign="center"
                  >
                    <Box bgColor="gray.50" mb={4} p={4}>
                      <Box
                        border="1px solid"
                        borderColor={'gray.300'}
                        rounded="md"
                        overflow="hidden"
                        mb={4}
                      >
                        <Zoom>
                          <img
                            src={`${process.env.REACT_APP_CDNURL}/${production.product.productGroup.photos[0]}?width=600&height=600&fit=contain&trim=20&extend=20&bg=ffffff`}
                            alt={
                              production.product.productGroup?.description[0]
                                .title
                            }
                            width="600"
                            height="600"
                          />
                        </Zoom>
                      </Box>
                      <Box fontSize={'xl'} fontWeight="semibold">
                        {production.product.productGroup?.description[0].title}
                      </Box>
                      <Box>
                        {production.product.productGroup.category.sku +
                          '-' +
                          production.product.productGroup.sku}
                      </Box>
                      <Box>{production.product.barcode}</Box>
                      <Box my={2}>
                        {production.product.sku?.split('-')[2] === 'C'
                          ? production.customSize
                          : production.product.sku?.split('-')[2]}{' '}
                        Beden
                      </Box>
                      <Box>
                        <RenderMaterials
                          locale="tr"
                          sku={production.product.sku || ''}
                        />
                      </Box>
                      <Box>
                        {production.user.name + ' ' + production.user.surname}{' '}
                        tarafından{' '}
                        {dayjs(production.createdAt).format('DD-MM-YY HH:mm')}{' '}
                        tarihinde üretildi
                      </Box>
                      <Box my={4}>
                        <Flex direction="column">
                          <Box mb={2}>
                            <DeleteModal
                              onDelete={onDelete}
                              id={production.id}
                            />
                          </Box>
                          <Box mb={2}>
                            <StorageModal
                              id={production.id}
                              onSubmit={onTransfer}
                            />
                          </Box>
                          <Box>
                            <Popover placement="bottom" isLazy>
                              <PopoverTrigger>
                                <Button width={'full'}>Barkod Bas</Button>
                              </PopoverTrigger>
                              <PopoverContent w="fit-content">
                                <PopoverArrow />
                                <PopoverBody>
                                  <Stack>
                                    <Button
                                      rightIcon={<BiBarcode />}
                                      onClick={(e: React.MouseEvent) =>
                                        onRetailPrice(production.product)
                                      }
                                    >
                                      TL Barkod
                                    </Button>
                                    <Button
                                      rightIcon={<BiBarcode />}
                                      onClick={(e: React.MouseEvent) =>
                                        onNoPrice(production.product)
                                      }
                                    >
                                      Fiyatsız Barkod
                                    </Button>
                                    <Button
                                      onClick={(e: any) =>
                                        onWholesalePrice(production.product)
                                      }
                                      rightIcon={<BiBarcode />}
                                    >
                                      Euro Toptan Barkod
                                    </Button>
                                    <Button
                                      onClick={(e: any) =>
                                        onWholesaleUsPrice(production.product)
                                      }
                                      rightIcon={<BiBarcode />}
                                    >
                                      Dolar Toptan Barkod
                                    </Button>
                                    <Button
                                      onClick={(e: any) =>
                                        onRetailEurPrice(production.product)
                                      }
                                      rightIcon={<BiBarcode />}
                                    >
                                      Euro Satış Barkod
                                    </Button>
                                    <Button
                                      onClick={(e: any) =>
                                        onRetailUsPrice(production.product)
                                      }
                                      rightIcon={<BiBarcode />}
                                    >
                                      Dolar Satış Barkod
                                    </Button>
                                    <Button
                                      onClick={(e: any) =>
                                        onWholesaleCaPrice(production.product)
                                      }
                                      rightIcon={<BiBarcode />}
                                    >
                                      Kanada Toptan Barkod
                                    </Button>
                                    <Button
                                      bg={'gold'}
                                      onClick={(e: any) =>
                                        onGoldPrice(production.product)
                                      }
                                      rightIcon={<BiBarcode />}
                                    >
                                      Altın Barkod
                                    </Button>
                                  </Stack>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Box>
                        </Flex>
                      </Box>
                    </Box>
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {skip + index + 1}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    <Zoom>
                      <img
                        src={`${process.env.REACT_APP_CDNURL}/${production.product.productGroup.photos[0]}`}
                        alt={
                          production.product.productGroup?.description[0].title
                        }
                        width="100"
                        height="100"
                      />
                    </Zoom>
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.product.productGroup?.description[0].title}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.product.productGroup.category.sku +
                      '-' +
                      production.product.productGroup.sku}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.product.barcode}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.product.sku?.split('-')[2] === 'C'
                      ? production.customSize
                      : production.product.sku?.split('-')[2]}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    <RenderMaterials
                      locale="tr"
                      sku={production.product.sku || ''}
                    />
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.user.name + ' ' + production.user.surname}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {dayjs(production.createdAt).format('DD-MM-YY HH:mm')}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    {production.isRepair ? 'Onarım' : 'Üretim'}
                    {production.repairStatus ? (
                      <Text>{production.repairStatus}</Text>
                    ) : (
                      <></>
                    )}
                  </Td>
                  <Td
                    display={{
                      base: 'none',
                      md: 'table-cell'
                    }}
                  >
                    <Stack>
                      <DeleteModal onDelete={onDelete} id={production.id} />
                      <StorageModal id={production.id} onSubmit={onTransfer} />
                      {/* <Button
                      bg="green.500"
                      rightIcon={<BiTask />}
                      color={'white'}
                      onClick={(e: any) => onTransfer(production.id)}
                    >
                      Depoya Gönder
                    </Button> */}
                      <Popover placement="bottom" isLazy>
                        <PopoverTrigger>
                          <Button>Barkod Bas</Button>
                        </PopoverTrigger>
                        <PopoverContent w="fit-content">
                          <PopoverArrow />
                          <PopoverBody>
                            <Stack>
                              <Button
                                rightIcon={<BiBarcode />}
                                onClick={(e: React.MouseEvent) =>
                                  onRetailPrice(production.product)
                                }
                              >
                                TL Barkod
                              </Button>
                              <Button
                                rightIcon={<BiBarcode />}
                                onClick={(e: React.MouseEvent) =>
                                  onNoPrice(production.product)
                                }
                              >
                                Fiyatsız Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onWholesalePrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Euro Toptan Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onWholesaleUsPrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Dolar Toptan Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onRetailUsPrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Dolar Satış Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onRetailEurPrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Euro Satış Barkod
                              </Button>
                              <Button
                                onClick={(e: any) =>
                                  onWholesaleCaPrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Kanada Toptan Barkod
                              </Button>
                              <Button
                                bg={'gold'}
                                onClick={(e: any) =>
                                  onGoldPrice(production.product)
                                }
                                rightIcon={<BiBarcode />}
                              >
                                Altın Barkod
                              </Button>
                            </Stack>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Stack>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Container>
      <Box mx="auto" my="8" w="10%">
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Box>
    </Layout>
  );
};
