import React from 'react';
import { Layout } from '../Layout';
import { useProductGroupsListQuery, PriceType } from '../../generated/graphql';
import Heading from '../ui/Heading';
import Pagination from '../ui/Pagination';
import {
  Box,
  Container,
  Flex,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Spinner,
  Button,
  Input,
  Icon,
  chakra,
  Image,
  Tag
} from '@chakra-ui/react';
import formatMoney from '../../utils/formatMoney';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { FcEmptyFilter, FcClearFilters, FcFilledFilter } from 'react-icons/fc';

export const ProductGroups = () => {
  const [totalPages, setTotalPages] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [priceType, setPriceType] = React.useState(PriceType.Retail);
  const [sku, setSku] = React.useState('');
  const [tempSku, setTempSku] = React.useState('');
  const defaultPageSize = 30;
  const skip = (page - 1) * defaultPageSize;

  const { data, loading, refetch, error } = useProductGroupsListQuery({
    variables: {
      first: defaultPageSize,
      skip,
      sku: tempSku ? tempSku : null
    }
  });

  React.useEffect(() => {
    if (!loading && data) {
      setTotalPages(
        Math.ceil(data.productGroups.productCount / defaultPageSize)
      );
    }
  }, [data, loading]);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (error) return <div>Bir Hata Oluştu! Sayfayı Yenileyin</div>;
  const priceTypes = [
    {
      label: 'TL Satış',
      value: PriceType.Retail
    },
    {
      label: 'Euro Satış',
      value: PriceType.Foreign
    },
    {
      label: 'Euro Toptan',
      value: PriceType.Wholesale
    },
    {
      label: 'Dolar',
      value: PriceType.Retailus
    },
    {
      label: 'Dolar Toptan',
      value: PriceType.Wholesaleus
    },
    {
      label: 'Kanada Fiyatı',
      value: PriceType.Wholesaleca
    }
  ];
  return (
    <Layout>
      <Container maxW={'container.xl'}>
        <Heading title="Ürünler" />
        <Box>
          <Flex
            flexDirection={['column', 'column', 'row']}
            alignItems={'center'}
            my={2}
          >
            <Box w={['100%', '100%', '35%']}>
              <strong>Fiyat Türü</strong>
              <Select
                onChange={(e) => setPriceType(e?.value || PriceType.Retail)}
                options={priceTypes}
              />
            </Box>
            <chakra.form w={'full'}>
              <chakra.strong mx={[0, 0, 8]}>SKU</chakra.strong>
              <Flex>
                <Input
                  w={['100%', '100%', '85%']}
                  ml={[0, 0, 8]}
                  mr={[2]}
                  value={sku}
                  placeholder="Stok Kodu Giriniz"
                  onChange={(e: any) => setSku(e.target.value)}
                />
                <Button
                  type="submit"
                  w={['15%']}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    if (tempSku) {
                      setTempSku('');
                      setSku('');
                    } else {
                      setPage(1);
                      setTempSku(sku);
                    }
                  }}
                >
                  <Icon
                    as={
                      !sku
                        ? FcEmptyFilter
                        : tempSku
                        ? FcClearFilters
                        : FcFilledFilter
                    }
                  />
                </Button>
              </Flex>
            </chakra.form>
          </Flex>
          <Table>
            <Thead
              display={{
                base: 'none',
                md: 'table-header-group'
              }}
            >
              <Tr>
                <Th>Sıra</Th>
                <Th>Resim</Th>
                <Th>SKU</Th>
                <Th>İsim</Th>
                {/*  fiyat ve ağırlık muhasebe için */}
                {/* <Th>Seçenekler</Th> */}
                <Th>Ağırlık</Th>
                {/* <Th>M</Th> */}
                <Th>Fiyat</Th>
                <Th>İşlemler</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={7}>
                    <Spinner mx="auto" />
                  </Td>
                </Tr>
              ) : (
                data?.productGroups.productGroups.map((product, index) => {
                  let price = 0;
                  let currency = 'TRY';
                  const findPrice = product.price.find(
                    (p) => p.priceType === priceType
                  );
                  if (findPrice) {
                    price = findPrice.price;
                    currency = findPrice.currency.code;
                  }
                  return (
                    <Tr key={product.id}>
                      <Td
                        display={{
                          base: 'none',
                          md: 'table-cell'
                        }}
                      >
                        {index + 1 + skip}
                      </Td>
                      <Td
                        display={{
                          base: 'none',
                          md: 'table-cell'
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_CDNURL}/${product.photos[0]}?width=100&height=100&fit=contain&trim=20&extend=20&bg=ffffff`}
                          alt={product?.description[0]?.title}
                          width="100"
                          height="100"
                        />
                      </Td>
                      <Td
                        display={{
                          base: 'none',
                          md: 'table-cell'
                        }}
                      >
                        {product.category.sku + '-' + product.sku}
                      </Td>
                      <Td
                        display={{
                          base: 'none',
                          md: 'table-cell'
                        }}
                      >
                        {product.description[0]?.title}
                      </Td>

                      <Td
                        display={{
                          base: 'none',
                          md: 'table-cell'
                        }}
                      >
                        {product.weight}
                      </Td>

                      <Td
                        display={{
                          base: 'none',
                          md: 'table-cell'
                        }}
                      >
                        {formatMoney(price, currency)}
                      </Td>
                      <Td
                        display={{
                          base: 'table-cell',
                          md: 'none'
                        }}
                      >
                        <Box bgColor="gray.50" mb={4} p={4}>
                          <Box
                            border="1px solid"
                            borderColor={'gray.300'}
                            rounded="md"
                            overflow="hidden"
                            mb={4}
                          >
                            <Image
                              mx={'auto'}
                              src={`${process.env.REACT_APP_CDNURL}/${product.photos[0]}?width=250&height=250&fit=contain&trim=20&extend=20&bg=ffffff`}
                              alt={product?.description[0]?.title}
                              width="250"
                              height="250"
                              my={2}
                            />
                            <Box
                              fontSize={'xl'}
                              fontWeight="semibold"
                              textAlign={'center'}
                            >
                              #{index + 1} {product.description[0].title}
                            </Box>
                            <Box my={1} textAlign={'center'}>
                              {product.category.sku}-{product.sku}
                            </Box>
                            <Box my={1} textAlign={'center'}>
                              <chakra.strong>Ağırlık:</chakra.strong>
                              {product.weight}
                            </Box>
                            <Box my={1} textAlign={'center'}>
                              <chakra.strong>Fiyat:</chakra.strong>
                              {formatMoney(price, currency)}
                            </Box>
                            <Box
                              my={2}
                              display={'flex'}
                              justifyContent="center"
                            >
                              <Link to={`/product-group/detail/${product.id}`}>
                                <Tag
                                  textAlign={'center'}
                                  size="sm"
                                  justifyContent="center"
                                  variant={'solid'}
                                  py={2}
                                  px={20}
                                >
                                  Detay
                                </Tag>
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </Td>
                      <Td display={{ base: 'none', md: 'table-cell' }}>
                        <Button
                          to={`/product-group/detail/${product.id}`}
                          as={Link}
                        >
                          Detay
                        </Button>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </Box>
      </Container>
      <Box mx="auto" my="8" w="40%">
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Box>
    </Layout>
  );
};
